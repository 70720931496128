import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import AuthPage from '../views/auth/auth.page'
import OnboardingPage from '../views/onboarding/onboarding.page'

const AuthNavigator = ({ pathname }: { pathname: string }) => {
    return (
        <Routes>
            <Route path="/onboarding" element={<OnboardingPage />} />
            <Route path="/login" element={<AuthPage pathname={pathname} />} />
            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    )
}

export default AuthNavigator
