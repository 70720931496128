import { FC } from 'react'
import { EnergySeries } from '../../dataProviders/useEnergyConsumptions'
import Highcharts from 'highcharts'
import HighchartsChart from '@/components/Charts/HighchartsChart'

const chartFormatOptions: Highcharts.Options = {
    title: {
        text: '',
    },
    chart: {
        backgroundColor: 'transparent',
    },
    plotOptions: {
        series: {
            stacking: 'normal',
        },
    },
    yAxis: {
        title: {
            text: 'Consommation (kWh)',
        },
    },
    exporting: {
        enabled: false,
    },
}

interface EnergyConsumptionsBarChartProps {
    energySeries: EnergySeries
}

const EnergyConsumptionsBarChart: FC<EnergyConsumptionsBarChartProps> = ({ energySeries }) => {
    const { categories, series } = energySeries

    return (
        <HighchartsChart
            options={{
                ...chartFormatOptions,
                series: series.map((s) => ({
                    ...s,
                    type: 'column',
                })),
                xAxis: {
                    categories,
                },
            }}
        />
    )
}

export default EnergyConsumptionsBarChart
