import React, { useState } from 'react'
import { CardText, Collapse } from 'reactstrap'
import Telecommand from './telecommand.component'

const EquipmentCard = (props) => {
    const [collapse, setCollapse] = useState(false)

    return (
        <>
            <Collapse isOpen={collapse}>
                <CardText className="mb-4">
                    <Telecommand
                        sensors={props.equipment.Sensors}
                        addModification={props.addModification}
                        setParentTemperature={props.setTemperature}
                        objectId={props?.objectId}
                    />
                </CardText>
            </Collapse>
        </>
    )
}

export default EquipmentCard
