import { Series } from '../types'

/**
 * Returns the first series that matches the type and subType (can be undefined)
 * @param series array of series
 * @param type
 * @param subType
 * @returns single series or undefined
 */
export const pickSeries = (series: Series[], type: Series['type'], subType?: Series['subType']): Series | undefined =>
    series.filter((s) => s.type === type && (subType === undefined || s.subType === subType))[0]

const SEPARATOR = '|||'

export const seriesTypesToTypeId = (type: Series['type'], subType?: Series['subType']): string =>
    [type, subType].filter(Boolean).join(SEPARATOR)

export const seriesTypeIdToTypes = (id: string): Pick<Series, 'type' | 'subType'> => {
    const arr = id.split(SEPARATOR)
    return { type: arr[0], subType: arr[1] || null } as Pick<Series, 'type' | 'subType'>
}
