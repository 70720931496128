import React, { VFC, useState } from 'react'
import { Card, CardContent, useTheme } from '@mui/material'
import HighchartsChart from '@/components/Charts/HighchartsChart'
import { PerimeterExtendedSiteData } from '../types'
import moment from 'moment'
import HightCharts from 'highcharts'
import { Series } from '../types'
import ButtonPicker from './buttonPicker'
import uniqBy from 'lodash/uniqBy'
import * as seriesUtils from '../utils/series'

interface ExtendedSeries extends Series {
    name: string
    stack: number
    color: string | undefined
}
interface BarChartComponentProps {
    series: ExtendedSeries[]
}

export const BarChartComponent: VFC<BarChartComponentProps> = ({ series }) => {
    const categories = series[0].data.map((d) => moment(d.date).format('MMM'))

    // need to manually add colors otherwise can be duplicated in different stacks
    const uniqueNames = Array.from(new Set(series.map((s) => s.name)))

    const colors = HightCharts?.getOptions()?.colors?.slice(0, uniqueNames.length) || []
    const nameColorMap = uniqueNames.reduce(
        (acc, name, index) => {
            acc[name] = colors[index] as string
            return acc
        },
        {} as Record<string, string>
    )

    const unit = series[0].unit || ''

    const options = {
        chart: {
            type: 'column',
        },
        title: {
            // no title
            text: '',
        },
        exporting: {
            enabled: false,
        },
        xAxis: {
            categories,
        },
        yAxis: {
            min: 0,
            title: {
                text: `${series[0].fullName} (${unit})`,
            },
        },
        legend: {
            reversed: false,
            enabled: series.length > 1,
        },
        plotOptions: {
            column: {
                stacking: 'normal',
            },
        },
        tooltip: {
            pointFormat: `{series.name}: <b>{point.y:.2f} ${unit}</b>`,
        },

        series: series.map((s, index) => {
            const previous = series.slice(0, index).find((previous) => previous.name === s.name)
            const seriesToId = (s: ExtendedSeries) => `${s.name}-${s.stack}`
            const serios = {
                id: seriesToId(s),
                linkedTo: previous ? seriesToId(previous) : undefined, // from https://jsfiddle.net/1ktmb2d2/1/
                name: s.name,
                stack: s.stack,
                unit: s.unit || '',
                data: s.data.map((d) => d.value),
                color: s.color || nameColorMap[s.name],
            }
            return serios
        }),
    }

    return <HighchartsChart options={options} />
}

interface BarChartWrapperProps {
    sites: PerimeterExtendedSiteData[]
    groupBy: string | null
}

const BarChartWrapper: VFC<BarChartWrapperProps> = ({ sites, groupBy }) => {
    const { palette } = useTheme()
    const allSeries = sites.map((site) => site.series).flat()
    const uniqueSeries = uniqBy(
        allSeries.map((s) => ({
            ...s,
            typeId: seriesUtils.seriesTypesToTypeId(s.type, s.subType),
        })),
        'typeId'
    ).sort((a, b) => a.typeId?.localeCompare(b.typeId))
    const seriesOptions = uniqueSeries.map((s) => s.typeId)
    const seriesOptionsTranslations = uniqueSeries.reduce(
        (acc, s) => {
            acc[s.typeId] = s.fullName
            return acc
        },
        {} as Record<string, string>
    )
    const [selectedSeries, setSelectedSeries] = useState<string>(seriesOptions[0])
    const selectedSeriesTypes = seriesUtils.seriesTypeIdToTypes(selectedSeries)
    const series = sites
        .map((site) => {
            const s = seriesUtils.pickSeries(site.series, selectedSeriesTypes.type, selectedSeriesTypes.subType)
            if (!s) {
                return null
            }
            return {
                ...s,
                name: site.name,
                stack: site.perimeterIndex,
                color: groupBy ? undefined : sites.length > 1 ? site.perimeter.color : palette.primary.light,
            }
        })
        .filter(Boolean) as ExtendedSeries[]
    return (
        <Card elevation={0}>
            <CardContent style={{ position: 'relative' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <ButtonPicker
                        value={selectedSeries}
                        options={seriesOptions}
                        setValue={setSelectedSeries}
                        badge={false}
                        valueTranslations={seriesOptionsTranslations}
                    >
                        {seriesOptionsTranslations[selectedSeries]}
                    </ButtonPicker>
                </div>
                <br />
                <BarChartComponent series={series} />
            </CardContent>
        </Card>
    )
}

export default BarChartWrapper
