import moment from 'moment'
import { electricBlue, electricGreen, electricOrange } from '../components/Basic/colors'
import { hexToRGB } from './global.utils'

export const formatTreemap = (data) => {
    let out = {}
    for (let i = 0; i < data?.length; i++) {
        let item = data?.[i]
        let key = item?.ObjectHash
        if (key in out) {
            out[key] = {
                Consumption: out[key]?.Consumption + item.EnergyConsumption,
                OnPercentage: out[key]?.OnPercentage + item.OnPercentage,
                SetTempMean: out[key]?.SetTempMean + item.SetTempMean,
                TinMean: out[key]?.TinMean + item.TinMean,
                TinMeanOn: item?.OnPercentage ? parseFloat(item.TinMean) + out[key]?.TinMeanOn : out[key]?.TinMeanOn,
                HeatDuration: out[key]?.HeatDuration + item.HeatDuration,
                CoolDuration: out[key]?.CoolDuration + item.CoolDuration,
                count: item?.TinMean ? out[key].count + 1 : out[key].count,
                _count: item.SetTempMean ? out[key]._count + 1 : out[key]._count,
                onCount: item.OnPercentage ? out[key].onCount + 1 : out[key].onCount,
                ObjectHash: item.ObjectHash,
                ObjectName: item.ObjectName,
                total: out[key]?.total + 1,
            }
        } else if (!(key in out)) {
            out[key] = {
                Consumption: item.EnergyConsumption,
                OnPercentage: item.OnPercentage,
                SetTempMean: item.SetTempMean ? item.SetTempMean : 0,
                TinMeanOn: item?.OnPercentage ? item.TinMean : 0,
                TinMean: item.TinMean,
                HeatDuration: item.HeatDuration,
                CoolDuration: item.CoolDuration,
                count: item.TinMean ? 1 : 0,
                _count: item.SetTempMean ? 1 : 0,
                onCount: item.OnPercentage ? 1 : 0,
                ObjectHash: item.ObjectHash,
                ObjectName: item.ObjectName,
                total: 1,
            }
        }
    }

    for (let key in out) {
        let obj = out[key]
        obj.TinMean = obj.count ? obj.TinMean / obj.count : 0
        obj.TinMeanOn = obj.onCount ? obj.TinMeanOn / obj.onCount : 0
        obj.SetTempMean = obj._count ? obj.SetTempMean / obj._count : 0
        // obj.OnPercentage = obj.onCount ? obj.OnPercentage / obj.onCount : 0;
        obj.OnPercentage = obj.OnPercentage / obj.total
    }

    return out
}

export const computeAverageTemp = (data, key) => {
    let out = 0
    let count = 0
    let _data = data?.filter((el) => el?.[key])

    for (let i = 0; i < _data?.length; i++) {
        out += _data[i]?.[key]
        count += 1
    }

    if (out) return out / count
    return  0 
}

export const computeOnPercentage = (data) => {
    let _data = data?.map((el) => el?.OnPercentage)
    if (_data?.length === 0 || !_data) return 0
    return _data.reduce((a, b) => a + b, 0) / _data.length
}

export const computeAverageSetTemp = (data) => {
    let _data = data?.filter((el) => el?.SetTempMean)?.map((el) => el?.SetTempMean)

    if (_data?.length === 0 || !_data) return { _avg: 0 }

    return {
        _avg: _data.reduce((a, b) => a + b, 0) / _data.length,
    }
}

export const computeAveragePilotedSetTemp = (data) => {
    let _data = data?.filter((el) => el?.SetTempAutoMean)?.map((el) => el?.SetTempAutoMean)
    if (_data?.length === 0 || !_data) return 0
    return _data.reduce((a, b) => a + b, 0) / _data.length
}

export const computeAutomatisation = (data) => {
    let _data = data
        ?.filter((el) => el?.ToggleAutoSetTemp || el?.ToggleAutoState)
        ?.map((el) => parseInt(el?.ToggleAutoSetTemp) + parseInt(el?.ToggleAutoState))
    let _autoCount = _data.reduce((a, b) => a + b, 0)
    _data = data
        ?.filter((el) => el?.ToggleManualSetTemp || el?.ToggleManualState)
        ?.map((el) => parseInt(el?.ToggleManualSetTemp) + parseInt(el?.ToggleManualState))
    let _manualCount = _data.reduce((a, b) => a + b, 0)
    return { _autoCount, _manualCount }
}

export const enumerateDaysBetweenDates = function (startDate, endDate) {
    var dates = []

    var currDate = moment(startDate).startOf('day')
    var lastDate = moment(endDate).startOf('day')
    dates.push(currDate.clone().toDate())
    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
        dates.push(currDate.clone().toDate())
    }

    return dates
}

export const computeLineChart = (data, start, end) => {
    let labels = enumerateDaysBetweenDates(start, end)
    let out = []
    let distances = {}
    for (let i = 0; i < labels?.length; i++) {
        let label = moment(labels?.[i]).format('YYYY-MM-DD')
        let day = label?.split('T')?.[0]?.split('-')?.[2]
        let month = label?.split('T')?.[0]?.split('-')?.[1]
        let year = label?.split('T')?.[0]?.split('-')?.[0]
        let _data = data.filter((el) => {
            let _day = el.StartDateTime?.split('T')?.[0]?.split('-')?.[2]
            let _month = el.StartDateTime?.split('T')?.[0]?.split('-')?.[1]
            let _year = el.StartDateTime?.split('T')?.[0]?.split('-')?.[0]
            return `${_day}/${_month}/${_year}}` === `${day}/${month}/${year}}`
        })

        let _tInt = _data?.map((el) => el?.TinMean)?.filter((el) => el !== null)

        _tInt = _tInt?.length > 0 ? _tInt.reduce((a, b) => a + b, 0) / _tInt.length : null
        let _tExt = _data?.map((el) => el?.TOutMean)
        let setTemp = _data?.map((el) => el?.SetTempMean)?.filter((el) => el !== null)
        setTemp = setTemp.length > 0 ? setTemp.reduce((a, b) => a + b, 0) / setTemp.length : null
        let objects = {}
        out.push({
            label: `${year}-${month}-${day}`,
            tInt: _tInt,
            tExt: _tExt.reduce((a, b) => a + b, 0) / _tExt.length,
            setTemp,
            ...objects,
        })
    }

    return { lineChart: out, distances }
}

export const sumByDateAndType = (arr) => {
    // Create an object to store the sums
    let sums = {}

    // Iterate over the array of objects
    for (let i = 0; i < arr?.length; i++) {
        let obj = arr[i]
        let date = obj.Date
        let type = obj.Type
        let value = obj.Value

        // If this date and type combination has not yet been encountered, initialize the sum to 0
        if (!sums[date]) {
            sums[date] = {}
        }
        if (!sums[date][type]) {
            sums[date][type] = 0
        }

        // Add the value to the sum for this date and type
        sums[date][type] += parseInt(value)
    }

    // Create an array to store the final result
    let result = []

    // Iterate over the dates in the sums object
    for (let date in sums) {
        // Iterate over the types for this date
        for (let type in sums[date]) {
            // Add an object to the result array with the date, type, and sum
            result.push({
                Date: date,
                Type: type,
                Value: sums[date][type],
            })
        }
    }

    // Sort the result array by date
    result.sort((a, b) => {
        return new Date(a.Date) - new Date(b.Date)
    })

    return result
}

export const computeEnergySeries = (formatted_data, energyDisplay, selectedMeter, lineData) => {
    let tInt = extractTOut(lineData, 'tInt')
    let setTemp = extractTOut(lineData, 'setTemp');
    let extractFirstNonNul = tInt?.data?.find((el) => el?.y > 0)
    let tOut = extractTOut(lineData, 'tExt');

    tOut["data"] = tOut?.data?.map((el) => {
        if (el?.x < extractFirstNonNul?.x) {
            return {
                x: el?.x,
                y: null
            }
        } else {
            return el
        }
    })

    if (!selectedMeter) {
        return [tOut, tInt, setTemp]
    }
    if (energyDisplay === 'power') {
        return [
            tOut,
            {
                id: 'Pmean',
                name: 'Puissance moy.',
                data: formatted_data
                    ?.filter((el) => el?.Type === 'Pmean')
                    ?.map((el) => {
                        let date = moment.utc(el?.Date, 'DD-MM-YYYY').set('hours', 0).toDate()

                        return {
                            y: el?.Value,
                            x: date.getTime(),
                        }
                    }),
                hash: 4,
                visible: true,
                marker: {
                    enabled: false,
                },
                type: 'column',
                tooltip: {
                    valueSuffix: ' W',
                },
                spacingLeft: 10,
                yAxis: 0,
                color: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, electricGreen],
                        [1, hexToRGB(electricGreen, 0.5)],
                    ],
                },
            },
            {
                name: 'Puissance max.',
                id: 'Pmax',
                data: formatted_data
                    ?.filter((el) => el?.Type === 'Pmax')
                    ?.map((el) => {
                        let date = moment.utc(el?.Date, 'DD-MM-YYYY').set('hours', 0).toDate()

                        return {
                            y: el?.Value,
                            x: date.getTime(),
                        }
                    }),
                hash: 4,
                visible: true,
                marker: {
                    enabled: false,
                },
                type: 'line',
                spacingLeft: 10,
                yAxis: 0,
                color: 'red',
                tooltip: {
                    valueSuffix: ' W',
                },
                dataLabels: {
                    enabled: true,
                    format: '{y} W',
                },
                // lineWidth: 0
                // color: {
                //   linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                //   stops: [
                //     [0, electricGreen],
                //     [1, hexToRGB(electricGreen, 0.5)],
                //   ],
                // },
            },

            // tInt,
            // setTemp,
        ]
    } else if (energyDisplay === 'consumption') {
        return [
            tOut,
            tInt,
            setTemp,
            {
                name: 'Consommation',
                id: 'EA',
                data: formatted_data
                    ?.filter((el) => el?.Type === 'EA')
                    ?.map((el) => {
                        let date = moment.utc(el?.Date, 'DD-MM-YYYY').set('hours', 0).toDate()

                        return {
                            y: parseInt(el?.Value / 100) / 10,
                            x: date.getTime(),
                        }
                    }),
                hash: 6,
                visible: true,
                marker: {
                    enabled: false,
                },
                type: 'column',
                spacingLeft: 10,
                yAxis: 0,
                tooltip: {
                    valueSuffix: ' kWh',
                },
                color: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, hexToRGB(electricBlue, 0.5)],
                        [1, hexToRGB(electricBlue, 0.1)],
                    ],
                },
            },
        ]
    }
}

export const extractTOut = (lineData, type) => {
    if (!lineData) return {}
    return {
        id: type ? type : 0,
        name: type == 'tExt' ? 'Temp. ext.' : type == 'tInt' ? 'Temp. int.' : 'Temp. consigne',
        data: lineData?.map((el, index) => {
            let date = moment.utc(el.label, 'YYYY-MM-DD').set('hours', 0).toDate()
            return {
                y: parseInt(el?.[type]),
                x: date.getTime(),
            }
        }),
        hash: 10,
        visible: true,
        // marker: {
        //   enabled: false,
        //   valueSuffix: " °C",
        // },
        type: 'line',
        spacingLeft: 10,
        yAxis: 1,
        color: type == 'tExt' ? electricOrange : type == 'tInt' ? electricBlue : electricGreen,
        dataLabels: {
            enabled: true,
            format: '{y} °C',
        },
        dashStyle: 'DashDot',
        tooltip: {
            valueSuffix: ' °C',
        },
        showLegend: true,
    }
}
