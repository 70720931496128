import FallbackTemplate from './template.component'
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn'

const Forbidden = () => (
    <FallbackTemplate
        title="Accès interdit"
        description="Vous n'êtes pas autorisé à accéder à cette ressource. Si vous pensez qu'il s'agit d'une erreur, veuillez contacter l'administrateur."
        icon={<DoDisturbOnIcon fontSize="inherit" />}
    />
)

export default Forbidden
