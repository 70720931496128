import { environment as env } from '../utils/environment'
import { ErrorClass } from '../utils/error.utils'

export const isDemo = () => {
    // const me = useSelector((state) => state.meStore?.me);
    let is_test = localStorage.getItem('isTest')
    return (
        window.location.href.includes('localhostx') ||
        window.location.href.includes('demo.a-grid') ||
        is_test === 'true'
    )
}

export const queryBuilder = (query) =>
    Object.keys(query).reduce((acc, key) => `${acc}${key}=${JSON.stringify(query[key])}&`, '?')

const prepareHeaders = (token, queryHeaders, mode) => {
    const headers = {
        ...queryHeaders,
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }

    if (token) {
        headers.Authorization = token
    }

    if (mode === 'master') {
        headers.AGRID_MODE = mode
    }

    return headers
}

export const put = async (route, object) => {
    const token = localStorage.getItem('token')
    const headers = prepareHeaders(token)
    return fetch(env.apiUrl + route, {
        method: 'PUT',
        headers,
        body: JSON.stringify(object),
    })
}

export const get = async (route, queryHeaders) => {
    if (isDemo()) {
        const { MockResponse } = await import('../mocks/factory')
        return new MockResponse(route, {})
    }

    const token = localStorage.getItem('token')
    const mode = localStorage.getItem('AGRID_MODE')
    const headers = prepareHeaders(token, queryHeaders, mode)
    return fetch(env.apiUrl + route, {
        method: 'GET',
        headers,
    }).catch(() => new ErrorClass())
}

export const del = async (route) => {
    const token = localStorage.getItem('token')
    const headers = prepareHeaders(token)
    return fetch(env.apiUrl + route, {
        method: 'DELETE',
        headers,
    })
}

export const post = async (route, object, queryHeaders) => {
    if (isDemo()) {
        const { MockResponse } = await import('../mocks/factory')
        return new MockResponse(route, object)
    }

    const token = localStorage.getItem('token')
    const mode = localStorage.getItem('AGRID_MODE')
    const headers = prepareHeaders(token, queryHeaders, mode)

    return fetch(env.apiUrl + route, {
        method: 'POST',
        headers,
        body: JSON.stringify(object),
    }).catch(() => new ErrorClass())
}
