import { FC } from 'react'
import { Chip, ChipProps } from '@mui/material'

export type DpeType = 'consumption' | 'greenhouse'
type DpeClass = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G'

const COLORS: Record<DpeType, Record<DpeClass, string>> = {
    consumption: {
        A: '#019c6c',
        B: '#52b153',
        C: '#79bd76',
        D: '#f4e711',
        E: '#f0b510',
        F: '#eb8235',
        G: '#d8221f',
    },
    greenhouse: {
        A: '#f7edfd',
        B: '#dfc2f7',
        C: '#d5a9f5',
        D: '#cb95f3',
        E: '#bb72f3',
        F: '#a94cee',
        G: '#8b1ae1',
    },
}

const typeStyles: Record<DpeType, ChipProps['sx']> = {
    consumption: {
        clipPath: 'polygon(0 0, 75% 0, 100% 50%, 75% 100%, 0 100%)',
    },
    greenhouse: {},
}

interface DpeProps extends ChipProps {
    dpeClass: DpeClass
    type: DpeType
    width?: number
}

const DpeLabel: FC<DpeProps> = ({ dpeClass, type, width = 90, ...chipProps }) => (
    <Chip
        label={dpeClass}
        {...chipProps}
        sx={{
            backgroundColor: COLORS[type][dpeClass],
            borderRadius: 0,
            width,
            justifyContent: 'flex-start',
            fontWeight: 'bold',
            ...typeStyles[type],
        }}
    />
)

export default DpeLabel
