import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import RealtimePage from '../views/realTime/realtime.page'
import ConstraintsPage from '../views/constraints/constraints.page'
import SiteSettings from '../views/settings/admin.page'
import ProfileSettings from '../views/settings/user.page'
import SecuritySettings from '../views/settings/security.page'
import OnboardingPage from '../views/onboarding/onboarding.page'
import TutorialsPage from '../views/settings/tutorials/index.page'
import { SiteDetails } from '../views/settings/site/site.details'
import { SetupPage } from '../views/setup/setup.screen'
import DocumentsPage from '../views/documents/documents.page'
import { DashboardEntryPoint } from '../views/newdashboard/dashboard.entrypoint'
import { HelpPage } from '../views/help/help.screen'
// import { SupportScreen } from '../views/help/support.screen'
import { PdfPage } from '../views/documents/components/pdf.page'
import { RoutePaths } from './route'
import MainLayout from '@/components/layout/mainLayout.component' // @TODO :)
import { GroupsPage } from '@/views/group/group.page'

const MainRoutes = ({ defaultRoute }: { defaultRoute: string }) => {
    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                <Route path={RoutePaths.Dashboard} element={<DashboardEntryPoint />} />
                <Route path={RoutePaths.Realtime} element={<RealtimePage />} />
                <Route path={RoutePaths.Constraints} element={<ConstraintsPage />} />
                <Route path={RoutePaths.Documents} element={<DocumentsPage />} />
                <Route path={RoutePaths.Help} element={<HelpPage />} />
                <Route path={RoutePaths.Settings} element={<SecuritySettings />} />
                {/* <Route path={RoutePaths.Support} element={<SupportScreen />} /> */}
                <Route path="/" element={<Navigate to={defaultRoute} />} />
            </Route>
            <Route path="/settings/profile" element={<ProfileSettings />} />
            <Route path="/onboarding" element={<OnboardingPage />} />
            <Route path="/settings/site" element={<SiteDetails />} />
            <Route path="/settings/admin" element={<SiteSettings />} />
            <Route path="/settings/tutorials" element={<TutorialsPage />} />
            <Route path="/pdf-small" element={<PdfPage />} />
            <Route path="/setup" element={<SetupPage />} />
            <Route path="/hidden" element={<GroupsPage />} />
            <Route path="*" element={<Navigate to={defaultRoute} />} />
        </Routes>
    )
}

export default MainRoutes
