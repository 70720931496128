import React, { FC } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { Card, CardContent, CardMedia, Chip, Tooltip, Grid, IconButton, Typography } from '@mui/material'
import DefaultPic from '../../../assets/img/site.png'
import { deepGrey, linkBlue } from '../../../components/Basic/colors'
import DpeLabel from './dpeLabel.component'
import { formatNumber } from '../../../utils/global.utils'
import { GroupMapComponent } from './maps-group.component'
import { WarningText } from './warning.text'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import HomeIcon from '@mui/icons-material/Home'
import { useDpe } from '../dataProviders/useDpe'

interface DetailsComponentProps {
    site: {
        SiteId: number
        Surface: number
        PictureUrl?: string
        Name: string
        Hash: string
        SiteType: string
        Address: string
        Latitude?: string
        Longitude?: string
    }
    yearlyData?: { Sum: number }[]
    startDatePilot?: string
}

export const DetailsComponent: FC<DetailsComponentProps> = ({ site, startDatePilot }) => {
    const { dpe } = useDpe(site.SiteId)

    return (
        <Card style={{ height: '100%', position: 'relative' }}>
            <CardMedia
                component="img"
                height="110"
                image={site?.PictureUrl ? site?.PictureUrl : DefaultPic}
                alt={site?.Name}
            />
            <IconButton
                href={`/documents?site=${site?.Hash}`}
                style={{
                    position: 'absolute',
                    top: 4,
                    right: 50,
                    border: '1px solid #f5f5f5',
                    opacity: 0.7,
                    background: deepGrey,
                }}
            >
                <DownloadIcon style={{ color: '#f5f5f5' }} />
            </IconButton>
            <IconButton
                href={`/constraints?site=${site?.Hash}`}
                style={{
                    position: 'absolute',
                    top: 4,
                    right: 4,
                    border: '1px solid #f5f5f5',
                    opacity: 0.7,
                    background: deepGrey,
                }}
            >
                <EditIcon style={{ color: '#f5f5f5' }} />
            </IconButton>

            <Chip
                variant="outlined"
                label={site?.SiteType}
                style={{
                    marginLeft: 10,
                    position: 'absolute',
                    top: 5,
                    left: 0,
                    background: linkBlue,
                    color: 'white',
                }}
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item container xs={12} alignItems="baseline" justifyContent="space-between">
                        <Grid item xs={8} spacing={1} container>
                            {dpe.map((el) => (
                                <Tooltip
                                    title={`${el.title} (${el.value.toFixed(1)} ${el.unit})`}
                                    key={el.title}
                                    placement="right"
                                    arrow
                                >
                                    <Grid item>
                                        <DpeLabel dpeClass={el.class} type={el.type} />
                                    </Grid>
                                </Tooltip>
                            ))}
                        </Grid>
                        <Grid item>
                            {site?.Surface ? (
                                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                    {`${formatNumber(site?.Surface)}m²`}
                                </Typography>
                            ) : (
                                <WarningText text="surface à renseigner" />
                            )}
                        </Grid>
                    </Grid>

                    <Grid item md={6}>
                        <Typography variant="h6" gutterBottom>
                            {site.Name}
                        </Typography>

                        {site.Address ? (
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                                gutterBottom
                            >
                                <HomeIcon fontSize="small" />
                                <span style={{ marginTop: 3 }}>{site?.Address}</span>
                            </Typography>
                        ) : (
                            <WarningText text="adresse à renseigner" />
                        )}

                        {startDatePilot ? (
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                            >
                                <CalendarMonthIcon fontSize="small" />
                                <span style={{ marginTop: 3 }}>{moment(startDatePilot).format('DD MMMM YYYY')}</span>
                            </Typography>
                        ) : null}
                    </Grid>
                    <Grid item md={6} style={{ paddingTop: 20 }}>
                        <GroupMapComponent
                            sites={[site]?.map((el) => {
                                return {
                                    ...el,
                                    Latitude: el.Latitude ? parseFloat(el.Latitude) : null,
                                    Longitude: el.Longitude ? parseFloat(el.Longitude) : null,
                                }
                            })}
                            onSelect={() => {}}
                            style={{ width: '100%', height: 120 }}
                            disableClick={true}
                            fullscreenControl={false}
                        />
                        {site?.Latitude && site?.Longitude ? null : <WarningText text="coordonnées à renseigner" />}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
