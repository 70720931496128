import { useQuery } from '@tanstack/react-query'
import { camelCaseDeep } from '@/utils/camelCaseDeep'
import { SiteData } from '../types'

const DEMO_DATA_URL = 'https://connect-demo-data.s3.eu-central-1.amazonaws.com/demo_data.json'

const getDemoData = async (): Promise<SiteData[]> => {
    const response = await fetch(DEMO_DATA_URL, { method: 'GET', cache: 'no-cache' })
    if (!response.ok) {
        throw new Error('Failed to fetch demo data')
    }
    const data = await response.json()

    return camelCaseDeep(data) as unknown as SiteData[]
}

export const useDemoData = () =>
    useQuery<SiteData[]>({
        queryKey: ['demoData'],
        queryFn: getDemoData,
        staleTime: Infinity,
    })
