import SettingsIcon from '@mui/icons-material/Settings'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import * as React from 'react'
import useLocale from '../../../utils/locale/locale.hook'

export default function NoteTable({ notes, setNote, editable }) {
    const locale = useLocale()
    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Ajout</TableCell>
                        <TableCell>Dernière édition</TableCell>
                        <TableCell>Titre</TableCell>
                        {editable && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {notes.map((row, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                {moment(row.CreatedAt).format('DD-MM-YYYY')}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {moment(row.UpdatedAt).format('DD-MM-YYYY')}
                            </TableCell>
                            <TableCell>
                                <a href={row.Path} style={{ color: '#03a9f4' }} target="_blank" rel="noreferrer">
                                    {row.Title}
                                </a>
                            </TableCell>
                            {editable && (
                                <TableCell align="right">
                                    <SettingsIcon style={{ cursor: 'pointer' }} onClick={() => setNote(row)} />
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
