import { FormLabel, Grid, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

const Digit = ({ focused, onChange }) => {
    let ref = useRef()
    useEffect(() => {
        if (focused) {
            ref.current.focus()
            ref.current.value = null
        }
    }, [focused])
    return (
        <Grid item md={2}>
            <TextField
                onChange={onChange}
                type="number"
                inputRef={ref}
                InputProps={{ inputProps: { min: 0, max: 9, step: 1 } }}
                placeholder="X"
            />
        </Grid>
    )
}

const CodeComponent = ({ submit, label }) => {
    const [focusIndex, setFocusIndex] = useState(0)
    const [code, setCode] = useState([null, null, null, null])
    const change = (value, index) => {
        if (isNaN(value) || parseFloat(value) % 1 !== 0) return
        value = parseInt(value)
        if (value >= 10 || value < 0) return
        let newCode = code
        newCode[index] = value
        setCode(newCode)
        if (index !== 3) setFocusIndex(index + 1)
        else {
            if (newCode?.indexOf(null) === -1) {
                let out = `${newCode[0]}${newCode[1]}${newCode[2]}${newCode[3]}`
                submit(out)
            }
        }
    }
    return (
        <>
            <FormLabel>{label}</FormLabel>
            <Grid
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
                spacing={3}
            >
                <Digit focused={focusIndex === 0} onChange={(e) => change(e.target.value, 0)} />
                <Digit focused={focusIndex === 1} onChange={(e) => change(e.target.value, 1)} />
                <Digit focused={focusIndex === 2} onChange={(e) => change(e.target.value, 2)} />
                <Digit focused={focusIndex === 3} onChange={(e) => change(e.target.value, 3)} />
            </Grid>
        </>
    )
}

export default CodeComponent
