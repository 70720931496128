import { post, get } from './utils.service'

export const updateSite = async (body, hash) => {
    const res = await post(`/sites/update/${hash}`, body, {
        SiteHash: hash,
    })
    return await res?.json()
}

export const createSite = async (body) => {
    const res = await post(`/site/create`, body)
    return await res?.json()
}

export const getTypes = async () => {
    const res = await get(`/sites/types`)
    return await res?.json()
}
