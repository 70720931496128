/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'

// used in case value is a string
const safeParse = (value: any) => {
    try {
        return JSON.parse(value)
    } catch (e) {
        return value
    }
}

const stringify = (value: any) => {
    if (typeof value === 'string') return value
    return JSON.stringify(value)
}

const useLocalStorage = <T = any>(key: string, initialValue?: T) => {
    const [storedValue, setStoredValue] = useState<T>(safeParse(localStorage.getItem(key)) || initialValue)

    const setValue = (newValue: T) => {
        setStoredValue(newValue)
        localStorage.setItem(key, stringify(newValue))
    }

    const clearValue = () => {
        localStorage.removeItem(key)
    }

    return [storedValue, setValue, clearValue] as const
}

export default useLocalStorage
