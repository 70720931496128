import React from 'react'
import { createPortal } from 'react-dom'
import { DashboardGroup } from './dashboard.group'
import { store } from '../../store/configure.store'
import GlobalSlice from '../../store/slices/global.slice'
import DashboardPage from './dashboard.page'
import { useSelector } from 'react-redux'
import { checkPermission, getRedirection } from '../../utils/global.utils'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { NavCalendarComponent } from '@/components/Global/nav-calendar.component'
import Calendar from '@/components/Sidebar/Calendar'
import { Box, Divider } from '@mui/material'
import Forbidden from '@/components/fallbacks/forbidden.component'

const SideCalendar = () => (
    <>
        <Divider />
        <Box sx={{ p: 2 }}>
            <h6 className="navbar-heading text-muted p-0">
                <span className="docs-normal">Calendrier</span>
            </h6>
            <div id="agrid-calendar" />
            <Calendar onChange={() => {}} />
        </Box>
    </>
)

export const DashboardEntryPoint = () => {
    const navigate = useNavigate()
    const me = useSelector((state) => state.meStore?.me)
    const [searchParams, setSearchParams] = useSearchParams()
    const site = searchParams.get('site')

    const getPageToShow = () => {
        const groupAllowed = checkPermission('DashboardGroupView', me)
        const isSiteAllowed = Boolean(site && me?.sites?.find((el) => el?.Hash == site));

        if (!site) {
            if (groupAllowed) {
                return 'group'
            }
            let firstSite = me?.sites[0];
            
            if (firstSite) {
                navigate(`/dashboard?site=${firstSite.Hash}`)
            }
            return null
        }
        if (!isSiteAllowed) {
            navigate(getRedirection(me))
            return null
        }
        return 'site'
    }
    const show = getPageToShow()

    const selectSite = (hash) => {
        let find = me?.sites?.find((el) => el.Hash == hash)
        if (find) {
            setSearchParams({ site: hash })
            store.dispatch(GlobalSlice.actions.setSelectedSite(find))
        }
    }

    if (show === 'group') {
        return <DashboardGroup onSelect={selectSite} show={show == 'group'} />
    }
    if (show === 'site') {
        const headerPortalContainer = document.getElementById('header-right-content-portal')
        const drawerPortalContainer = document.getElementById('nav-drawer-portal-container')
        return (
            <>
                {headerPortalContainer && createPortal(<NavCalendarComponent />, headerPortalContainer)}
                {drawerPortalContainer && createPortal(<SideCalendar />, drawerPortalContainer)}
                <DashboardPage />
            </>
        )
    }
    return <Forbidden />
}
