import { Typography } from '@mui/material'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { useTranslation } from 'react-i18next'

ChartJS.register(ArcElement, Tooltip, Legend)

export const DoughnutGroup = ({ sites }) => {
    const { t } = useTranslation('connect', { keyPrefix: 'groupDashboard' })
    return (
        <div
            style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 130,
                height: 130,
            }}
        >
            {sites?.length > 0 && (
                <Doughnut
                    className="chart-canvas-print"
                    data={{
                        labels: ['Sans Pilotage', 'Avec Pilotage'],
                        datasets: [
                            {
                                label: 'Vue globale',
                                data: [
                                    sites?.length - sites?.filter((el) => el?.Pilot)?.length,
                                    sites?.filter((el) => el?.Pilot)?.length,
                                ],
                                backgroundColor: ['#e0e0e0', '#00e676'],
                                hoverOffset: 4,
                            },
                        ],
                    }}
                    options={{
                        responsive: true,
                        cutout: '80%',
                        animation: {
                            animateScale: true,
                            animateRotate: true,
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            title: {
                                display: false,
                            },
                        },
                    }}
                    width={'60px!important'}
                    height={'60px!important'}
                    style={{ position: 'absolute', top: 0, left: 0 }}
                />
            )}
            {sites?.length && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h4" noWrap component="div" sx={{ fontWeight: 600 }}>
                        {sites?.filter((el) => el?.Pilot)?.length}
                    </Typography>
                    <Typography component="div" variant="caption" color="textSecondary" sx={{ marginTop: '-5px' }}>
                        {t('sitesWPilot', { count: sites?.filter((el) => el?.Pilot)?.length })}
                    </Typography>
                </div>
            )}
        </div>
    )
}
