import React, { useEffect, useState } from 'react'
import DefaultPic from '../../assets/img/site.png'

export const ImageInput = (props) => {
    const [url, setUrl] = useState(null)
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        if (props?.url) setUrl(props?.url || null)
    }, [props?.url])

    const handleChange = (event) => {
        let file = event.target.files[0]
        if (file) {
            props?.onChange(file)
            setUrl(URL.createObjectURL(file))
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <input id="image-input" type="file" onChange={handleChange} accept="image/*" style={{ display: 'none' }} />
            <label htmlFor="image-input" style={{ width: '100%', cursor: 'pointer' }}>
                {url ? (
                    <img
                        style={{
                            width: '100%',
                            objectFit: 'cover',
                            borderRadius: 2,
                            display: loaded ? '' : 'none',
                        }}
                        alt="Site Pic"
                        height="140"
                        src={url}
                        title="Site picture"
                        key={url}
                        onLoad={() => setLoaded(true)}
                    />
                ) : (
                    <img
                        alt="Site Pic"
                        height="140"
                        style={{
                            width: '100%',
                            objectFit: 'cover',
                            borderRadius: 2,
                        }}
                        src={DefaultPic}
                        title="Default picture"
                    />
                )}
                {url && !loaded && (
                    <img
                        alt="Site Pic"
                        height="140"
                        style={{ width: '100%', objectFit: 'cover', borderRadius: 2 }}
                        src={DefaultPic}
                        title="Site picture"
                    />
                )}
            </label>
        </div>
    )
}
