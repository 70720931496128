import { VFC } from 'react'
import { Container, Typography } from '@mui/material'
import Logo from '@/assets/img/brand/logo.png'

const Footer: VFC = () => (
    <Container
        component="footer"
        sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'end',
            gap: 0.5,
            py: 10,
        }}
    >
        <Typography variant="body2">Powered by</Typography>
        <img src={Logo} alt="Agrid logo" style={{ width: 60 }} />
    </Container>
)

export default Footer
