import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import CloseIcon from '@mui/icons-material/Close'
import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import object_hash from 'object-hash'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ContainerComponent } from '../../components/Global/container.component'
import NoDataComponent from '../../components/Global/no-data.component'
import { getConstraints, getGroups } from '../../services/constraints.service'
import {
    getBookings,
    getObjectsForSite,
    getTranslations,
    togglePilot,
    warnActionNotPassed,
} from '../../services/realtime.service'
import { sendAction } from '../../services/sensors.service'
import { isDemo } from '../../services/utils.service'
import GlobalSlice from '../../store/slices/global.slice'
import { extractFathers } from '../../utils/building.utils'
import { event, pageview } from '../../utils/ga.utils'
import { makeid, sleep } from '../../utils/global.utils'
import useLocale from '../../utils/locale/locale.hook'
import { getChanges, insertAction, verifyActionIsPassed } from '../../utils/realtime.utils'
import AlternativeRow from './components/alternative-row.component'
import NotificationMenu from './components/notifications.component'
import SaveMenu from './components/save-menu.component'
import ZonePage from './components/zone.page'

const RealTimePage = () => {
    const selectedSite = useSelector((state) => state.globalStore.selectedSite)
    const selectedObject = useSelector((state) => state.globalStore.selectedObject)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [rawObjects, setRawObjects] = useState([])
    const [loading, setLoading] = useState(true)
    const [dialog, setDialog] = useState(false)
    const [confirmDialog, setConfirmDialog] = useState(false)
    const dispatch = useDispatch()
    const [modal, setModal] = useState(false)
    const [showAlert, setShowAlert] = useState(null)
    const [sensors, setSensors] = useState(null)
    const [dataMeasurements, setDataMeasurements] = useState(null)
    const dmRef = useRef(dataMeasurements)
    dmRef.current = dataMeasurements
    const [isFetching, setIsFetching] = useState(false)
    const [pilot, setPilot] = useState(false)
    const [groups, setGroups] = useState([])
    const [buildings, setBuildings] = useState([])
    const navigate = useNavigate()
    const [modifications, setModifications] = useState([])
    const [translations, setTranslations] = useState(null)
    const isFetchingRef = useRef(isFetching)
    isFetchingRef.current = isFetching
    const hashRef = useRef(selectedSite?.Hash)
    hashRef.current = selectedSite?.Hash
    const modalRef = useRef(modal)
    modalRef.current = modal
    const rawRef = useRef(rawObjects)
    rawRef.current = rawObjects
    const [objectPilot, setObjectPilot] = useState(null)
    const [observationEndDate, setObservationEndDate] = useState(null)
    const [selectedBuildingId, setSelectedBuildingId] = useState(null)
    const [unreachable, setUnreachable] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [constraints, setConstraints] = useState([])
    const notifRef = useRef(notifications)
    notifRef.current = notifications
    let location = useLocation()
    let { search } = useLocation()
    const locale = useLocale()
    const [scrollY, setScrollY] = useState(0)
    const [objectPms, setObjectPms] = useState([])
    const [lastSetTempDmOld, setlastSetTempDmOld] = useState(null)
    const [sendLoader, setSendLoader] = useState(false)
    const [bookings, setBookings] = useState([])
    useEffect(() => {
        if (!modal) {
            window.scrollTo(0, scrollY)
        }
    }, [modal])

    useEffect(() => {
        const query = new URLSearchParams(search)
        const objectId = query.get('objectId');

        const hash = query.get('site');
        if (!objectId) {
            setModal(false)
            dispatch(GlobalSlice.actions.setSelectedObject(null))
        }
        if (objectId && !selectedObject) {
            console.log("selectedSite #1", selectedSite)
            navigate(`/realtime?site=${selectedSite?.Hash || hash}`)
        }
    }, [location])

    useEffect(() => {
        pageview('/admin/realtime')
    }, [])

    useEffect(() => {
        if (selectedSite?.Hash) {
            if (!search) {
                navigate(`/realtime?site=${selectedSite?.Hash}`)
            }
            setModal(false)
            fetchData(true)
            setSensors(null)
            setRawObjects([])
            setDataMeasurements(null)
            fetchGroups()
            setFirstLoaded(false)
            setSelectedBuildingId(null)
            setObjectPms([])

            let stored_data = localStorage.getItem(`${selectedSite?.Hash}_realtime`)
            if (stored_data) {
                setup(JSON.parse(stored_data))
            }
            let stored_tr = localStorage.getItem(`${selectedSite?.Hash}_translation`)
            if (stored_tr) {
                setTranslations(JSON.parse(stored_tr))
            }
            const interval = setInterval(() => {
                fetchData(false)
            }, 10000)
            setconstraints()
            return () => clearInterval(interval)
        } else {
            setLoading(false)
        }
    }, [selectedSite])

    useEffect(() => {
        if (selectedSite?.Hash) {
            setBookings([])
            _getBookings()
        }
    }, [selectedSite])

    const _getBookings = async () => {
        let res = await getBookings(selectedSite?.Hash)
        if (res?.message?.bookings?.length > 0) {
            setBookings(res?.message?.bookings)
        }
    }

    useEffect(() => {
        if (selectedObject?.ObjectId) {
            navigate(`/realtime?site=${selectedSite?.Hash}&objectId=${selectedObject.ObjectId}`)
            setModal(true)
        }
    }, [selectedObject])

    const fetchGroups = async () => {
        const res = await getGroups(selectedSite.Hash)
        if (res?.groups) {
            setGroups(res?.groups)
        }
    }

    const setconstraints = async () => {
        let res = await getConstraints(selectedSite?.Hash)
        if (res?.constraints) {
            setConstraints(res?.constraints)
        }
    }

    const fetchData = async (init) => {
        let focused = document?.visibilityState
        /** we don't want to fetch data is the user is not looking at his screen or when we display equipment modal */
        if (focused !== 'visible' || (isFetchingRef.current && !init) || modalRef.current) return
        const hash = selectedSite?.Hash

        setIsFetching(true)
        if (hash) {
            if (init) {
                setLoading(true)
                setRawObjects(null)
            }

            const res = await getObjectsForSite({ hash, realtime: true })
            if (res && res.objects && hash == hashRef.current) {
                setUnreachable(false)
                let changes = getChanges(dmRef.current, res?.dataMeasurements, res?.sensors, res.objects)
                setNotifications([...changes, ...notifRef.current])
                // localStorage.setItem(
                //   `${selectedSite?.Hash}_realtime`,
                //   JSON.stringify(res)
                // );
                await setup(res)
                // let actions_failed = [2, 2];
                // let actions_passed = [2, 2];
                let { actions_passed, actions_failed } = verifyActionIsPassed(res?.dataMeasurements)

                if (actions_failed?.length > 0) {
                    setShowAlert('warning')
                    await warnActionNotPassed({
                        siteName: selectedSite?.Name,
                        actions: JSON.stringify(actions_failed),
                    })
                } else if (actions_passed?.length) {
                    setShowAlert('success')
                }
                if (res?.objectPms) {
                    setObjectPms(res?.objectPms)
                }
                setFirstLoaded(true)
            }
            if (hash == hashRef.current) setLoading(false)
            if (init) {
                const tr = await getTranslations(hash)

                if (tr?.translations) {
                    // localStorage.setItem(
                    //   `${selectedSite?.Hash}_translation`,
                    //   JSON.stringify(tr?.translations)
                    // );
                    setTranslations(tr?.translations)
                }
            }
            let stored_data = localStorage.getItem(`${selectedSite?.Hash}_realtime`)
            if (res && !res?.objects && stored_data) {
                setUnreachable(true)
            }
        }
        setIsFetching(false)
    }

    const setup = async (res) => {
        let new_hash = object_hash({
            objects: res.objects?.map((el) => {
                return { ...el, fathers: null }
            }),
        })
        let old_hash = object_hash({
            objects: rawRef.current?.map((el) => {
                return { ...el, fathers: null }
            }),
        })
        if (old_hash !== new_hash) {
            let newRaw = res?.objects?.map((el) => {
                let fathers = extractFathers(selectedSite, el, res?.objects)
                return { ...el, fathers }
            })
            setRawObjects([...newRaw])
        }

        let buildings = res?.objects?.filter((el) => el?.ObjectTypeId === 2)
        setBuildings(buildings)
        let site = res.objects?.find((el) => el?.ObjectTypeId === 1)
        if (site) {
            setPilot(site?.Pilot)
        }
        setSensors(res?.sensors)
        setDataMeasurements(res?.dataMeasurements)
        if (res?.dataMeasurements?.length > 0) {
            let filtered_dm = res?.dataMeasurements?.filter((el) => el?.Value > 1)
            let _lastSetTempDmOld = filtered_dm?.[filtered_dm?.length - 1]?.DateTime
            if (_lastSetTempDmOld !== undefined && _lastSetTempDmOld !== null) {
                // check the last set temp dm old is not more than 1 day
                // if it is more than 1 day, we  show the alert
                // if it is less than 1 day, we don't show the alert
                let now = new Date()
                let diff = now - _lastSetTempDmOld
                if (diff > 86400000) {
                    setlastSetTempDmOld(true)
                }
            }
        }

        setObservationEndDate(res?.site?.ObservationEndDate)
    }

    const togglePilotForObjects = async (objectIds, overRideValue) => {
        setLoading(true)
        setDialog(false)
        await togglePilot(selectedSite?.Hash, {
            objectIds,
            overRideValue,
            newValue: 1 - pilot,
            objects: rawObjects,
        })
        let updatedObjects = rawObjects?.map((el) => {
            if (objectIds?.indexOf(el.ObjectId) > -1) return { ...el, Pilot: 1 - pilot }
            else return el
        })
        setRawObjects(updatedObjects)
        setPilot(1 - pilot)
        setLoading(false)
    }

    const confirmModifications = async (hash) => {
        setSendLoader(true)
        setConfirmDialog(false)
        if (modifications && modifications?.length > 0) {
            let send = insertAction(modifications)
            if (send) {
                await sendAction({
                    groupHashes: hash ? [hash] : [],
                    modifications,
                    hash: selectedSite?.Hash,
                    fromDashboard: true,
                    selectedObject,
                })
            }

            // if (res.success) {
            //   setShowAlert("success");
            // } else {
            //   setShowAlert("warning");
            // }
        }
        if ((objectPilot === 1 || objectPilot === 0) && objectPilot !== selectedObject?.Pilot) {
            await togglePilot(selectedSite?.Hash, {
                objectIds: [selectedObject?.ObjectId],
                overRideValue: objectPilot,
                newValue: objectPilot,
            })
            let objectIds = [selectedObject?.ObjectId]
            let updatedObjects = rawObjects?.map((el) => {
                if (objectIds?.indexOf(el.ObjectId) > -1) return { ...el, Pilot: 1 - pilot }
                else return el
            })
            dispatch(
                GlobalSlice.actions.setSelectedObject({
                    ...selectedObject,
                    Pilot: 1 - pilot,
                })
            )
            setRawObjects(updatedObjects)
        }
        await sleep(5000)
        setSendLoader(false)
        event('SendAction', `${selectedSite?.Name} - ${selectedObject?.ObjectId}`)
        navigate(`/realtime?site=${selectedSite?.Hash}`)
        dispatch(GlobalSlice.actions.setSelectedObject(null))
        setModal(null)
    }

    const saveModifications = async (hash) => {
        if (selectedObject?.ObjectTypeId === 1) {
            setConfirmDialog(true)
        } else {
            await confirmModifications(hash)
        }
    }

    const addModification = (actuatorId, value, title, sensors) => {
        let array = [...modifications]
        let exist = array.find((el) => el.id === actuatorId)
        if (exist) {
            for (let i = 0; i < array.length; i++) {
                if (array[i].id === actuatorId) {
                    array[i].value = value
                    array[i].title = title
                    break
                }
            }
        } else {
            array.push({
                id: actuatorId,
                value,
                title,
                actuatorId: actuatorId,
                sensors,
                createdAt: new Date(),
                hash: makeid(),
            })
        }
        setModifications(array)
    }

    const clearModifications = () => {
        setModifications([])
    }

    const headerPortalContainer = document.getElementById('header-right-content-portal')
    return (
        <ContainerComponent style={{ position: 'relative' }}>
            {headerPortalContainer &&
                createPortal(
                    modal ? (
                        <SaveMenu
                            title="Enregistrer"
                            saveModifications={saveModifications}
                            items={groups.filter((el) => el?.objects?.indexOf(selectedObject?.ObjectId) > -1)}
                        />
                    ) : (
                        <NotificationMenu notifications={notifications} />
                    ),
                    headerPortalContainer
                )}
            {showAlert && (
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={showAlert === 'warning' || showAlert === 'success'}
                    autoHideDuration={6000}
                    onClose={() => setShowAlert(null)}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setShowAlert(null)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                >
                    {showAlert === 'warning' ? (
                        <Alert onClose={() => setShowAlert(null)} severity="warning" sx={{ width: '100%' }}>
                            {locale?.['realTime']?.['error']}
                        </Alert>
                    ) : (
                        <Alert onClose={() => setShowAlert(null)} severity="success" sx={{ width: '100%' }}>
                            {locale?.['realTime']?.['success']}
                        </Alert>
                    )}
                </Snackbar>
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={unreachable}
                // autoHideDuration={6000}
                onClose={() => setUnreachable(false)}
                message="Bâtiment avec une connexion instable"
            />
            {dialog && (
                <Dialog
                    open={dialog}
                    onClose={() => setDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{locale?.['realTime']?.['applyForAll']}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {locale?.['realTime']?.['applyForAllDesc']}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialog(false)}>{locale?.['realTime']?.['cancel']}</Button>
                        <Button
                            onClick={async () => {
                                let site = rawObjects?.find((el) => el?.ObjectTypeId === 1)
                                await togglePilotForObjects([site?.ObjectId])
                            }}
                        >
                            {locale?.['no']}
                        </Button>
                        <Button
                            onClick={async () => {
                                await togglePilotForObjects(
                                    rawObjects.filter((el) => el.Pilot === 0).map((el) => el.ObjectId),
                                    1
                                )
                            }}
                            autoFocus
                        >
                            {locale?.['yes']}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {confirmDialog && (
                <Dialog
                    open={confirmDialog}
                    onClose={() => setConfirmDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{locale?.['realTime']?.['applyForAll']}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {locale?.['realTime']?.['notReco']}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={async () => {
                                event('SendAction', `${selectedSite?.Name} - Full Website Action`)
                                await confirmModifications(null)
                            }}
                            autoFocus
                        >
                            {locale?.['yes']}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {sendLoader ? (
                <Dialog
                    open={sendLoader}
                    onClose={() => setSendLoader(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogTitle
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress />
                        </DialogTitle>
                        <DialogContentText
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Alert severity="warning" style={{ marginBottom: 20 }}>
                                Votre action est en cours de traitement, veuillez patienter...cette opération peut
                                prendre plusieurs secondes
                            </Alert>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            ) : null}
            {!observationEndDate && firstLoaded && !isDemo() && (
                <Alert severity="info" style={{ marginBottom: 20 }}>
                    {locale?.['realTime']?.['notFinishedYet']}
                </Alert>
            )}
            {lastSetTempDmOld && (
                <Alert severity="warning" style={{ marginBottom: 20 }}>
                    La donnée récupérée est trop ancienne, veuillez contacter Agrid si le problème persiste.
                </Alert>
            )}
            {/* {!pilot && !modal && (
          <Alert severity="info" style={{ marginBottom: 40 }}>
            Rien n'apparait ? Vos zones sont peut-être toutes non pilotées !
          </Alert>
        )} */}
            {selectedSite && modal && selectedObject && (
                <ZonePage
                    modal={modal}
                    setModal={setModal}
                    node={selectedObject}
                    selectedSite={selectedSite}
                    addModification={addModification}
                    setObjectPilot={setObjectPilot}
                    clearModifications={clearModifications}
                    observationEndDate={observationEndDate}
                />
            )}

            {!modal && (
                <AlternativeRow
                    objectPms={objectPms}
                    bookings={bookings}
                    selectedBuildingId={selectedBuildingId}
                    groups={groups?.filter((el) => {
                        if (buildings?.length <= 1 || !selectedBuildingId) return true
                        else {
                            let el_obj = el.objects
                            for (let i = 0; i < el_obj?.length; i++) {
                                let find = rawObjects?.find((el) => el?.ObjectId === el_obj[i])
                                let fathers = extractFathers(selectedSite, find, rawObjects)
                                if (fathers?.indexOf(selectedBuildingId) > -1) {
                                    return true
                                }
                            }
                            return false
                        }
                    })}
                    show={(row) => {
                        setScrollY(window.scrollY)
                        dispatch(GlobalSlice.actions.setSelectedObject(row))
                    }}
                    sensors={sensors}
                    dataMeasurements={dataMeasurements}
                    rawObjects={rawObjects
                        ?.filter((el) => el?.ObjectTypeId !== 3 && el?.Name)

                        ?.filter((el) => {
                            if (buildings?.length <= 1 || !selectedBuildingId) return true
                            else {
                                /** more than one building */
                                return el?.fathers?.indexOf(selectedBuildingId) > -1
                            }
                        })}
                    draggable={false}
                    realtime={true}
                    sitePilot={pilot}
                    translations={translations}
                    showSite={() => {
                        let find = rawObjects?.find((el) => el?.ObjectTypeId == 1)
                        dispatch(GlobalSlice.actions.setSelectedObject(find))
                    }}
                    constraints={constraints}
                    hash={selectedSite?.Hash}
                />
            )}
            {loading && (
                <div
                    style={{
                        top: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 10000,
                        left: 0,
                        display: 'flex',
                        paddingTop: 100,
                        justifyContent: 'center',
                        position: 'absolute',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress color="inherit" />
                    <div style={{ fontSize: 10, margin: 15 }}>
                        Veuillez patienter, nous accédons à votre site pour récupérer vos informations..
                    </div>
                </div>
            )}

            {!loading && !selectedSite ? (
                <Alert severity="warning" style={{ marginBottom: 20 }}>
                    Aucun site n'est encore associé à votre compte, veuillez contacter Agrid pour plus d'informations.
                </Alert>
            ) : null}

            {firstLoaded && (rawObjects?.length === 0 || !rawObjects) && <NoDataComponent />}
            {!firstLoaded && <Backdrop open={true} style={{ color: '#fff', zIndex: 1000 }}></Backdrop>}
        </ContainerComponent>
    )
}

export default RealTimePage
