import { post, get } from './utils.service'
import { logout } from './auth.service'

export const getObjectsForSite = async (body) => {
    const res = await post(`/realtime/familytree`, { ...body }, { SiteHash: body.hash })
    return await res?.json()
}

export const getSites = async () => {
    const res = await get(`/realtime/sites`)
    return await res?.json()
}

export const getEquipments = async (body) => {
    const res = await post(`/realtime/equipments`, { ...body }, { SiteHash: body.hash })
    return await res?.json()
}

export const togglePilot = async (hash, body) => {
    const res = await post(`/realtime/togglePilot/${hash}`, { ...body }, { SiteHash: hash })
    return await res?.json()
}

export const getTranslations = async (hash) => {
    const res = await get(`/realtime/translations/${hash}`)
    return await res?.json()
}

export const toggleAgridPms = async (hash, body) => {
    const res = await post(`/realtime/toggleAgridPms/${hash}`, body, {
        SiteHash: hash,
    })
    return await res?.json()
}

export const chatBotConversation = async (body) => {
    const res = await post(`/realtime/chatbot`, body)
    return await res?.json()
}

export const getModeHistory = async (hash, objectId) => {
    const res = await get(`/realtime/modeHistory/${hash}/${objectId}`, {
        SiteHash: hash,
    })
    return await res?.json()
}

export const getBookings = async (hash) => {
    const res = await get(`/realtime/bookings/${hash}`, {
        SiteHash: hash,
    })
    return await res?.json()
}

export const warnActionNotPassed = async (body) => {
    const res = await post(`/realtime/warnActionNotPassed`, body, {
        SiteHash: body?.hash,
    })
    return await res?.json()
}
