import { FC, ReactNode, useState } from 'react'
import { Skeleton, Box, IconButton } from '@mui/material'
import { useEnergyConsumptionsSeries, EnergySeries } from '../../dataProviders/useEnergyConsumptions'
import { getRollingYear } from './utils/getRollingYear'
import { sumByMonth } from './utils/sumByMonth'
import { Card } from '@mui/material'
import { CardTitle } from '../card-title.component'
import moment from 'moment'
import { EnedisDisabled } from '../enedis-disabled.component'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import HighchartsChart from '@/components/Charts/HighchartsChart'
import { useTranslation } from 'react-i18next'

const chartFormatOptions: Highcharts.Options = {
    title: {
        text: '',
    },
    chart: {
        backgroundColor: 'transparent',
    },
    exporting: {
        enabled: false,
    },
    legend: {
        enabled: false,
    },
    yAxis: {
        title: {
            text: '',
        },
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.y:.0f} kWh</b>',
    },
    colors: ['rgb(85, 214, 241)'],
}

interface YearlyConsumptionsBarChartProps {
    energySeries: EnergySeries
    actions?: ReactNode
}

const YearlyConsumptionsBarChart: FC<YearlyConsumptionsBarChartProps> = ({ energySeries, actions }) => {
    const { series, categories } = energySeries
    const { t } = useTranslation('connect')

    const totals = series.reduce((acc, { data }) => {
        data.forEach((value, index) => {
            acc[index] = (acc[index] || 0) + value
        })
        return acc
    }, [] as number[])
    const { months, monthlyTotals } = sumByMonth(categories, totals)

    return (
        <Card sx={{ p: 2, position: 'relative' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <CardTitle title={`${t('energy.rolling_year_consumption')} (kWh)`} style={{}} />
                {actions}
            </Box>
            <HighchartsChart
                containerProps={{ style: { height: '260px' } }}
                options={{
                    ...chartFormatOptions,
                    series: [{ name: t('energy.consumption'), type: 'column', data: monthlyTotals }],
                    xAxis: {
                        categories: months.map((m) => moment(m).format('MMM YY')),
                    },
                }}
            />
            {series.length === 0 && <EnedisDisabled loading={false} />}
        </Card>
    )
}

export default YearlyConsumptionsBarChart

interface SiteYearlyConsumptionsBarChartProps {
    siteId: number
}

export const SiteYearlyConsumptionsBarChart: FC<SiteYearlyConsumptionsBarChartProps> = ({ siteId }) => {
    const [monthsOffset, setMonthsOffset] = useState(0)
    const { energySeries, isLoading, isError } = useEnergyConsumptionsSeries({
        siteId,
        ...getRollingYear({ nMonths: 13, offset: monthsOffset }),
    })

    if (isLoading && !energySeries) {
        return <Skeleton variant="rectangular" width={'100%'} height={300} />
    }

    if (isError || !energySeries) {
        return null
    }
    return (
        <YearlyConsumptionsBarChart
            energySeries={energySeries}
            actions={
                <Box sx={{ display: 'flex', mt: -0.5 }}>
                    <IconButton onClick={() => setMonthsOffset((prev) => prev - 1)} size="small">
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => setMonthsOffset((prev) => prev + 1)}
                        disabled={monthsOffset >= 0}
                        size="small"
                    >
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Box>
            }
        />
    )
}
