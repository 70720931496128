import React, { useState, useEffect } from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote'
import ThermostatIcon from '@mui/icons-material/Thermostat'
import { Box, Container, Grid, Skeleton } from '@mui/material'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { PageTitleComponent } from '../../components/Basic/library'
import { enumerateDaysBetweenDates } from '../../utils/dashboard.utils'
import { Item } from './components/item.component'
import { TemperatureChart } from './components/temperature.chart'
import { ModeHistoryComponent } from './components/mode-history.component'

export const DashboardZone = ({ objectData }) => {
    const { lineData, data, siteAvg, objectId, objectHash, siteHash } = objectData;
    const [line, setLine] = useState(null)
    const [zoneData, setZoneData] = useState(null)
    const calendarStore = useSelector((state) => state.calendarStore)

    const [zoneAverageOn, setZoneAverageOn] = useState(null)
    const [globalAverageOn, setGlobalAverageOn] = useState(null)
    const [zoneAverageTin, setZoneAverageTin] = useState(null)
    const [globalAverageTin, setGlobalAverageTin] = useState(null)
    const [zoneAverageSetTemp, setZoneAverageSetTemp] = useState(null)
    const [globalAverageSetTemp, setGlobalAverageSetTemp] = useState(null)
    const [zoneAverageMode, setZoneAverageMode] = useState(null)

    useEffect(() => {
        setGlobalAverageOn(parseFloat(siteAvg?.onPercentage * 100))
        setGlobalAverageTin(parseFloat(siteAvg?.averageTmp))
        setGlobalAverageSetTemp(parseFloat(siteAvg?.setTemp))
    }, [siteAvg])
    useEffect(() => {
        init()
        if (data && lineData) {
            computeZoneLine()
        }
    }, [objectId, calendarStore.start, calendarStore.end, data, lineData])

    const init = async () => {
        console.log("init", data, lineData)
        setLine(null)
        setZoneAverageTin(null)
        setZoneAverageOn(null)
        setZoneAverageSetTemp(null)
        setZoneAverageMode(null)

        if (data && lineData) {
            computeZoneLine()
        }
    }

    const computeZoneLine = () => {
        let _newLineData = JSON.parse(JSON.stringify([...lineData]))
        let _data = data?.filter((el) => el.ObjectHash == objectHash)
        setZoneData(_data)

        let _newZoneAverageOn = parseInt((_data?.reduce((a, b) => a + b?.OnPercentage, 0) / _data?.length) * 100)

        setZoneAverageOn(_newZoneAverageOn)
        let tmpData = _data?.filter((el) => el?.TinMean)
        setZoneAverageTin(parseInt(tmpData?.reduce((a, b) => a + b?.TinMean, 0) / tmpData?.length))
        let setTempFiltered = _data?.filter((el) => el?.SetTempMean)

        setZoneAverageSetTemp(
            parseInt(
                setTempFiltered?.length > 0
                    ? setTempFiltered?.reduce((a, b) => a + b?.SetTempMean, 0) / setTempFiltered?.length
                    : 0
            )
        )

        if (_data) {
            let labels = enumerateDaysBetweenDates(calendarStore.start, calendarStore.end)
            for (let i = 0; i < labels.length; i++) {
                let label = moment(labels?.[i]).format('YYYY-MM-DD')
                let day = label?.split('T')?.[0]?.split('-')?.[2]
                let month = label?.split('T')?.[0]?.split('-')?.[1]
                let year = label?.split('T')?.[0]?.split('-')?.[0]

                let tmp = _data?.filter((el) => {
                    let _day = el.StartDateTime?.split('T')?.[0]?.split('-')?.[2]
                    let _month = el.StartDateTime?.split('T')?.[0]?.split('-')?.[1]
                    let _year = el.StartDateTime?.split('T')?.[0]?.split('-')?.[0]
                    return `${_day}/${_month}/${_year}}` === `${day}/${month}/${year}}`
                })
                let onTmp = tmp?.map((el) => el?.OnPercentage)
                if (_newLineData[i]) {
                    _newLineData[i]['Zone_OnPercentage'] =
                        onTmp.length > 0 ? (onTmp.reduce((a, b) => a + b, 0) / onTmp.length) * 100 : 0
                    let zoneTmp = tmp?.map((el) => el?.SetTempMean)

                    _newLineData[i]['Zone'] =
                        zoneTmp.length > 0 ? zoneTmp.reduce((a, b) => a + b, 0) / zoneTmp.length : 0
                    _newLineData[i]['setTemp'] =
                        zoneTmp.length > 0 ? zoneTmp.reduce((a, b) => a + b, 0) / zoneTmp.length : 0
                    let tInTmp = tmp?.map((el) => el?.TinMean)

                    _newLineData[i]['tInt'] = tInTmp.length > 0 ? tInTmp.reduce((a, b) => a + b, 0) / tInTmp.length : 0
                }
            }
        }
        // setGlobalAverageOn(parseInt(global?.OnPercentage * 100));
        // setGlobalAverageTin(parseInt(global?.TinMean));
        // setGlobalAverageSetTemp(parseInt(global?.SetTempMean));
        // setGlobalAverageOn(parseInt(siteAvg?.onPercentage * 100));
        // setGlobalAverageTin(parseInt(siteAvg?.tinMean));
        // setGlobalAverageSetTemp(parseInt(siteAvg?.setTempMean));

        if (_data?.length > 0) {
            let _mode = _data?.reduce((a, b) => a + b?.CoolDuration - b?.HeatDuration, 0)

            _mode = _mode > 0 ? 'COOL' : _mode < 0 ? 'HEAT' : 'OTHER'
            setZoneAverageMode(_mode)
        }

        setLine(_newLineData)
    }

    return (
        <Box>
            <Container>
                <Grid container spacing={2} style={{ marginTop: 5 }}>
                    <Grid item md={12}>
                        <PageTitleComponent title=" Sur la période sélectionnée" />
                    </Grid>
                    <Grid item md={4} xs={12} key={zoneAverageOn}>
                        <Item
                            number={zoneData?.length > 0 ? zoneAverageOn : '-'}
                            chip={'par rapport à la moyenne du site'}
                            chipColor={zoneAverageOn - globalAverageOn < 0 ? 'green' : 'orange'}
                            chipValue={
                                zoneData?.length > 0
                                    ? `${zoneAverageOn - globalAverageOn >= 0 ? '+' : ''}${(
                                          zoneAverageOn - globalAverageOn
                                      ).toFixed(1)}%`
                                    : ''
                            }
                            unit={'% du temps allumée'}
                            icon={<AccessTimeIcon style={{ color: '#448aff' }} />}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Item
                            number={zoneData?.length > 0 ? zoneAverageTin : '-'}
                            unit={'°C mesurée en moyenne'}
                            chip={'par rapport à la moyenne du site'}
                            chipColor={
                                (zoneAverageTin - globalAverageTin >= 0 && zoneAverageMode === 'COOL') ||
                                (zoneAverageTin - globalAverageTin <= 0 && zoneAverageMode === 'HEAT')
                                    ? 'green'
                                    : 'orange'
                            }
                            chipValue={
                                zoneData?.length > 0
                                    ? `${zoneAverageTin - globalAverageTin >= 0 ? '+' : ''}${(
                                          zoneAverageTin - globalAverageTin
                                      ).toFixed(1)}°C`
                                    : ''
                            }
                            icon={<ThermostatIcon style={{ color: '#448aff' }} />}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Item
                            number={zoneData?.length > 0 ? zoneAverageSetTemp : ''}
                            unit={'°C demandée en moyenne'}
                            chip={`par rapport à la moyenne du site`}
                            chipColor={
                                (zoneAverageSetTemp >= globalAverageSetTemp && zoneAverageMode === 'COOL') ||
                                (zoneAverageSetTemp <= globalAverageSetTemp && zoneAverageMode === 'HEAT')
                                    ? 'green'
                                    : 'orange'
                            }
                            chipValue={
                                zoneData?.length > 0 && zoneAverageOn > 0
                                    ? `${
                                          zoneAverageSetTemp - globalAverageSetTemp > 0 ? '+' : ''
                                      }${(zoneAverageSetTemp - globalAverageSetTemp).toFixed(1)}°C`
                                    : '-'
                            }
                            icon={<SettingsRemoteIcon style={{ color: '#448aff' }} />}
                        />
                    </Grid>

                    {line ? (
                        <Grid item md={12}>
                            <TemperatureChart data={line} />
                        </Grid>
                    ) : (
                        <Skeleton variant="rectangular" width={'100%'} height={500} />
                    )}

                    <Grid item md={12}>
                        <ModeHistoryComponent hash={siteHash} objectId={objectId} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
