import { CardContent } from '@mui/material'
import React, { useState } from 'react'

import { Button, Grid } from '@mui/material'
import { Card } from 'reactstrap'
import Daikin from '../../../assets/img/other/daikin.png'
import Fujitsu from '../../../assets/img/other/fujitsu.png'
import Mitsubishi from '../../../assets/img/other/mitsubishi.png'
import Layout from '../../../components/Global/layout.component'

const _Title = ({ label }) => {
    return <div style={{ color: 'black', fontSize: 22 }}>{label}</div>
}

const TutorialsPage = () => {
    const [selected, setSelected] = useState(null)
    const [path, setPath] = useState([])
    const tunnels = [
        {
            name: 'mitsubishi',
            img: Mitsubishi,
            children: [
                {
                    content: [
                        { type: 'title', label: 'Quel est le modèle de votre GTC ?' },
                        {
                            type: 'button',
                            label: "J'ai un AE200",
                            onClick: () => {
                                setPath([...path, 0])
                            },
                        },
                        {
                            type: 'button',
                            label: "J'ai un AE210",
                            onClick: () => {
                                setPath([...path, 1])
                            },
                        },
                    ],
                    children: [
                        {
                            content: [{ type: 'title', label: "J'ai un AE200" }],
                        },
                        {
                            content: [{ type: 'title', label: "J'ai un AE210" }],
                        },
                    ],
                },
            ],
        },
        { name: 'daikin', img: Daikin, children: [] },
        { name: 'fujitsu', img: Fujitsu, children: [] },
    ]

    const formatContent = () => {
        let out = selected?.children[0]
        for (let i = 0; i < path.length; i++) {
            out = out?.children[path[i]]
        }
        return out?.content
    }
    return (
        <Layout title="Tutoriel">
            <Grid container spacing={2}>
                {selected && <Button onClick={() => setSelected(null)}>Retour</Button>}
                {!selected &&
                    tunnels?.map((el) => {
                        return (
                            <Grid item xs={4}>
                                <Card
                                    style={{
                                        height: 120,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setSelected(el)}
                                >
                                    <CardContent>
                                        <img src={el?.img} style={{ maxWidth: 150, objectFit: 'contain' }} />
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })}
            </Grid>
            {selected &&
                formatContent()?.map((el) => {
                    if (el?.type === 'title') return <_Title label={el?.label} />
                })}
        </Layout>
    )
}

export default TutorialsPage
