import React, { useEffect, useState } from 'react'

// import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { QRCodeCanvas } from 'qrcode.react'
import { CardBody, CardHeader } from 'reactstrap'
import Layout from '../../components/Global/layout.component'

import { Button, Card, CircularProgress, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { TitleComponent } from '../../components/Basic/library'
import { getObjectsForSite } from '../../services/realtime.service'
import { getSiteNotes, getSiteSettings } from '../../services/site.service'
import { sleep } from '../../utils/global.utils'
import useType from '../../utils/hooks.utils'
import useLocale from '../../utils/locale/locale.hook'
import DocumentModal from './site/document.modal'
import BasicTable from './site/documents.table'
import NoteTable from './site/notes.table'
// import NoteModal from "./site/note.modal";
import { ContainerComponent } from '../../components/Global/container.component'
import AdminPage from './admin.page'
const UserPage = () => {
    const locale = useLocale()
    const [objects, setObjects] = useState([])
    const [color, setColor] = useState('black')
    const { selectedSite } = useSelector((state) => state.globalStore)
    const [parsedObjects, setParsedObjects] = useState([])
    const [trigger, setTrigger] = useState(false)
    const [documents, setDocuments] = useState([])
    const [notes, setNotes] = useState([])
    const [selectedDocument, setSelectedDocument] = useState(null)
    const [selectedNote, setSelectedNote] = useState(null)
    const [documentLoader, setDocumentLoader] = useState(false)
    const documentTypes = ['doe', 'plan', 'edf', 'enedis']

    useEffect(() => {
        if (selectedSite?.Hash) {
            fetchObjects()
            init()
        }
    }, [selectedSite])

    const fetchObjects = async () => {
        setParsedObjects([])
        const res = await getObjectsForSite({ hash: selectedSite?.Hash })
        if (res.objects) {
            let newObjects = res?.objects?.filter(
                (el) => el?.ObjectTypeId !== 1 && el?.ObjectTypeId !== 3 && el?.ObjectHash
            )
            setObjects([...newObjects])
            let isOk = await verify([...newObjects])
            while (!isOk) {
                await sleep(500)
                isOk = await verify([...newObjects])
            }
            // await sleep(2000);
            let items = newObjects.map((el) => {
                return {
                    qr: document?.getElementById(`qrcode_${el?.ObjectHash}`)?.toDataURL(),
                    name: el?.Name,
                }
            })
            setParsedObjects(items)
        }
    }

    const init = async () => {
        setDocumentLoader(true)
        let res = await getSiteSettings(selectedSite?.Hash)
        if (res?.documents) setDocuments(res?.documents)
        res = await getSiteNotes(selectedSite?.Hash)
        if (res?.notes) setNotes(res?.notes)
        setDocumentLoader(false)
    }

    const verify = async (obj) => {
        if (obj?.length === 0) return false
        let out = true
        for (let i = 0; i < obj.length; i++) {
            let item = document?.getElementById(`qrcode_${obj[i]?.ObjectHash}`)?.toDataURL()

            if (!item || item?.length < 1000) {
                return false
            }
        }
        return out
    }

    return (
        <Layout title={locale?.['navBar']?.['settings']} showSites={true}>
            {selectedDocument && (
                <DocumentModal
                    open={selectedDocument ? true : false}
                    handleClose={() => {
                        setSelectedDocument(null)
                        init()
                    }}
                    selectedDocument={selectedDocument}
                    documents={documents}
                    selectedSite={selectedSite}
                    documentTypes={documentTypes}
                />
            )}
            {/* {selectedNote && (
        <NoteModal
          open={selectedNote ? true : false}
          handleClose={() => {
            setSelectedNote(null);
            init();
          }}
          selectedNote={selectedNote}
          notes={notes}
          selectedSite={selectedSite}
        />
      )} */}
            <ContainerComponent>
                <Grid container spacing={2}>
                    {/* {type === "admin" && (
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TitleComponent title={"Etiquettes"} />
                  <div style={{ color: "#03a9f4" }}>
                    {parsedObjects?.length > 0 && trigger && (
                      <PDFDownloadLink
                        document={<Pdf color={color} qrCodes={parsedObjects} />}
                        fileName="agrid.pdf"
                        style={{ color: "rgb(66, 133, 244)" }}
                      >
                        {({ blob, url, loading, error }) =>
                          loading
                            ? locale?.pages?.["settings"]?.["loadingPdf"]
                            : locale?.pages?.["settings"]?.["downloadPdf"]
                        }
                      </PDFDownloadLink>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  <Grid container>
                    {!trigger ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          setTrigger(true);
                        }}
                        disabled={parsedObjects?.length == 0}
                      >
                        Générer des étiquettes pour mon bâtiment{" "}
                        {parsedObjects?.length === 0 && (
                          <CircularProgress color="inherit" size={12} />
                        )}
                      </Button>
                    ) : (
                      <Grid item xs={12} md={4}>
                        {parsedObjects?.length > 0 ? (
                          <PDFViewer>
                            {objects.map((el) => {
                              return (
                                <Pdf color={color} qrCodes={parsedObjects} />
                              );
                            })}
                          </PDFViewer>
                        ) : (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CircularProgress color="inherit" />
                            <span style={{ padding: 4 }}>
                              Génération d'étiquettes en cours...
                            </span>
                          </div>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <div style={{ display: "none" }}>
                    {objects?.map((el, index) => {
                      // if (index === objects?.length - 1) setQrDisplayed(true);
                      return (
                        <QRCodeCanvas
                          value={`https://api.a-grid.com/api/client-app/scan?hash=${selectedSite?.Hash}&objectHash=${el?.ObjectHash}`}
                          id={`qrcode_${el?.ObjectHash}`}
                        />
                      );
                    })}
                  </div>
                </CardBody>
              </Card>
            </Grid>
          )} */}
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <TitleComponent title={'Mes documents'} />
                                {
                                    <div
                                        style={{ color: '#03a9f4', cursor: 'pointer' }}
                                        onClick={() => {
                                            setSelectedDocument({
                                                DocumentType: documentTypes?.[0],
                                                Name: '',
                                            })
                                        }}
                                    >
                                        Ajouter
                                    </div>
                                }
                            </CardHeader>
                            <CardBody>
                                <BasicTable
                                    documents={documents?.filter((el) => documentTypes?.indexOf(el.DocumentType) > -1)}
                                    setSelectedDocument={setSelectedDocument}
                                    editable={true}
                                    documentLoader={documentLoader}
                                />
                            </CardBody>
                        </Card>
                    </Grid>

                    <Grid item xs={12}>
                        <Card>
                            <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <TitleComponent title={'Mes notes'} />
                                <div
                                    style={{ color: '#03a9f4', cursor: 'pointer' }}
                                    onClick={() => {
                                        setSelectedNote({
                                            Note: '',
                                            Title: '',
                                        })
                                    }}
                                >
                                    Ajouter
                                </div>
                            </CardHeader>
                            <CardBody>
                                <NoteTable notes={notes} setNote={setSelectedNote} editable={true} />
                            </CardBody>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        {<AdminPage />}
                    </Grid>
                </Grid>
            </ContainerComponent>
        </Layout>
    )
}

export default UserPage
