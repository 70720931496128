import { VFC } from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { categoryFilterKeyTranslations } from '../../types'
import { IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    }
}

interface CategoryFilterProps {
    title: string
    values: string[]
    options: string[]
    setValues: (values: string[]) => void
}

const CategoryFilter: VFC<CategoryFilterProps> = ({ title, options, values, setValues }) => {
    // https://mui.com/material-ui/react-select/#chip
    const theme = useTheme()

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event
        setValues(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }
    const translatedTitle = categoryFilterKeyTranslations[title] || title
    return (
        <FormControl>
            <InputLabel>{translatedTitle}</InputLabel>
            <Select
                id={`chip-select-{title}`}
                multiple
                value={values}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label={translatedTitle} />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} size="small" />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
                endAdornment={
                    <Box sx={{ display: 'flex', mr: 1.5 }}>
                        {values.length > 0 && (
                            <IconButton onClick={() => setValues([])} title="Effacer" size="small">
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Box>
                }
            >
                {options.map((option) => (
                    <MenuItem key={`option-${title}-${option}`} value={option} style={getStyles(option, values, theme)}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default CategoryFilter
