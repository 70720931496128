import React from 'react'

import { Card, CardContent, Modal } from '@mui/material'

export const CardModal = ({ modal, setModal, content }) => {
    return (
        <Modal
            open={modal}
            onClose={() => setModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card
                sx={{
                    position: 'absolute',
                    top: { md: '50%', xs: 40 },
                    left: { md: '50%', xs: 4 },
                    right: { md: '', xs: 4 },
                    transform: { md: 'translate(-50%, -50%)', xs: '' },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <CardContent>{content}</CardContent>
            </Card>
        </Modal>
    )
}
