import React from 'react'

class ModalComponent extends React.Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()
    }

    handleClick = (event) => {
        let id = event.target.id
        if (id === 'modal-wrapper') {
            this.props.handleModalOutsideClick()
        }
    }

    render() {
        return (
            <div style={styles.wrapper} onClick={this.handleClick} id="modal-wrapper" ref={this.myRef}>
                <div
                    style={{ ...styles.container, ...this.props.containerStyle }}
                    ref={(ref) => (this.container = ref)}
                >
                    {this.props.children}
                </div>
            </div>
        )
    }
}

const styles = {
    wrapper: {
        height: '100%',
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.2)',
        zIndex: 10,
    },
    container: {
        width: '60%',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 50,
        position: 'relative',
        overflow: 'scroll',
        boxSizing: 'border-box',
    },
}

export default ModalComponent
