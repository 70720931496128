import { store } from '../store/configure.store'
import { getDataPoint } from '../services/sensors.service'
import { formatNumberByType } from './global.utils'
import moment from 'moment'
export const colors = {
    1: '#b2dfdb',
    2: '#bbdefb',
    3: '#c5cae9',
    4: '#f8bbd0',
    5: '#e1bee7',
    6: '#f0f4c3',
    7: '#ffe0b2',
}
export const generateFamilyTree = (objectsList) => {
    const nodes = objectsList.map((el) => {
        return {
            id: el.ObjectId,
            label: el.Name,
            title: el.Name,
            color: colors[el.ObjectTypeId] || '#e04141',
        }
    })
    const edges = objectsList
        .filter((el) => el.FatherId)
        .map((el) => {
            return { from: el.FatherId, to: el.ObjectId }
        })
    return {
        edges,
        nodes,
    }
}

export const options = {
    layout: {
        improvedLayout: true,
        hierarchical: {
            direction: 'UD',
            enabled: true,
            parentCentralization: true,
            sortMethod: 'directed',
            shakeTowards: 'leaves',
        },
    },
    edges: {
        color: '#000000',
        physics: false,
    },
    height: '100%',
    width: '100%',
    clickToUse: true,
    physics: {
        enabled: true,
        repulsion: {
            damping: 0,
        },
    },
    interaction: { dragView: true, hover: true },
    nodes: { shape: 'box' },
}

export const fetchDataPoint = async (sensors, setCurrentValue, setActuator, setDate, setOptions, type) => {
    const storeData = store.getState()
    const globalStore = storeData.globalStore
    let output = 0
    if (sensors) {
        let res = await getDataPoint({
            hash: globalStore?.selectedSite?.Hash,
            sensors: [...sensors],
        })
        let measurement = res?.measurement?.[0]
        if (measurement && measurement?.data?.length > 0) {
            let value = measurement.data.reduce((total, next) => total + next.Value, 0) / measurement?.data?.length
            let val = formatNumberByType(type, value)
            setCurrentValue(val)

            setDate(measurement.data[0].DateTime)
            setOptions(measurement.options)
            output = val
        } else if (measurement?.data?.length === 0) {
            setCurrentValue(0)
            setOptions(measurement.options)
            output = 0
        }
        if (res && measurement?.actuator) {
            setActuator(measurement.actuator)
            setOptions(measurement.options)
        }
    }
    return output
}

export const formatTree = (data, expand) => {
    let output = []
    if (!data) return []
    for (let i = 0; i < data.length; i++) {
        //let father = data.find((el) => el.ObjectId === data[i].FatherId);

        let expanded = data[i]?.expanded === false || data[i]?.expanded === true ? data[i]?.expanded : true
        let item = {
            id: data[i].ObjectId,
            title: data[i].Name,
            fatherId: data[i].FatherId,
            children: [],
            expanded: expanded,
            objectTypeId: data[i].ObjectTypeId,
            idLocalHub: data[i].IdLocal_Hub,
        }
        output.push(item)
    }
    let root = []

    const idMapping = output.reduce((acc, el, i) => {
        acc[el.id] = i
        return acc
    }, {})

    output.forEach((el) => {
        // Handle the root element
        if (el.fatherId === null) {
            root.push(el)
            return
        }
        // Use our mapping to locate the parent element in our data array
        const fatherId = output[idMapping[el.fatherId]]
        // Add our current el to its parent's `children` array
        fatherId.children = [...(fatherId.children || []), el]
    })

    return root
}

export function changeNodeAtPath({ treeData, path, newNode, getNodeKey, ignoreCollapsed = true }) {
    const RESULT_MISS = 'RESULT_MISS'
    const traverse = ({ isPseudoRoot = false, node, currentTreeIndex, pathIndex }) => {
        if (!isPseudoRoot && getNodeKey({ node, treeIndex: currentTreeIndex }) !== path[pathIndex]) {
            return RESULT_MISS
        }

        if (pathIndex >= path.length - 1) {
            // If this is the final location in the path, return its changed form
            return typeof newNode === 'function' ? newNode({ node, treeIndex: currentTreeIndex }) : newNode
        }
        if (!node.children) {
            // If this node is part of the path, but has no children, return the unchanged node
            throw new Error('Path referenced children of node with no children.')
        }

        let nextTreeIndex = currentTreeIndex + 1
        for (let i = 0; i < node.children.length; i += 1) {
            const result = traverse({
                node: node.children[i],
                currentTreeIndex: nextTreeIndex,
                pathIndex: pathIndex + 1,
            })

            // If the result went down the correct path
            if (result !== RESULT_MISS) {
                if (result) {
                    // If the result was truthy (in this case, an object),
                    //  pass it to the next level of recursion up
                    return {
                        ...node,
                        children: [...node.children.slice(0, i), result, ...node.children.slice(i + 1)],
                    }
                }
                // If the result was falsy (returned from the newNode function), then
                //  delete the node from the array.
                return {
                    ...node,
                    children: [...node.children.slice(0, i), ...node.children.slice(i + 1)],
                }
            }

            nextTreeIndex += 1 + getDescendantCount({ node: node.children[i], ignoreCollapsed })
        }

        return RESULT_MISS
    }

    // Use a pseudo-root node in the beginning traversal
    const result = traverse({
        node: { children: treeData },
        currentTreeIndex: -1,
        pathIndex: -1,
        isPseudoRoot: true,
    })

    if (result === RESULT_MISS) {
        throw new Error('No node found at the given path.')
    }
    return result.children
}

export function getDescendantCount({ node, ignoreCollapsed = true }) {
    return (
        getNodeDataAtTreeIndexOrNextIndex({
            getNodeKey: () => {},
            ignoreCollapsed,
            node,
            currentIndex: 0,
            targetIndex: -1,
        }).nextIndex - 1
    )
}

function getNodeDataAtTreeIndexOrNextIndex({
    targetIndex,
    node,
    currentIndex,
    getNodeKey,
    path = [],
    lowerSiblingCounts = [],
    ignoreCollapsed = true,
    isPseudoRoot = false,
}) {
    // The pseudo-root is not considered in the path
    const selfPath = !isPseudoRoot ? [...path, getNodeKey({ node, treeIndex: currentIndex })] : []

    // Return target node when found
    if (currentIndex === targetIndex) {
        return {
            node,
            lowerSiblingCounts,
            path: selfPath,
        }
    }

    // Add one and continue for nodes with no children or hidden children
    if (!node.children || (ignoreCollapsed && node.expanded !== true)) {
        return { nextIndex: currentIndex + 1 }
    }

    // Iterate over each child and their descendants and return the
    // target node if childIndex reaches the targetIndex
    let childIndex = currentIndex + 1
    const childCount = node.children.length
    for (let i = 0; i < childCount; i += 1) {
        const result = getNodeDataAtTreeIndexOrNextIndex({
            ignoreCollapsed,
            getNodeKey,
            targetIndex,
            node: node.children[i],
            currentIndex: childIndex,
            lowerSiblingCounts: [...lowerSiblingCounts, childCount - i - 1],
            path: selfPath,
        })

        if (result.node) {
            return result
        }

        childIndex = result.nextIndex
    }

    // If the target node is not found, return the farthest traversed index
    return { nextIndex: childIndex }
}

export function mapDescendants({
    callback,
    getNodeKey,
    ignoreCollapsed,
    isPseudoRoot = false,
    node,
    parentNode = null,
    currentIndex,
    path = [],
    lowerSiblingCounts = [],
}) {
    const nextNode = { ...node }

    // The pseudo-root is not considered in the path
    const selfPath = isPseudoRoot ? [] : [...path, getNodeKey({ node: nextNode, treeIndex: currentIndex })]
    const selfInfo = {
        node: nextNode,
        parentNode,
        path: selfPath,
        lowerSiblingCounts,
        treeIndex: currentIndex,
    }

    // Return self on nodes with no children or hidden children
    if (!nextNode.children || (nextNode.expanded !== true && ignoreCollapsed && !isPseudoRoot)) {
        return {
            treeIndex: currentIndex,
            node: callback(selfInfo),
        }
    }

    // Get all descendants
    let childIndex = currentIndex
    const childCount = nextNode.children.length
    if (typeof nextNode.children !== 'function') {
        nextNode.children = nextNode.children.map((child, i) => {
            const mapResult = mapDescendants({
                callback,
                getNodeKey,
                ignoreCollapsed,
                node: child,
                parentNode: isPseudoRoot ? null : nextNode,
                currentIndex: childIndex + 1,
                lowerSiblingCounts: [...lowerSiblingCounts, childCount - i - 1],
                path: selfPath,
            })
            childIndex = mapResult.treeIndex

            return mapResult.node
        })
    }

    return {
        node: callback(selfInfo),
        treeIndex: childIndex,
    }
}

export function walkDescendants({
    callback,
    getNodeKey,
    ignoreCollapsed,
    isPseudoRoot = false,
    node,
    parentNode = null,
    currentIndex,
    path = [],
    lowerSiblingCounts = [],
}) {
    // The pseudo-root is not considered in the path
    const selfPath = isPseudoRoot ? [] : [...path, getNodeKey({ node, treeIndex: currentIndex })]
    const selfInfo = isPseudoRoot
        ? null
        : {
              node,
              parentNode,
              path: selfPath,
              lowerSiblingCounts,
              treeIndex: currentIndex,
          }

    if (!isPseudoRoot) {
        const callbackResult = callback(selfInfo)

        // Cut walk short if the callback returned false
        if (callbackResult === false) {
            return false
        }
    }

    // Return self on nodes with no children or hidden children
    if (!node.children || (node.expanded !== true && ignoreCollapsed && !isPseudoRoot)) {
        return currentIndex
    }

    // Get all descendants
    let childIndex = currentIndex
    const childCount = node.children.length
    if (typeof node.children !== 'function') {
        for (let i = 0; i < childCount; i += 1) {
            childIndex = walkDescendants({
                callback,
                getNodeKey,
                ignoreCollapsed,
                node: node.children[i],
                parentNode: isPseudoRoot ? null : node,
                currentIndex: childIndex + 1,
                lowerSiblingCounts: [...lowerSiblingCounts, childCount - i - 1],
                path: selfPath,
            })

            // Cut walk short if the callback returned false
            if (childIndex === false) {
                return false
            }
        }
    }

    return childIndex
}

export const changeNodeAtPathPromise = async ({ treeData, path, newNode, getNodeKey, ignoreCollapsed = true }) => {
    return new Promise((resolve, reject) => {
        let output = changeNodeAtPath({
            treeData,
            path,
            newNode,
            getNodeKey,
        })
        resolve(output)
    })
}

export const insertAction = (actions) => {
    let local_actions = localStorage.getItem('actions')
    local_actions = JSON.parse(local_actions) || []
    /** we must filter out old command for the same actuatorId */
    let actuatorIds = actions?.map((el) => el?.id)
    let find = local_actions?.find(
        (el) => actuatorIds.indexOf(el?.actuatorId) > -1 && actions?.map((eq) => eq?.value).indexOf(el?.value) > -1
    )
    if (find) return false
    local_actions = local_actions?.filter((el) => actuatorIds.indexOf(el?.actuatorId) === -1)
    local_actions = [...local_actions, ...actions]
    localStorage.setItem('actions', JSON.stringify(local_actions))
    return true
}

export const verifyActionIsPassed = (dataMeasurements) => {
    let local_actions = localStorage.getItem('actions')
    local_actions = JSON.parse(local_actions) || []
    local_actions?.sort((a, b) => {
        let diff = moment(new Date(b.createdAt)).diff(moment(new Date(a?.createdAt)), 'minutes')
        return diff
    })
    if (local_actions?.length === 0) return { actions_failed: [], actions_passed: [] }
    let sensors = local_actions?.map((el) => el.sensors)
    sensors = [].concat.apply([], sensors)
    let actions_passed = []
    dataMeasurements = dataMeasurements?.filter((el) => sensors?.indexOf(el.SensorId) > -1)

    for (let i = 0; i < dataMeasurements?.length; i++) {
        let find = local_actions?.find((el) => el?.sensors?.indexOf(dataMeasurements[i]?.SensorId) > -1)
        if (find?.value == dataMeasurements?.[i]?.Value) {
            actions_passed.push(find)
        }
    }
    let hashes = actions_passed?.map((el) => el?.hash)
    local_actions = local_actions?.filter((el) => hashes?.indexOf(el?.hash) === -1)

    let actions_failed = local_actions?.filter((el) => moment().diff(moment(el?.createdAt), 'seconds') > 40)
    local_actions = local_actions?.filter((el) => moment().diff(moment(el?.createdAt), 'seconds') <= 40)

    localStorage.setItem('actions', JSON.stringify(local_actions))
    return {
        actions_passed: actions_passed || [],
        actions_failed: actions_failed || [],
    }
}

// data format is {DataMeasurementId, DateTime, SensorId, Value}
// we want to know if something has changed
export const getChanges = (oldData, newData, sensors, objects) => {
    let changes = []
    for (let i = 0; i < newData?.length; i++) {
        let sensor = sensors?.find(
            (el) => el?.SensorId === newData[i]?.SensorId && (el?.DataTypeId === 226 || el?.DataTypeId == 228)
        )
        if (sensor) {
            let object = objects?.find((el) => el?.ObjectId === sensor?.ObjectId)
            if (object) {
                let find = oldData?.find(
                    (el) => el?.SensorId === newData[i]?.SensorId && el?.Value !== newData[i]?.Value
                )
                if (find) {
                    changes.push({ ...newData[i], sensor, object })
                }
            }
        }
    }
    return changes
}
