import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { ReferenceStudyData } from '../types'
import { camelCaseDeep } from '@/utils/camelCaseDeep'

const REFERENCE_STUDIES_URL = 'https://connect-demo-data.s3.eu-central-1.amazonaws.com/references.json'

export const useReferenceStudies = () => {
    const [selectedStudyName, setSelectedStudyName] = useState<string | null>(null)
    const { data: referenceStudies = [] } = useQuery<ReferenceStudyData[]>({
        queryKey: ['referenceStudies'],
        queryFn: async () => {
            const response = await fetch(REFERENCE_STUDIES_URL, { method: 'GET', cache: 'no-cache' })
            if (!response.ok) {
                throw new Error('Failed to fetch reference studies')
            }
            const data = await response.json()
            return camelCaseDeep(data) as unknown as ReferenceStudyData[]
        },
        staleTime: Infinity,
    })
    const selectedStudy = referenceStudies.find(({ fullName }) => fullName === selectedStudyName)

    return { referenceStudies, selectedStudy, selectedStudyName, setSelectedStudyName }
}
