import React from 'react'
import { CardBody, Col, Row } from 'reactstrap'
import DocumentsForm from './documents.form'

const DocumentsWrapper = ({ documents, setDocuments }) => {
    const onDrop = (key, files) => {
        let newDocuments = documents.filter((el) => el.DocumentType != key)
        newDocuments = [...newDocuments, ...files]
        setDocuments(newDocuments)
    }
    return (
        <>
            <CardBody>
                <Row>
                    <Col className="mb-3" md="4">
                        <DocumentsForm
                            title="DOE"
                            onDrop={(files) => onDrop('doe', files)}
                            DocumentType="doe"
                            documents={documents.filter((doc) => doc.DocumentType == 'doe')}
                        />
                    </Col>
                    <Col className="mb-3" md="4">
                        <DocumentsForm
                            title="Plans d'architecte (pdf, png, jpg)"
                            onDrop={(files) => onDrop('plan', files)}
                            DocumentType="plan"
                            documents={documents.filter((doc) => doc.DocumentType == 'plan')}
                        />
                    </Col>
                    <Col className="mb-3" md="4">
                        <DocumentsForm
                            title="Factures de l'an passé (elec, gaz)"
                            onDrop={(files) => onDrop('invoice', files)}
                            DocumentType="invoice"
                            documents={documents.filter((doc) => doc.DocumentType == 'invoice')}
                        />
                    </Col>
                </Row>
            </CardBody>
        </>
    )
}

export default DocumentsWrapper
