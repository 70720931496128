import React from 'react'
import { Avatar, Box, Typography } from '@mui/material'

const collaborators = [
    {
        name: 'John Doe',
        email: 'john.doe@example.com',
        progress: 75,
        image: 'https://thispersondoesnotexist.com/image',
    },
    {
        name: 'Jane Smith',
        email: 'jane.smith@example.com',
        progress: 50,
        image: 'https://thispersondoesnotexist.com/image',
    },
    {
        name: 'Alex Johnson',
        email: 'alex.johnson@example.com',
        progress: 90,
        image: 'https://thispersondoesnotexist.com/image',
    },
]

export const CustomProgressBar = ({ progress }) => {
    return (
        <Box sx={{ width: '100%', height: 10, backgroundColor: '#e0e0e0', borderRadius: 5 }}>
            <Box sx={{ width: `${progress}%`, height: '100%', backgroundColor: '#69f0ae', borderRadius: 5 }} />
        </Box>
    )
}

export const CollaboratorList = () => {
    return (
        <>
            {collaborators.map((collaborator, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <Avatar src={'https://thispersondoesnotexist.com'} sx={{ width: 30, height: 30, marginRight: 2 }} />
                    <Box sx={{ flexGrow: 1 }}>
                        {/* <Typography variant="subtitle1">{collaborator.name}</Typography> */}
                        <Typography variant="body2" color="textSecondary">
                            {collaborator.email}
                        </Typography>
                        <CustomProgressBar progress={collaborator.progress} />
                    </Box>
                </Box>
            ))}
        </>
    )
}
