import { createSlice } from '@reduxjs/toolkit'

const RealtimeSlice = createSlice({
    name: 'me',
    initialState: {
        selectedSite: null,
        selectedObject: null,
        objects: [],
    },
    reducers: {
        updateRealtime: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            }
        },
    },
})

export default RealtimeSlice
