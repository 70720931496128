import { get } from './utils.service'

export const getS3Url = async (type) => {
    const res = await get(`/s3/url?type=${type}`)
    return await res.json()
}

export const uploadFile = async (file) => {
    let type = file?.type || file?.Type
    const { url } = await getS3Url(type)
    const headersContent = {
        'Content-Type': file?.type,
        Accept: 'application/json',
    }
    const headers = new Headers(headersContent)
    const response = await fetch(url, {
        method: 'PUT',
        body: file,
        headers,
    })

    if (!response) return null
    let resUrl = new URL(response.url)

    return resUrl.protocol + '//' + resUrl.host + resUrl.pathname
}
