import { Card, CardContent, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Logo from '../../../assets/img/brand/logo.png'
import { formatNumber } from '../../../utils/global.utils'

import AspectRatioIcon from '@mui/icons-material/AspectRatio'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import { linkBlue, normalGrey } from '../../../components/Basic/colors'
import { DoughnutGroup } from './doughnut-group.component'
import { useTranslation } from 'react-i18next'

export const GroupSummaryComponent = ({ sites }) => {
    const [surface, setSurface] = useState(null)
    const { t } = useTranslation('connect', { keyPrefix: 'groupDashboard' })
    const scroll = () => {
        let id = 'dashboard-group-table'
        const element = document.getElementById(id)
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }

    useEffect(() => {
        let surface = 0
        sites.forEach((site) => {
            if (site.Surface) surface += parseInt(site.Surface)
        })
        setSurface(surface)
    }, [sites])

    return (
        <Card sx={{ height: '100%' }}>
            <CardContent style={{ position: 'relative' }}>
                <Grid container spacing={1}>
                    <Grid item md={7} xs={7}>
                        <Grid container direction="column" spacing={0.25}>
                            <Grid item sx={{ ml: -1 }}>
                                <img
                                    src={Logo}
                                    alt="agrid_logo"
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '80%',
                                        maxHeight: 60,
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    marginTop: 5,
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        width: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <AspectRatioIcon style={{ color: normalGrey }} />
                                </div>
                                <Typography
                                    component="div"
                                    style={{
                                        fontSize: 14,
                                        color: normalGrey,
                                        marginLeft: 2,
                                    }}
                                >
                                    {surface ? formatNumber(surface) : '-'} m²
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    marginTop: 5,
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        width: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <HomeWorkIcon style={{ color: normalGrey }} />
                                </div>

                                <Typography
                                    component="div"
                                    style={{
                                        fontSize: 14,
                                        color: linkBlue,
                                        marginLeft: 2,
                                        cursor: 'pointer',
                                    }}
                                    onClick={scroll}
                                >
                                    {t('building', { count: sites.length })}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={5} xs={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <DoughnutGroup sites={sites} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
