import React from 'react'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import { Item } from './item.component'
import { useTranslation } from 'react-i18next'

/**
 * @param {number | undefined} average
 */
export const GlobalGroupComponent = ({ average }) => {
    const nationalReco = 250
    const { t } = useTranslation('connect', { keyPrefix: 'groupDashboard' })
    return (
        <Item
            number={average?.toFixed(0) || '-'}
            unit={'kWh/m2/an'}
            icon={<QueryStatsIcon style={{ color: '#f06292' }} />}
            chip={average ? t('versusAverage') : t('missingData')}
            chipColor={average < nationalReco ? 'green' : 'red'}
            chipValue={average ? `${parseInt(((average - nationalReco) / nationalReco) * 100)}%` : '-'}
        />
    )
}
