import React from 'react'

/** ICONS */
import { Alert } from '@mui/material'
import useLocale from '../../utils/locale/locale.hook'
const NoDataComponent = (props) => {
    const locale = useLocale()
    return (
        <Alert severity="warning" style={{ fontSize: 12 }}>
            {locale?.['noData']}
        </Alert>
    )
}

export default NoDataComponent
