/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, post } from './utils.service'

export const getDataForObject = async (body: any) => {
    const res = await post(`/data/site`, { ...body }, { SiteHash: body.hash })
    return await res?.json()
}

export const getIndicators = async (siteHash: string, body: any) => {
    const res = await post(`/data/indicators/${siteHash}`, { ...body }, { SiteHash: siteHash })
    return await res?.json()
}

export const getDashboardV2 = async (siteHash: string, body: any) => {
    const res = await get(`/data/dashboard-v2/${siteHash}?start=${body.start}&end=${body.end}`, { SiteHash: siteHash })
    return await res?.json()
}

export const getCompanyDashboardData = async (companyHash: string) => {
    const res = await get(`/data/dashboard-company/${companyHash}`, {})
    return await res?.json()
}

type ConsumptionDataUnit = 'kWh' | 'kW'

interface DataPoint {
    value: number
    min?: number
    max?: number
    timestamp: string
}

interface Period {
    from: string
    to: string
}
interface MeterData {
    meterId: number
    pointId: string
    provider: string
    pmaxKw?: number
    providerMeterType?: string
    siteId: number
    createdAt: string
    updatedAt: string
    isError: boolean
    timeZone: string
}
export interface SiteConsumptionData<TUnit extends ConsumptionDataUnit> extends Period {
    step: string
    unit: TUnit
    meters: (MeterData & {
        points: DataPoint[]
    })[]
}

export const getEnergyConsumptions = async (siteId: number, from: string, to: string) => {
    const res = await get(`/data/energy-consumption/${siteId}?from=${from}&to=${to}&step=daily`, {})
    const data = await res?.json()
    return data as SiteConsumptionData<'kWh'>
}

export const getPowerCurves = async (siteId: number, from: string, to: string) => {
    const res = await get(`/data/power-curve/${siteId}?from=${from}&to=${to}`, {})
    const data = await res?.json()
    return data as SiteConsumptionData<'kW'>
}

interface OverrunData extends Period {
    meters: (MeterData & { overrunMinutes: number })[]
}

export const getOverrunData = async (siteId: number, from: string, to: string) => {
    const res = await get(`/data/power-overrun/${siteId}?from=${from}&to=${to}`, {})
    const data = await res?.json()
    return data as OverrunData
}

type DpeClass = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G'
export const dpeTypes = ['consumption', 'greenhouse'] as const
type DpeType = (typeof dpeTypes)[number]

interface AnnualConsumptionData {
    kwhFinal: number
    kwhPrimary: number
    kgCo2eq: number
    isPartial: boolean
    missingPercent: number
}
interface DpeResponse extends Period {
    surface: number
    buildingType: string
    buildingTypeDescription: string
    primaryKwhPerSquareMeter: number
    co2eqKgPerSquareMeter: number
    classes: Record<DpeType, DpeClass>
    dpe: Record<
        DpeType,
        {
            value: number
            class: DpeClass
            unit: string
            ranges: Record<DpeClass, number>
        }
    >

    meters: (MeterData & AnnualConsumptionData)[]
}

export const getDpe = async (siteId: number) => {
    const res = await get(`/data/dpe/${siteId}`, {})
    return (await res?.json()) as DpeResponse
}
