import React, { useEffect } from 'react'
import { Card, CardContent } from '@mui/material'
import HighchartsChart from '@/components/Charts/HighchartsChart'
import moment from 'moment'

const hexToRgb = (hex, opacity) => {
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16)

    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')'
}

const formatSeriesData = (series) => {

    return series?.map((serie, index) => ({
        ...serie,
        data: serie?.data?.map((item) => {
            const month = moment(item.date).month() // Get month index (0-11)
            const isFuture = moment(item.date).isAfter(moment())
            return {
                x: month,
                y: item.value,
                color: hexToRgb(serie.color, isFuture ? 0.4 : 1), // Different colors with varying opacity
                id: item.id, // Keep the id for the click event
            }
        }),
    }))
}

export const LineComponent = ({ series, setSelectedZone, title }) => {
    const formattedSeries = formatSeriesData(series)
    const options = {
        chart: {
            type: 'column',
        },
        legend: { enabled: true },
        title: {
            text: '',
            enabled: false,
        },
        yAxis: [
            {
                title: {
                    text: '',
                },
            },
        ],
        xAxis: [
            {
                title: {
                    text: '',
                },
                categories: moment.monthsShort(), // Display months as categories
            },
        ],
        series: formattedSeries,
        plotOptions: {
            column: {
                stacking: null,
                groupPadding: 0.15,
                pointPadding: 0.15,
                borderWidth: 0,
                borderRadius: 4,
            },
            series: {
                animation: false,
                point: {
                    events: {
                        click: function () {
                            setSelectedZone(this.id)
                        },
                    },
                },
            },
            chart: {
                margin: 0,
            },
            states: {
                hover: {
                    enabled: false,
                },
            },
        },
    }

    return <HighchartsChart options={options} />
}

export default LineComponent
