// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

export const computeKpis = ({ sites, year }) => {
    let kpis = [
        {
            type: 'energy',
            value: 0,
            last_year_value: 0,
            series: [],
            last_year_series: [],
        },
        {
            type: 'water',
            value: 0,
            last_year_value: 0,
            series: [],
            last_year_series: [],
        },
        {
            subType: 'GAS',
            value: 0,
            last_year_value: 0,
            series: [],
            last_year_series: [],
        },
        {
            subType: 'ELECTRICITY',
            value: 0,
            last_year_value: 0,
            series: [],
            last_year_series: [],
        },
        {
            subType: 'HEAT',
            value: 0,
            last_year_value: 0,
            series: [],
            last_year_series: [],
        },
        {
            subType: 'co2',
            value: 0,
            last_year_value: 0,
            series: [],
            last_year_series: [],
        },
    ]
    let total_sq = sites?.map((el) => el?.surface)?.reduce((acc, curr) => acc + curr, 0)
    let total_night = 0

    sites?.forEach((site) => {
        kpis?.forEach((kpi) => {
            let key = kpi?.type ? 'type' : 'subType'

            let seriesList = site?.series?.filter((s) => s?.[key] === kpi?.[key])
            
            seriesList?.forEach((seriesObj) => {
                let series = seriesObj?.data?.filter((d) => d?.date?.includes(year))
                if (series?.length) {
                    kpi.value += series?.reduce((acc, curr) => acc + curr?.value, 0)
                    if (kpi?.series?.length === 0) {
                        kpi.series = series
                    } else {
                        kpi.series = kpi.series.map((s, i) => {
                            return {
                                ...s,
                                value: s.value + series[i].value,
                            }
                        })
                    }
                }

                let last_year_series = seriesObj?.data?.filter((d) => d?.date?.includes(year - 1))
                if (last_year_series?.length) {
                    kpi.last_year_value += last_year_series?.reduce((acc, curr) => acc + curr?.value, 0)
                    if (kpi?.last_year_series?.length === 0) {
                        kpi.last_year_series = last_year_series
                    } else {
                        kpi.last_year_series = kpi.last_year_series.map((s, i) => {
                            return {
                                ...s,
                                value: s.value + last_year_series[i].value,
                            }
                        })
                    }
                }
            })
        })

        let night_series = site?.series
            ?.find((s) => s.subType === 'GUEST_NIGHTS')
            ?.data?.filter((d) => d?.date?.includes(year))
        total_night += night_series?.reduce((acc, curr) => acc + curr?.value, 0)
    })

    kpis = kpis?.map((kpi) => {
        return {
            ...kpi,
            value: kpi.value / total_sq,
            total_sq: total_sq,
            valuePerNight: kpi.value / total_night,
            total_night: total_night,
            percentage: ((kpi.value - kpi.last_year_value) / kpi.last_year_value) * 100,
            series: kpi.series?.map((s) => {
                return {
                    ...s,
                    value: s.value / total_sq,
                }
            }),
            last_year_series: kpi.last_year_series?.map((s) => {
                return {
                    ...s,
                    value: s.value / total_sq,
                }
            }),
        }
    })

    return kpis
}
