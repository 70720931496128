import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Typography } from '@mui/material'
import GoogleMapReact from 'google-map-react'
import React, { useEffect, useRef, useState } from 'react'
import DefaultPic from '../../../assets/img/site.png'
import { deepBlue, electricGreen, orangeButton } from '../../../components/Basic/colors'

const PinComponent = ({ text, onClick }) => (
    <div
        style={{
            cursor: 'pointer',
            // position: "relative",
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
        }}
        onClick={onClick}
    >
        <LocationOnIcon style={{ color: 'red', fontSize: 30 }} />
    </div>
)

const Details = ({ name, address, onSelect, hash, pilot, dpe }) => {
    return (
        <div
            style={{
                position: 'relative',
                left: 0,
                top: 0,
                padding: 4,
                background: 'white',
                width: 200,
            }}
        >
            {/* <ClearIcon
        style={{ top: 2, right: 2, position: "absolute", cursor: "pointer" }}
        onClick={exit}
      /> */}
            <div
                style={{
                    top: 8,
                    right: 8,
                    position: 'absolute',
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    background: pilot ? electricGreen : orangeButton,
                }}
            />
            <img src={DefaultPic} style={{ width: '100%', objectFit: 'cover', maxHeight: 100 }} />
            {/* {SimpleDPE({ value: dpe })} */}
            <Typography sx={{ fontSize: 14 }}>{name}</Typography>
            <Typography sx={{ fontSize: 12 }} color="text.secondary">
                {address}
            </Typography>

            <div
                style={{
                    color: deepBlue,
                    cursor: 'pointer',
                    fontSize: 12,
                    marginTop: 5,
                }}
                onClick={() => onSelect(hash)}
            >
                Voir plus
            </div>
        </div>
    )
}

export const GroupMapComponent = ({ sites, onSelect, style, disableClick, fullscreenControl }) => {
    useEffect(() => {
        console.log("GroupMapComponent init", sites)
    }, [])
    const map = useRef(null)
    const [center, setCenter] = useState({
        lat: sites?.[0]?.Latitude || 48,
        lng: sites?.[0]?.Longitude || 2,
    })
    const [defaultProps, setDefaultProps] = useState({
        center: {
            lat: sites?.[0]?.Latitude || 48,
            lng: sites?.[0]?.Longitude || 2,
        },
        zoom: 13,
    })

    const [selected, setSelected] = useState(null)

    const getMapBounds = (map, maps, places) => {
        const bounds = new maps.LatLngBounds()

        places.forEach((place) => {
            bounds.extend(new maps.LatLng(place.lat, place.lng))
        })
        return bounds
    }

    const onClick = (e) => {
        setSelected(null)
    }

    useEffect(() => {
        if (sites?.[0]?.Latitude && sites?.[0]?.Longitude) {
            setCenter({
                lat: sites?.[0]?.Latitude,
                lng: sites?.[0]?.Longitude,
            })
        }
       
    }, [sites])

    return (
        // Important! Always set the container height explicitly
        <div style={{ height: '50vh', width: '100%', position: 'relative', ...style }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: import.meta.env.VITE_GOOGLE_API_KEY }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                center={center}
                // zoom={10}
                ref={map}
                onGoogleApiLoaded={({ map, maps }) => {
                    if (!disableClick && sites?.length > 1) {
                        const bounds = getMapBounds(
                            map,
                            maps,
                            sites?.map((el) => {
                                return {
                                    lat: el.Latitude,
                                    lng: el.Longitude,
                                }
                            })
                        )
                        map.fitBounds(bounds)
                    }
                }}
                options={{
                    fullscreenControl: fullscreenControl !== undefined ? fullscreenControl : true,
                }}
                onClick={onClick}
                // key={`${sites?.[0]?.Latitude}-${sites?.[0]?.Longitude}`}
            >
                {sites
                    ?.filter((site) => site?.Latitude && site?.Longitude)
                    ?.map((el) => {
                        return (
                            <PinComponent
                                lat={el?.Latitude}
                                lng={el?.Longitude}
                                key={el?.SiteId}
                                onClick={() => {
                                    if (!disableClick) setSelected(el)
                                }}
                            />
                        )
                    })}
                {selected ? (
                    <Details
                        name={selected.Name}
                        exit={() => setSelected(null)}
                        address={selected.Address}
                        onSelect={onSelect}
                        hash={selected?.Hash}
                        lat={selected.Latitude}
                        lng={selected.Longitude}
                        pilot={selected?.Pilot}
                        dpe={selected?.dpe}
                    />
                ) : null}
            </GoogleMapReact>
        </div>
    )
}
