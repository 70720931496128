import ReactGA from 'react-ga'
import { store } from '../store/configure.store'

export const initialize = () => {
    ReactGA.initialize('UA-205597613-1')
}

export const event = (category, action) => {
    let me = store?.getState()?.meStore?.me
    ReactGA.event({
        category,
        action,
        label: me?.UserId || 'unknown-user',
    })
}

export const pageview = (path) => {
    ReactGA.pageview(path)
}

export const gaSet = (userId) => {
    ReactGA.set({ userId })
}
