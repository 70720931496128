import { Box, Card, CardContent, Typography } from '@mui/material'
import React, { useEffect } from 'react'

export const Item = ({ number, icon, unit, chip, chipValue, chipColor, style, comingSoon }) => {
    const [displayNumber, setDisplayNumber] = React.useState(number)
    useEffect(() => {
        setDisplayNumber(number)
    }, [number])
    return (
        <Card style={{ height: '100%', position: 'relative', ...style }}>
            <CardContent sx={{ p: 1 }}>
                <Typography component="div" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {icon || null}
                </Typography>
                <Typography component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }} component="div">
                        {displayNumber}
                    </Typography>
                    <Typography color="text.secondary">{unit}</Typography>
                </Typography>
                {chip ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', px: 1, mt: 2 }}>
                        {chipValue !== undefined && (
                            <div
                                style={{
                                    color: chipColor == 'green' ? 'rgb(76, 175, 80)' : 'rgb(244, 67, 54)',
                                    fontWeight: 600,
                                    background:
                                        chipColor == 'green' ? 'rgba(76, 175, 80, 0.1)' : 'rgba(244, 67, 54, 0.1)',
                                    padding: '2px',
                                    borderRadius: '3px',
                                    marginRight: '8px',
                                    fontSize: 14,
                                }}
                            >
                                {chipValue}
                            </div>
                        )}
                        <Typography variant="body2" color="text.secondary" gutterBottom component="div">
                            {chip}
                        </Typography>
                    </Box>
                ) : null}
            </CardContent>
            {comingSoon && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,0.4)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                    }}
                >
                    <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} gutterBottom>
                        Bientot disponible
                    </Typography>
                </div>
            )}
        </Card>
    )
}
