// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck dt'
import { Box, Card, CardContent, Grid } from '@mui/material'
import { styled } from '@mui/system'
import { colors } from './variables'
import { PerimeterMarker } from './table'
import { CustomTooltip } from './kpis.component'

const IconContainer = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
})

const ColoredIcon = styled('div')(({ color, width }) => ({
    fontSize: '14px',
    padding: 2,
    width: width,
    height: 36,
    color: color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

const DonutChart = ({ percentage }) => {
    const radius = 36 // adjusted radius to fit within the viewBox
    const circumference = 2 * Math.PI * radius
    const fillPercentage = (percentage / 100) * circumference

    return (
        <svg width="80" height="80" viewBox="0 0 80 80">
            <circle cx="40" cy="40" r={radius} stroke="lightgrey" strokeWidth="8" fill="none" />
            <circle
                cx="40"
                cy="40"
                r={radius}
                stroke="#80cbc4"
                strokeWidth="8"
                fill="none"
                strokeDasharray={`${fillPercentage} ${circumference}`}
                transform="rotate(-90 40 40)"
            />
            <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize="16px"
                fontWeight="bold"
                fill={colors?.darken}
            >
                {percentage}%
            </text>
        </svg>
    )
}
export const IconPercentage = ({ perimeters, sitesPerPerimeterFiltered, icon, title, metric, referenceStudies }) => {
    return (
        <Card>
            <CardContent>
                <div
                    style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: colors?.neutral,
                        letterSpacing: 2,
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 20,
                    }}
                >
                    {title}
                    <CustomTooltip
                        referenceStudies={referenceStudies}
                        type={metric}
                        unit={'%'}
                        metrics="parsedSocialMetrics"
                    />
                </div>
                {sitesPerPerimeterFiltered?.length === 1 ? (
                    <MonoKpi
                        percentage={
                            sitesPerPerimeterFiltered?.length > 0
                                ? (
                                      parseInt(
                                          sitesPerPerimeterFiltered?.[0]
                                              ?.map((el) => {
                                                  return (
                                                      el?.metrics
                                                          ?.find((_metric) => _metric?.key == metric)
                                                          ?.data?.find(
                                                              (data) => data?.year == perimeters?.[0]?.perimeter?.year
                                                          )?.value || 0
                                                  )
                                              })
                                              ?.reduce((a, b) => a + b, 0)
                                      ) / sitesPerPerimeterFiltered?.length
                                  ).toFixed(1)
                                : 0
                        }
                        icon={icon}
                    />
                ) : (
                    <MultipleKip
                        perimeters={perimeters}
                        sitesPerPerimeterFiltered={sitesPerPerimeterFiltered}
                        icon={icon}
                        title={title}
                        metric={metric}
                    />
                )}
            </CardContent>
        </Card>
    )
}

const MultipleKip = ({ perimeters, sitesPerPerimeterFiltered, icon, title, metric }) => {
    // one row per perimeter with the icons and percentage at the end but smaller
    // first item uses the perimeter.perimeter.color as the background color
    const totalIcons = 8

    const _sitesPerPerimeterFiltered = sitesPerPerimeterFiltered?.map((sites, i) => {
        return sites
            ?.map((el) => {
                return (
                    el?.metrics
                        ?.find((_metric) => _metric?.key == metric)
                        ?.data?.find((data) => data?.year == perimeters?.[i]?.perimeter?.year)?.value || 0
                )
            })
            ?.reduce((a, b) => a + b, 0)
    })
    return (
        <>
            {perimeters?.map((perimeter, index) => {
                const percentage = _sitesPerPerimeterFiltered?.[index]
                const filledIconsCount = Math.round((percentage / 100) * totalIcons)
                return (
                    <Grid container key={index} style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item md={1} style={{ padding: 5 }}>
                            <PerimeterMarker color={perimeter?.perimeter?.color} label={index + 1} />
                        </Grid>
                        <Grid item md={9} style={{ display: 'flex' }}>
                            {[...Array(totalIcons)].map((_, i) => (
                                <ColoredIcon key={i} color={i < filledIconsCount ? '#80cbc4' : 'grey'} width={40}>
                                    {icon}
                                </ColoredIcon>
                            ))}
                        </Grid>
                        <Grid item md={2} style={{ maxWidth: 60, textAlign: 'right', fontWeight: 'bold' }}>
                            {/* {index === 0 && percentage >= _sitesPerPerimeterFiltered?.[1] ? (
                                <Trend
                                    percentage={Math.ceil(
                                        ((percentage - _sitesPerPerimeterFiltered?.[1]) * 100) /
                                            _sitesPerPerimeterFiltered?.[1]
                                    ).toFixed(0)}
                                    reverse={true}
                                />
                            ) : null}
                            {index === 1 && percentage > _sitesPerPerimeterFiltered?.[0] ? (
                                <Trend
                                    percentage={Math.ceil(
                                        ((percentage - _sitesPerPerimeterFiltered?.[0]) * 100) /
                                            _sitesPerPerimeterFiltered?.[0]
                                    ).toFixed(0)}
                                    reverse={true}
                                />
                            ) : null} */}
                            {parseInt(percentage)}%
                        </Grid>
                    </Grid>
                )
            })}
        </>
    )
}

const MonoKpi = ({ percentage, icon }) => {
    const totalIcons = 14
    const rowCount = 2
    const filledIconsCount = Math.round((percentage / 100) * totalIcons)
    return (
        <Grid container>
            <Grid item md={8}>
                <IconContainer>
                    {[...Array(totalIcons)].map((_, index) => (
                        <ColoredIcon
                            key={index}
                            color={index < filledIconsCount ? '#80cbc4' : 'grey'}
                            width={`${parseInt(100 / (totalIcons / rowCount))}%`}
                        >
                            {icon}
                        </ColoredIcon>
                    ))}
                </IconContainer>
            </Grid>
            <Grid
                item
                md={4}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <DonutChart percentage={percentage} />
            </Grid>
        </Grid>
    )
}
