import FallbackTemplate from './template.component'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const Forbidden = () => (
    <FallbackTemplate
        title="Oups, il y un souci"
        description="Une erreur s'est produite sur cette page. Les administrateurs ont été informés et vont faire le nécessaire au plus vite. Veuillez réessayer plus tard."
        icon={<ErrorOutlineIcon fontSize="inherit" color="error" />}
    />
)

export default Forbidden
