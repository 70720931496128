import React from 'react'
import { Select, MenuItem } from '@mui/material'
import { useSelector } from 'react-redux'

const NavSelect = (props) => {
    const width = useSelector((state) => state.globalStore.width)
    return (
        <Select
            style={{
                background: 'white',
                color: props?.disabled ? '#e0e0e0' : '#8898aa',
                fontSize: '.875rem',
                maxWidth: width < 700 ? 120 : 1000,
                height: 40,
            }}
            labelId={`${props.label}-simple-select-label`}
            id={`${props.label}-simple-select`}
            //label={props.label}
            onChange={(e) => {
                props.onChange(e)
            }}
            value={props.value}
            disabled={props?.disabled}
        >
            {props.items?.map((el, index) => {
                return (
                    <MenuItem value={el.value} key={el.label + index}>
                        <span
                            style={{
                                fontWeight: index === 0 && props?.label === 'Objects' ? 'bold' : '',
                            }}
                        >
                            {el.label}
                        </span>
                    </MenuItem>
                )
            })}
        </Select>
    )
}

export default NavSelect
