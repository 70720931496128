import { VFC, useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { PerimeterFiltersMethods, categoryFilterKeyTranslations } from '../types'
import PerimeterFilters from './filters/perimeter.component'
import EditFilterDialog from './filters/editFilterModal.component'
import ButtonPicker from './buttonPicker'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'

// Import the files
// import PDF from '../../../assets/mocks/extendam.pdf'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
// import Excel from '../../../assets/mocks/extendam.xlsx'

interface GroupBySelectProps {
    value: string | null
    options: string[]
    setValue: (value: string | null) => void
}

interface ToolbarProps extends PerimeterFiltersMethods {
    counts: number[]
    filterOptions: Record<string, string[]>
    yearOptions: number[]
    groupingUtils: GroupBySelectProps
    referencePickerUtils: {
        selectedStudyName: string | null
        setSelectedStudyName: (value: string | null) => void
        options: string[]
    }
    exportAsCsv: () => void
}

const downloadFile = (filePath: string) => {
    const link = document.createElement('a')
    link.href = filePath
    link.download = filePath.split('/').pop() || ''
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const Toolbar: VFC<ToolbarProps> = ({
    perimeters,
    counts,
    filterOptions,
    yearOptions,
    groupingUtils,
    referencePickerUtils,
    exportAsCsv = () => alert('Exporting...'),
    ...methods
}) => {
    const [modalOpen, setModalOpen] = useState(false)
    const multiplePerimeters = perimeters.length > 1
    const hasReference = Boolean(referencePickerUtils.selectedStudyName)

    return (
        <Box>
            <Grid
                container
                direction={'row-reverse'}
                justifyContent="space-between"
                sx={{ mb: multiplePerimeters ? 2 : 0 }}
                alignItems="center"
            >
                <Grid item xs={12} sm={8} lg={8}>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'flex-end' }}>
                        <ButtonPicker
                            options={['-', ...referencePickerUtils.options]}
                            value={referencePickerUtils.selectedStudyName || ''}
                            setValue={(value: string) => {
                                referencePickerUtils.setSelectedStudyName(value !== '-' ? value : null || null)
                            }}
                            startIcon={hasReference ? <BookmarkIcon /> : <BookmarkAddIcon />}
                            sx={{ color: hasReference ? 'primary.light' : undefined }}
                            badge={false}
                        >
                            <Box
                                component="span"
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                    fontWeight: 600,
                                }}
                            >
                                {hasReference ? referencePickerUtils.selectedStudyName : 'Référence'}
                            </Box>
                        </ButtonPicker>
                        <ButtonPicker
                            variant="outlined"
                            options={['-', ...groupingUtils.options]}
                            value={groupingUtils.value || ''}
                            setValue={(value: string) =>
                                groupingUtils.setValue(groupingUtils.options.includes(value) ? value : null)
                            }
                            valueTranslations={categoryFilterKeyTranslations}
                        >
                            {groupingUtils.value ? (
                                <Box component="span">
                                    Grouper par{' '}
                                    <Box component="span" sx={{ fontWeight: 600, color: 'primary.light' }}>
                                        {categoryFilterKeyTranslations[groupingUtils.value]}
                                    </Box>
                                </Box>
                            ) : (
                                'Grouper par'
                            )}
                        </ButtonPicker>
                        <Button startIcon={<FilterListIcon />} variant="outlined" onClick={() => setModalOpen(true)}>
                            Filtrer
                        </Button>
                        <ButtonPicker
                            variant="contained"
                            value={''}
                            setValue={(value: string) =>
                                value === 'format csv'
                                    ? exportAsCsv()
                                    :  exportAsCsv()//downloadFile(value === 'format pdf' ? Excel : Excel)
                            }
                            options={['format pdf', 'format csv', 'format xlsx']}
                        >
                            Télécharger le rapport
                        </ButtonPicker>
                    </Box>
                </Grid>
                {!multiplePerimeters && (
                    <Grid item xs={12} sm={4} lg={4}>
                        <PerimeterFilters
                            title={'Périmètre'}
                            count={counts[0]}
                            yearOptions={yearOptions}
                            {...perimeters[0]}
                        />
                    </Grid>
                )}
            </Grid>
            {multiplePerimeters && (
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                    {perimeters.map((perimeter, index) => (
                        <PerimeterFilters
                            key={index}
                            title={`Périmètre ${index + 1}`}
                            count={counts[index]}
                            yearOptions={yearOptions}
                            {...perimeter}
                            onDelete={index > 0 ? () => methods.removePerimeter(index) : undefined}
                        />
                    ))}
                </Box>
            )}
            <EditFilterDialog
                filterOptions={filterOptions}
                perimeters={perimeters}
                yearOptions={yearOptions}
                {...methods}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            />
        </Box>
    )
}

export default Toolbar
