import { VFC, useState } from 'react'
import { Button, Badge, ButtonProps, Menu, MenuItem } from '@mui/material'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'

interface ButtonPickerProps extends ButtonProps {
    options: string[]
    value: string
    setValue: (value: string) => void
    valueTranslations?: Record<string, string>
    badge?: boolean
}

const ButtonPicker: VFC<ButtonPickerProps> = ({
    options,
    value,
    setValue,
    valueTranslations,
    badge = true,
    children,
    ...props
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const translate = (v: string): string => (valueTranslations ? valueTranslations[v] || v : v)
    return (
        <>
            <Badge variant="dot" color="primary" invisible={!value || !badge}>
                <Button {...props} onClick={(e) => setAnchorEl(e.currentTarget)} endIcon={<ExpandMoreOutlinedIcon />}>
                    {children || translate(value)}
                </Button>
            </Badge>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        onClick={() => {
                            setValue(option)
                            setAnchorEl(null)
                        }}
                    >
                        {translate(option)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default ButtonPicker
