import { useState } from 'react'
import { groupAndAggregateSites } from '../utils/aggregateSites'
import { CATEGORY_FILTERS, CategoryFilterKey, PerimeterExtendedSiteData } from '../types'
import identity from 'lodash/identity'

export const useGroupSites = () => {
    const [groupBy, setGroupBy] = useState<CategoryFilterKey | null>(null)
    return {
        groupBy,
        groupByOptions: CATEGORY_FILTERS,
        setGroupBy,
        aggregate: groupBy ? (sites: PerimeterExtendedSiteData[]) => groupAndAggregateSites(sites, groupBy) : identity,
        aggregateAll: (sites: PerimeterExtendedSiteData[]) => groupAndAggregateSites(sites),
    }
}
