import { post, get } from './utils.service'

export const getTypes = async () => {
    const res = await get(`/site/types`)
    return await res?.json()
}

export const getSites = async () => {
    const res = await get(`/site/sites`)
    return await res?.json()
}

export const updateSite = async (body, hash) => {
    const res = await post(`/site/${hash}`, body, { SiteHash: hash })
    return await res?.json()
}

export const createSite = async (body) => {
    const res = await post(`/site/create`, body)
    return await res?.json()
}

export const getDpe = async (hash, body) => {
    const res = await post(`/site/${hash}/dpe`, { ...body }, { SiteHash: hash })
    return await res?.json()
}

export const generateZip = async (hash) => {
    const res = await get(`/site/zip/${hash}`, { SiteHash: hash })
    return await res?.json()
}

export const togglePilot = async (hash) => {
    const res = await get(`/site/togglePilot/${hash}`, { SiteHash: hash })
    return await res?.json()
}

export const getSiteDetails = async (hash, key) => {
    const res = await get(`/site/details/${hash}/${key}`, { SiteHash: hash })
    return await res?.json()
}

export const getSiteSettings = async (hash) => {
    const res = await get(`/site/settings/${hash}`, { SiteHash: hash })
    return await res?.json()
}

export const getSiteReports = async (hash) => {
    const res = await get(`/site/reports/${hash}`, { SiteHash: hash })
    return await res?.json()
}

export const saveSiteName = async (hash, siteName) => {
    const res = await post(`/site/saveSiteName/${hash}`, { siteName }, { SiteHash: hash })
    return await res?.json()
}

export const saveSiteNameAlt = async (hash, body) => {
    const res = await post(`/site/saveSiteName/${hash}`, { ...body }, { SiteHash: hash })
    return await res?.json()
}

export const pingBox = async (hash) => {
    const res = await get(`/site/ping/${hash}`, { SiteHash: hash })
    return await res?.json()
}

export const triggerNetworkScan = async (hash) => {
    const res = await get(`/site/triggerNetworkScan/${hash}`, { SiteHash: hash })
    return await res?.json()
}

export const getNetworkscanResults = async (hash) => {
    const res = await get(`/site/getNetworkscanResults/${hash}`, {
        SiteHash: hash,
    })
    return await res?.json()
}

export const saveConfiguration = async (hash, body) => {
    const res = await post(`/site/saveConfiguration/${hash}`, { ...body }, { SiteHash: hash })
    return await res?.json()
}

export const initSite = async (hash, body) => {
    const res = await post(`/site/init/${hash}`, { ...body }, { SiteHash: hash })
    return await res?.json()
}

export const getStatus = async (hash, body) => {
    const res = await post(
        `/site/status/${hash}`,
        { ...body },
        {
            SiteHash: hash,
        }
    )
    return await res?.json()
}

export const getConfiguration = async (hash) => {
    const res = await get(`/site/configuration/${hash}`, {
        SiteHash: hash,
    })
    return await res?.json()
}

export const fetchBuildings = async (hash) => {
    const res = await get(`/site/buildings/${hash}`, {
        SiteHash: hash,
    })
    return await res?.json()
}

export const setupBuildings = async (hash, body) => {
    const res = await post(
        `/site/buildings/${hash}`,
        { ...body },
        {
            SiteHash: hash,
        }
    )
    return await res?.json()
}

export const updateDocuments = async (hash, body) => {
    const res = await post(
        `/sites/documents/${hash}`,
        { ...body },
        {
            SiteHash: hash,
        }
    )
    return await res?.json()
}

export const getSiteNotes = async (hash) => {
    const res = await get(`/site/notes/${hash}`, {
        SiteHash: hash,
    })
    return await res?.json()
}

export const postNote = async (hash, body) => {
    const res = await post(
        `/site/notes/${hash}`,
        { ...body },
        {
            SiteHash: hash,
        }
    )
    return await res?.json()
}

export const getReportingData = async (hash, start, end) => {
    const res = await get(`/sites/reporting/${hash}?start=${start}&end=${end}`, {
        SiteHash: hash,
    })
    return await res?.json()
}
