import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import Menu from '@mui/material/Menu'
import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import CalendarComponent from '../Sidebar/Calendar'

export const NavCalendarComponent = () => {
    const { width } = useSelector((state) => state.globalStore)
    const calendarStore = useSelector((state) => state.calendarStore)

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <div
                style={{
                    padding: '10px',
                    background: 'white',
                    color: 'black',
                    maxWidth: 350,
                    boxSizing: 'border-box',
                    borderRadius: 5,
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                }}
                onClick={handleClick}
            >
                <div style={{ display: 'flex' }}>
                    {width > 600
                        ? `${moment(calendarStore?.start).format('DD/MM/YYYY')} - ${moment(calendarStore?.end).format(
                              'DD/MM/YYYY'
                          )}`
                        : `${moment(calendarStore?.start).format('DD/MM')} - ${moment(calendarStore?.end).format(
                              'DD/MM'
                          )}`}
                    {width > 600 && <CalendarMonthIcon style={{ marginLeft: 10 }} />}
                </div>
                {/* <DateRangePicker
        // onChange={onChange}
        value={[new Date(start), new Date(end)]}
        format="dd/MM/yyyy"
        maxDetail="month"
        clearIcon={null}
        className="nav-calendar-picker"
        formatShortWeekday={(locale, value) =>
          ["D", "L", "M", "M", "J", "V", "S"][value.getDay()]
        }
      /> */}
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{ maxWidth: 280, padding: 10, boxSizing: 'border-box' }}>
                    <CalendarComponent onChange={() => handleClose()} />
                </div>
            </Menu>
        </>
    )
}
