import { createSlice } from '@reduxjs/toolkit'

const SiteSlice = createSlice({
    name: 'dashboard',
    initialState: {
        mode: 'Energy',
        building: {},
        site: {},
    },
    reducers: {
        setBuilding: (state, { payload }) => {
            return { ...state, building: payload.building }
        },
        setSite: (state, { payload }) => {
            return payload
        },
        updateSite: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            }
        },
    },
})

export default SiteSlice
