import React, { useEffect, useState } from 'react'
import { Tooltip } from '@mui/material'

const Block = ({ index }) => {
    const colors = {
        0: '#E2E4E8',
        1: '#CCFAE7',
        2: '#99F5CE',
        3: '#66F0B6',
        4: '#33EB9E',
        5: '#00E786',
        6: '#00B86B',
    }
    return (
        <svg width="13" height="33" viewBox="0 0 13 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="13" height="33" rx="4" fill={colors[index]} />
        </svg>
    )
}

const Spacing = () => <div style={{ width: 5 }} />

const Item = ({ length, selected, index, onClick, name }) => {
    if (length < index) return null
    return (
        <Tooltip title={name} key={name}>
            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => onClick(index - 1)} key={name}>
                <Block index={selected == index ? index : 0} />
                {/* <Block index={selected >= index ? index : 0} /> */}
                <Spacing />
            </div>
        </Tooltip>
    )
}

const FanComponent = ({ length, selected, onClick, options, fanSpeed }) => {
    const [filteredOptions, setFilteredOptions] = useState(options)
    useEffect(() => {
        let newOptions = options?.filter((el) => fanSpeed?.List?.indexOf(el?.NameLocal) > -1)
        setFilteredOptions(newOptions.sort((a, b) => a.IdGlobal - b.IdGlobal))
    }, [])
    return (
        <div style={{ display: 'flex' }}>
            {filteredOptions
                ?.map((el) => {
                    return el?.IdGlobal
                })
                ?.map((el, index) => {
                    return (
                        <Item
                            length={length}
                            selected={selected}
                            index={el}
                            onClick={onClick}
                            key={el}
                            name={filteredOptions?.[index]?.Name}
                        />
                    )
                })}
        </div>
    )
}

export default FanComponent
