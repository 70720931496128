export const deepBlue = '#4285f4'
export const backgroundBlue = 'rgb(12, 68, 111)'

/** electric */
export const electricBlue = '#2dcad5'
export const electricGreen = '#52d187'
export const electricOrange = '#ff8f00'
export const electricPurple = '#9b59b6'
export const electricPink = '#ff6b6b'

/** text */
export const veryLightGrey = '#f5f5f5'
export const lightGrey = '#e0e0e0'
export const mediumGrey = '#bdbdbd'
export const normalGrey = '#424242'
export const deepGrey = '#212121'

export const linkBlue = '#2196f3'
export const textBlue = '#0c446f'
export const textBlueLight = 'rgba(12,68,111, 0.4)'
export const yellow = '#c6ff00'

export const orangeButton = '#F7685B'

export const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16)

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')'
    }
}

export const redBackground = '#ff5252'
