import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { store } from '../../../store/configure.store'
import GlobalSlice from '../../../store/slices/global.slice'
import AlternativeRow from '../../realTime/components/alternative-row.component'
import ConstraintModal from './constraint.modal'
import { GroupComponent } from './groups.component'

export const Tab2Component = ({
    modal,
    setModal,
    saveObject,
    constraints,
    setModified,
    rawObjects,
    selectedObject,
    setObjectUpdates,
    changeSpaceType,
    setRawObjects,
    currentGroup,
    updateCurrentGroup,
    objectUpdates,
    groups,
    removeGroup,
    setGroupDialog,
    updateGroup,
    groupDialog,
    setCurrentGroup,
}) => {
    const [scrollY, setScrollY] = useState(0)

    useEffect(() => {
        if (!(modal && constraints) && scrollY > 0) {
            setTimeout(() => {
                // Use scrollTo with ScrollToOptions for smooth scrolling
                window.scrollTo({
                    top: scrollY, // Y-coordinate to scroll to
                    behavior: 'smooth', // Smooth scroll behavior
                })
            }, 100)
        }
    }, [modal])

    if (modal && constraints)
        return (
            <ConstraintModal
                modal={modal}
                setModal={setModal}
                save={saveObject}
                constraints={constraints}
                setModified={setModified}
            />
        )
    else if (rawObjects?.length > 0 && !selectedObject)
        return (
            <>
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid
                        item
                        md={9}
                        id={'list'}
                        //   style={{ height: `calc(100% - 500px)`, overflow: "scroll" }}
                    >
                        <AlternativeRow
                            mainEditable={true}
                            show={(row) => {
                                setScrollY(window.scrollY)
                                store.dispatch(GlobalSlice.actions.setSelectedObject(row))
                            }}
                            rawObjects={rawObjects?.filter((el) => el?.ObjectTypeId !== 3 && el?.Name)}
                            draggable={true}
                            updateObject={async (objectId, objectType) => {
                                let find = objectUpdates.find((el) => el.objectId === objectId)
                                if (!find) setObjectUpdates([...objectUpdates, { objectId, objectType }])
                                else {
                                    let newUpdates = objectUpdates.map((el) => {
                                        if (el.objectId === objectId) return { ...el, objectType }
                                        else return el
                                    })
                                    setObjectUpdates(newUpdates)
                                }
                                changeSpaceType(objectId, objectType)
                                let newObjects = rawObjects.map((el) => {
                                    if (el.ObjectId === objectId) return { ...el, SpaceType: objectType }
                                    else return el
                                })
                                setModified(true)
                                setRawObjects(newObjects)
                            }}
                            updateCurrentGroup={updateCurrentGroup}
                            currentGroup={currentGroup}
                        />
                    </Grid>

                    <Grid item md={3} style={{ position: 'relative' }}>
                        <GroupComponent
                            currentGroup={currentGroup}
                            groupDialog={groupDialog}
                            removeGroup={removeGroup}
                            groups={groups}
                            setCurrentGroup={setCurrentGroup}
                            setGroupDialog={setGroupDialog}
                            updateGroup={updateGroup}
                            rawObjects={rawObjects}
                            updateCurrentGroup={updateCurrentGroup}
                        />
                    </Grid>
                </Grid>
            </>
        )
    else return null
}
