import { post, get } from './utils.service'

export const postConstraints = async (body, hash) => {
    const res = await post(`/constraints/${hash}`, body, { SiteHash: hash })
    return await res?.json()
}

export const getConstraints = async (hash) => {
    const res = await get(`/constraints/${hash}`, { SiteHash: hash })
    return await res?.json()
}

export const postGroups = async (body, hash) => {
    const res = await post(`/constraints/groups/${hash}`, body, {
        SiteHash: hash,
    })
    return await res?.json()
}

export const toggleChallenge = async (body, hash) => {
    const res = await post(`/constraints/toggleChallenge/${hash}`, body, {
        SiteHash: hash,
    })
    return await res?.json()
}

export const getGroups = async (hash) => {
    const res = await get(`/constraints/groups/${hash}`, { SiteHash: hash })
    return await res?.json()
}

export const postCheckin = async (body, hash) => {
    const res = await post(`/constraints/checkin/${hash}`, body, {
        SiteHash: hash,
    })
    return await res?.json()
}
