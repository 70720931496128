import React, { FC } from 'react'
import { AppBar, Toolbar, IconButton, Box, useMediaQuery, Theme } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { DRAWER_WIDTH, PERMANENT_DRAWER_BREAKPOINT } from './constants'
import NavBreadcrumbs from './navBreadcrumbs/navBreadcrumbs'

interface HeaderProps {
    handleDrawerToggle: () => void
}

const Header: FC<HeaderProps> = ({ handleDrawerToggle }) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    return (
        <AppBar
            position="fixed"
            sx={{
                width: { [PERMANENT_DRAWER_BREAKPOINT]: `calc(100% - ${DRAWER_WIDTH}px)` },
                ml: { [PERMANENT_DRAWER_BREAKPOINT]: `${DRAWER_WIDTH}px` },
            }}
        >
            <Toolbar sx={{ px: 2, display: isMobile ? 'block' : 'flex', py: isMobile ? 1 : undefined }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { [PERMANENT_DRAWER_BREAKPOINT]: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        {!isMobile && <NavBreadcrumbs />}
                    </Box>
                    <div id="header-right-content-portal" />
                </Box>
                {isMobile && <NavBreadcrumbs />}
            </Toolbar>
        </AppBar>
    )
}

export default Header
