import React, { useEffect, useState } from 'react'
/** COMPONENTS */
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Form } from 'reactstrap'
import GoogleAutocomplete from '../../../components/Global/google.autocomplete.component'
import { ImageInput } from '../../../components/Global/image.input.component'
const SiteFormComponent = (props) => {
    const [types, setTypes] = useState([])
    useEffect(() => {
        setTypes(props.types)
    }, [props.types])
    return (
        <>
            <Form className="needs-validation" noValidate>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={2} sm={4} md={4}>
                        <ImageInput url={props?.site?.PictureUrl || ''} onChange={props?.setSitePicture} />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Raison sociale"
                                    value={props.site.BusinessName || ''}
                                    id="BusinessName"
                                    type="text"
                                    onChange={(e) => props.changeSite('BusinessName', e.target.value)}
                                    error={props.errors.indexOf(`BusinessName`) > -1}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <GoogleAutocomplete
                                    defaultInputValue={props.site.Address}
                                    onChange={props.changeAddress}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Code postal"
                                    value={props.site.ZipCode || ''}
                                    id="ZipCode"
                                    type="text"
                                    onChange={(e) => props.changeSite('ZipCode', e.target.value)}
                                    error={props.errors.indexOf(`ZipCode`) > -1}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Ville"
                                    value={props.site.City || ''}
                                    id="City"
                                    type="text"
                                    onChange={(e) => props.changeSite('City', e.target.value)}
                                    error={props.errors.indexOf(`City`) > -1}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={2} sm={2} md={2}>
                        <FormControl fullWidth>
                            <InputLabel style={{ background: 'white' }}>Type d'entreprise</InputLabel>
                            <Select
                                type="select"
                                onChange={(e) => {
                                    props.changeSite('LegalEntity', e.target.value)
                                }}
                                value={props.site.LegalEntity}
                            >
                                {['SAS', 'SARL', 'SA', 'SASU', 'EURL', 'SNC'].map((el) => (
                                    <MenuItem value={el}>{el}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                label="Surface"
                                value={props.site.Surface || ''}
                                type="number"
                                onChange={(e) => props.changeSite('Surface', e.target.value)}
                                error={props.errors.indexOf(`Surface`) > -1}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                        <TextField
                            fullWidth
                            label="Nom du site"
                            value={props.site.Name || ''}
                            type="text"
                            onChange={(e) => props.changeSite('Name', e.target.value)}
                            error={props.errors.indexOf(`Name`) > -1}
                        />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                        <FormControl fullWidth>
                            <InputLabel style={{ background: 'white' }}>Type de bâtiment</InputLabel>
                            <Select
                                onChange={(e) => {
                                    props.changeSite('Site_Type_Id', e.target.value)
                                }}
                                value={props.site.Site_Type_Id}
                            >
                                {types && types.map((el) => <MenuItem value={el.Site_Type_Id}>{el.Type}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* {props.site.Site_Type_Id === 2 && <HotelForm />} */}
                </Grid>
            </Form>
        </>
    )
}

export default SiteFormComponent
