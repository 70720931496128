import React, { FC } from 'react'
import { Select } from '@mui/material'

const NavSelect: FC<React.ComponentProps<typeof Select<string>>> = ({ sx, ...props }) => {
    return (
        <Select
            {...props}
            sx={{
                color: 'common.white',
                fontSize: 'inherit',
                '&: Mui-disabled': {
                    color: 'common.white',
                },
                border: 'none',
                '&: focus': {
                    outline: 'none',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
                outline: 'none',
                '& .MuiSelect-icon': {
                    color: 'inherit',
                },
                '& .MuiInputBase-input': {
                    px: 0,
                    py: 0.5,
                },
                ...sx,
            }}
        />
    )
}

export default NavSelect
