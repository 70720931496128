import React from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import { TextField, FormControl, Select, MenuItem } from '@mui/material'

class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = { address: props.defaultInputValue || '' }
    }

    handleChange = (address) => {
        this.setState({ address })
    }

    componentWillReceiveProps(newProps) {
        if (newProps.defaultInputValue) {
            this.setState({ address: newProps.defaultInputValue })
        }
    }

    handleSelect = (address) => {
        geocodeByAddress(address)
            .then(async (results) => {
                if (results && results.length > 0) {
                    let firstResult = results[0]
                    let output = {}
                    const address_components = firstResult['address_components']
                    const postal_code = address_components.find((el) => el.types.indexOf('postal_code') > -1)
                    const locality = address_components.find((el) => el.types.indexOf('locality') > -1)

                    const latLong = await getLatLng(firstResult)
                    output.Address = firstResult.formatted_address
                    output.Latitude = latLong.lat
                    output.Longitude = latLong.lng
                    output.ZipCode = postal_code.long_name || ''
                    output.City = locality.long_name || ''
                    this.props.onChange(output)
                    this.setState({
                        address: output.Address,
                    })
                }
            })
            .catch((error) => console.error('Error', error))
    }

    render() {
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={{
                    types: ['geocode'],
                    componentRestrictions: { country: 'fr' },
                }}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                    return (
                        <div style={{ position: 'reltive' }}>
                            <TextField
                                label="Adresse"
                                {...getInputProps({
                                    placeholder: 'Rechercher ...',
                                    className: 'location-search-input form-control',
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                    const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? {
                                              backgroundColor: '#fafafa',
                                              cursor: 'pointer',
                                          }
                                        : {
                                              backgroundColor: '#ffffff',
                                              cursor: 'pointer',
                                          }
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                }}
            </PlacesAutocomplete>
        )
    }
}

export default LocationSearchInput
