import { useQuery } from '@tanstack/react-query'
import { getDpe, dpeTypes } from '@/services/data.service'

const DPE_TITLES: Record<(typeof dpeTypes)[number], string> = {
    consumption: 'DPE',
    greenhouse: 'GES',
}

export const useDpe = (siteId: number) => {
    const { isError, isLoading, data } = useQuery({
        queryKey: ['dpe', siteId],
        queryFn: () => getDpe(siteId),
        staleTime: Infinity,
        enabled: Boolean(siteId),
    })
    const dpe = data
        ? dpeTypes.map((key) => ({
              type: key,
              title: DPE_TITLES[key],
              ...data.dpe[key],
          }))
        : []

    return { isLoading, isError, dpe }
}
