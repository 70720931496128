import { colors } from './variables'

export const Title = ({ title, style }) => {
    return (
        <div
            className="title"
            style={{
                color: colors.darken,
                fontSize: 22,
                fontWeight: 500,
                ...style,
            }}
        >
            {title}
        </div>
    )
}
