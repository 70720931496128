import ApartmentIcon from '@mui/icons-material/Apartment'
import CellWifiIcon from '@mui/icons-material/CellWifi'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HvacIcon from '@mui/icons-material/Hvac'
import LockIcon from '@mui/icons-material/Lock'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import WarningIcon from '@mui/icons-material/Warning'
import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { backgroundBlue, electricGreen, lightGrey, orangeButton } from '../../components/Basic/colors'
import {
    getConfiguration,
    getNetworkscanResults,
    getStatus,
    initSite,
    pingBox,
    saveConfiguration,
    saveSiteName,
    triggerNetworkScan,
} from '../../services/site.service'
import { sleep, useQuery } from '../../utils/global.utils'
import { validateIPaddress } from '../../utils/regex.utils'

export const SetupPage = () => {
    const meStore = useSelector((state) => state.meStore)
    const [loading, setLoading] = useState(false)
    const [leaveDialog, setLeaveDialog] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [siteName, setSiteName] = useState('')
    const [connectStatus, setConnectStatus] = useState('')
    const [hvac, setHvac] = useState([{ ip: '', brand: '' }])
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [info, setInfo] = useState('')
    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
    })
    const initSteps = [
        {
            icon: <ApartmentIcon />,
            checked: false,
            placeholder: 'Hôtel du labrador',
            label: 'Nom du site',
            value: siteName,
            modalContent: 0,
            buttonText: 'Enregistrer le nom de mon site',
        },
        {
            icon: <CellWifiIcon />,
            checked: false,
            placeholder: '',
            label: 'Connexion au réseau',
            value: connectStatus,
            buttonText: "Vérifier qu'Agrid est connecté",
        },
        {
            icon: <HvacIcon />,
            checked: false,
            placeholder: 'xxx.xxx.xxx.xxx',
            label: 'IP de la machine',
            value: hvac.ip + hvac.brand,
            modalContent: 2,
            buttonText: 'Récupérer les informations',
        },
        {
            icon: <LockIcon />,
            checked: false,
            placeholder: 'X X X X X X X',
            label: 'Identifiants',
            value: credentials.username,
            modalContent: 3,
            buttonText: 'Tester la connexion',
        },
    ]
    let brands = [
        {
            key: 'OKI Electric Industry',
            brand: 'Fujitsu',
            requireCredentials: true,
        },
        {
            key: 'Mitsubishi Electric',
            brand: 'Mitsubishi',
            requireCredentials: false,
        },
        { key: 'Daikin Industries', brand: 'Daikin', requireCredentials: true },
        // { key: "HF Company", brand: "Daikin", requireCredentials: true },
    ]
    const [notAllowed, setNotAllowed] = useState(false)
    const [steps, setSteps] = useState(initSteps)
    const [modalContent, setModalContent] = useState(null)
    const [configuration, setConfiguration] = useState(null)
    let query = useQuery()

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (hvac?.length > 0) {
            let ok = true
            for (let i = 0; i < hvac?.length; i++) {
                let ip = validateIPaddress(hvac[i]?.ip)
                if (!ip) ok = false
                if (!hvac[i]?.brand) ok = false
            }
            if (ok) {
                steps[2]['checked'] = true
                setSteps(steps)
            }
        }
    }, [hvac])
    let hash = query.get('hash')
    const init = async () => {
        let find = meStore?.me?.sites?.find((el) => el.Hash === hash)
        if (!find) {
            setNotAllowed(true)
        } else {
            setSiteName(find?.Name)
            /** nothing */
        }
        let res = await getConfiguration(hash)
        if (res?.configuration) {
            if (res?.configuration)
                setCredentials({
                    username: res?.configuration?.Username,
                    password: res?.configuration?.Password,
                })
        }
    }

    const shouldEnterPassword = () => {
        let count = 0
        for (let i = 0; i < hvac?.length; i++) {
            if (hvac[i].brand === 'Mitsubishi') count += 1
        }
        return !(hvac.length === count)
    }

    const parseNetworks = (networks) => {
        let out = []
        for (let i = 0; i < networks?.length; i++) {
            let mac = networks[i]?.MacAddress
            for (let j = 0; j < brands.length; j++) {
                if (mac?.toLowerCase().includes(brands[j]?.key?.toLowerCase())) {
                    out.push({
                        brand: brands[j]?.brand,
                        ip: networks[i]?.IpAddress,
                        requireCredentials: brands[j]?.requireCredentials,
                    })
                }
            }
        }
        return out
    }

    const click = async () => {
        setLoading(true)
        setError('')
        setSuccess('')
        setInfo('')
        if (!steps?.[0]?.checked) {
            /** we must verifiy the name of the site */

            if (siteName?.length < 2) {
                setError('Merci de mettre un nom de site')
            } else {
                let res = await saveSiteName(hash, siteName)
                if (res?.success) {
                    steps[0]['checked'] = true
                    setSuccess('Le nom a été enregistré !')
                    setSteps(steps)
                } else if (res?.errorMsg || res?.errorMessage) setError(res?.errorMsg || res?.errorMessage)
                else {
                    setError('Une erreur est survenue...')
                }
            }
        } else if (!steps?.[1]?.checked) {
            /** we must verifiy that we can ping our box */
            /** TO_DO - Fetch get query */
            let res = await pingBox(hash)
            if (res.success) {
                steps[1]['warning'] = false
                steps[1]['checked'] = true
                setSteps(steps)
                setSuccess('Votre box Agrid est bien connectée à internet')
                setConnectStatus('Connecté')
            } else {
                steps[1]['warning'] = true
                setSteps(steps)
                setConnectStatus('Non connecté')
                setError(
                    "Il semblerait qu'Agrid ne puisse pas se connecter à internet. Merci de vérifier que le cable ethernet est bien branché."
                )
            }
        } else if (!steps?.[2]?.checked) {
            /** we must find data on the network */
            /** TO_DO - Fetch get query */
            setInfo('Cette opération peut durer quelques minutes')
            await triggerNetworkScan(hash)
            await sleep(10000)
            let res = await getNetworkscanResults(hash)
            let count = 0
            while (res?.success && res?.networks?.length == 0 && count < 15) {
                res = await getNetworkscanResults(hash)
                await sleep(20000)
                count += 1
            }
            if (res?.success) {
                let result = parseNetworks(res?.networks)
                steps[2]['warning'] = false

                if (result?.length > 0) {
                    steps[2]['checked'] = true
                    setHvac(result)
                    setSuccess(`Nous avons trouvé une machine ${result?.[0]?.brand} sur l'adresse ${result?.[0]?.ip}`)
                } else {
                    steps[2]['warning'] = true
                    setHvac([{ ip: '', brand: '' }])
                    setInfo(
                        `Nous n'avons pas trouvé de machine compatible sur votre réseau. Merci de la renseigner manuellement.`
                    )
                }
                setSteps(steps)
                // setConnectStatus("Connecté");
            } else {
                steps[2]['warning'] = true
                setSteps(steps)
                setError('Une erreur est survenue lors du scan effectuée par Agrid')
            }
        } else if (!steps?.[3]?.checked) {
            if (shouldEnterPassword() && (!credentials?.password || !credentials?.username)) {
                steps[3]['warning'] = true
                setSteps(steps)
                setError('Pour se connecter à votre GTC, nous avons besoin des identifiants. Merci de les renseigner !')
            } else {
                let res = await saveConfiguration(hash, { hvac, credentials })
                if (res?.success) {
                    steps[3]['warning'] = false
                    setSteps(steps)
                    setSuccess('La configuration est sauvegardée et Agrid est prêt à lancer le setup !')
                    if (res?.configuration) setConfiguration(res?.configuration)
                    steps[3]['checked'] = true
                } else {
                    setError(`Une erreur est survenue: ${res?.errorMsg || res?.errorMessage}`)
                    steps[3]['warning'] = true
                    setSteps(steps)
                }
            }
            /** we must check credentials work if needed */
        } else {
            /** we must launch set up */
            let res = await initSite(hash, {
                congigurationId: configuration?.ConfigurationId,
            })
            await sleep(20000)
            res = await getStatus(hash, {
                hash: hash,
                configurationId: configuration?.ConfigurationId,
            })
            while (res?.Status !== 'ready' && res.success === true) {
                await sleep(20000)
                res = await getStatus(hash, {
                    hash: hash,
                    configurationId: configuration?.ConfigurationId,
                })
            }
            if (!res.success) {
                setError(`Une erreur est survenue: ${res?.errorMsg || res?.errorMessage}`)
            } else {
                setSuccess(
                    "L'installation a bien fonctionné ! Vous pouvez dès à présent voir vos données sur votre tableau de bord "
                )
            }
        }
        setLoading(false)
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            {notAllowed ? (
                <Backdrop
                    open={true}
                    style={{
                        zIndex: 10,
                        color: 'white',
                        flexDirection: 'column',
                        display: 'flex',
                    }}
                >
                    <CircularProgress color="inherit" />
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{
                            color: 'white',
                            zIndex: 20,
                            marginTop: 10,
                            fontSize: 14,
                            padding: 20,
                            textAlign: 'center',
                        }}
                    >
                        L'url indiquée ne semble pas correcte ou vous n'avez pas les droits sur ce site.
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => {
                            window.location.href = '/'
                        }}
                        // style={{ color: "white" }}
                    >
                        Retourner sur le site
                    </Button>
                </Backdrop>
            ) : (
                <Box
                    sx={{
                        width: '100%',
                        margin: { xs: 0, md: '30px  30%' },
                        height: { xs: '100%', md: 'calc(100% - 60px)' },
                        position: 'relative',
                        flex: 1,
                        boxSizing: 'border-box',
                        boxShadow: {
                            xs: 'none',
                            md: '0px 0px 11px 1px rgba(33,33,33,0.6)',
                        },
                    }}
                >
                    {loading && (
                        <Backdrop open={true} style={{ zIndex: 10, color: 'white' }}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    )}

                    <Dialog
                        open={leaveDialog}
                        onClose={() => setLeaveDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{'Fermer le formulaire'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                En quittant le formulaire maintenant, votre site ne sera pas totalement installé et nous
                                ne pourrons pas faire d'économies.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setLeaveDialog(false)
                                }}
                            >
                                Je reste
                            </Button>
                            <Button onClick={() => setLeaveDialog(false)}>Je pars</Button>
                        </DialogActions>
                    </Dialog>
                    <Modal
                        open={showModal}
                        onClose={() => setShowModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '30%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '90%',
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                borderRadius: 2,
                                padding: '20px',
                            }}
                        >
                            {modalContent !== null && (
                                <>
                                    <Typography
                                        id="modal-modal-title"
                                        variant="h6"
                                        component="h2"
                                        style={{ marginBottom: 20 }}
                                    >
                                        {modalContent === 0 && 'Changer le nom de mon site'}
                                        {modalContent === 2 &&
                                            "Modifier manuellement l'adresse IP de ma machine ou la marque installée"}
                                        {modalContent === 3 && hvac?.find((el) => el.requireCredentials)
                                            ? 'Modifier les identifiants'
                                            : modalContent === 3
                                              ? "Cette marque ne nécessite pas d'identifiants"
                                              : ''}
                                    </Typography>
                                    {modalContent === 0 && (
                                        <TextField
                                            variant="outlined"
                                            label={'Nom du site'}
                                            value={siteName}
                                            onChange={(e) => {
                                                steps[0]['checked'] = false
                                                setSteps(steps)
                                                setSiteName(e.target.value)
                                            }}
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    )}

                                    {modalContent === 2 &&
                                        hvac?.map((el, index) => {
                                            return (
                                                <Grid container spacing={2} style={{ marginTop: 10 }}>
                                                    <Grid item md={6} xs={6}>
                                                        <TextField
                                                            variant="outlined"
                                                            label={'Ip'}
                                                            value={el?.ip}
                                                            onChange={(e) => {
                                                                let newHvac = JSON.parse(JSON.stringify(hvac))
                                                                newHvac[index] = {
                                                                    ...newHvac[index],
                                                                    ip: e.target.value,
                                                                }
                                                                setHvac(newHvac)
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item md={6} xs={6}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">
                                                                Marque
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={el.brand}
                                                                label="Marque"
                                                                onChange={(e) => {
                                                                    let newHvac = JSON.parse(JSON.stringify(hvac))
                                                                    let find = brands?.find(
                                                                        (element) => element.brand === e.target.value
                                                                    )
                                                                    newHvac[index] = {
                                                                        ...newHvac[index],
                                                                        brand: e.target.value,
                                                                        requireCredentials: find?.requireCredentials,
                                                                    }
                                                                    setHvac(newHvac)
                                                                }}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                {brands.map((item) => {
                                                                    return (
                                                                        <MenuItem value={item.brand}>
                                                                            {item.brand}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    {modalContent === 3 && hvac?.find((el) => el.requireCredentials) && (
                                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                                            <Grid item md={6} xs={6}>
                                                <TextField
                                                    variant="outlined"
                                                    label={"Nom d'utilisateur"}
                                                    value={credentials?.username}
                                                    onChange={(e) => {
                                                        setCredentials({
                                                            ...credentials,
                                                            username: e.target.value,
                                                        })
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={6}>
                                                <TextField
                                                    variant="outlined"
                                                    label={'Mot de passe'}
                                                    value={credentials?.password}
                                                    onChange={(e) => {
                                                        setCredentials({
                                                            ...credentials,
                                                            password: e.target.value,
                                                        })
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Box>
                    </Modal>
                    <Grid container>
                        <Grid
                            xs={12}
                            item
                            style={{
                                background: orangeButton,
                                padding: 20,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            {/* <Typography
            style={{
              color: "white",
              fontSize: 10,
              fontWeight: "bold",
              marginBottom: 10,
              textTransform: "uppercase",
              letterSpacing: 2,
            }}
          >
            Ajouter un nouveau site
          </Typography> */}
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 7,
                                    left: 7,
                                    color: 'white',
                                    textTransform: 'uppercase',
                                    fontSize: 10,
                                }}
                                onClick={() => setLeaveDialog(true)}
                            >
                                Retour au site Agrid
                            </div>

                            <Typography
                                style={{
                                    color: 'white',
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    marginBottom: 5,
                                    maxWidth: 300,
                                    textAlign: 'center',
                                    margin: 20,
                                    letterSpacing: 1.2,
                                }}
                            >
                                Préparez-vous à rentrer dans l'ère du bâtiment connecté en quelques minutes !
                            </Typography>
                            <QrCodeScannerIcon style={{ color: 'white', fontSize: 70 }} />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'scroll',
                            }}
                        >
                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: `1px solid red`,
                                    padding: 10,
                                    marginTop: 10,
                                }}
                                justifyContent="center"
                            >
                                {steps?.map((el, index) => {
                                    return (
                                        <Grid item xs={10}>
                                            <TextField
                                                style={{
                                                    width: '100%',
                                                    background: el.checked ? 'rgba(82, 209, 135, 0.2)' : '',
                                                }}
                                                placeholder={el?.placeholder}
                                                variant="outlined"
                                                label={el.label}
                                                value={
                                                    index === 0
                                                        ? siteName
                                                        : index === 1
                                                          ? connectStatus
                                                          : index === 2
                                                            ? hvac?.length > 0 && hvac?.[0]?.ip
                                                                ? `${hvac?.[0]?.ip}/${hvac?.[0]?.brand}`
                                                                : ''
                                                            : index === 3 && credentials?.username
                                                              ? `${
                                                                    credentials?.username
                                                                }/${credentials?.password?.replace(/./g, 'x')}`
                                                              : ''
                                                }
                                                disabled={true}
                                                onClick={() => {
                                                    if (
                                                        el?.modalContent !== undefined &&
                                                        (index === 0 || steps?.[index - 1]?.checked)
                                                    ) {
                                                        setModalContent(el?.modalContent)
                                                        setShowModal(true)
                                                    }
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">{el.icon}</InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            {el?.warning ? (
                                                                <WarningIcon style={{ color: '#ff9800' }} />
                                                            ) : el?.checked ? (
                                                                <CheckCircleIcon style={{ color: electricGreen }} />
                                                            ) : (
                                                                <CheckCircleOutlineIcon style={{ color: lightGrey }} />
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    )
                                })}
                                <Grid item xs={10}></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {(error || info || success) && (
                        <Snackbar
                            open={true}
                            autoHideDuration={info ? 12000 : 6000}
                            onClose={() => {
                                setInfo('')
                                setSuccess('')
                                setError('')
                            }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                            <Alert
                                severity={error ? 'error' : info ? 'info' : 'success'}
                                onClose={() => {
                                    setInfo('')
                                    setSuccess('')
                                    setError('')
                                }}
                            >
                                {error || success || info}
                            </Alert>
                        </Snackbar>
                    )}

                    {/* <Box
            sx={{
              width: "100%",
              height: { xs: 0, md: 100 },
            }}
          ></Box> */}
                    <div
                        style={{
                            width: '100%',
                            background: backgroundBlue,
                            color: 'white',
                            padding: 20,
                            textAlign: 'center',
                            fontSize: 16,
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            cursor: 'pointer',
                        }}
                        onClick={click}
                    >
                        {!steps?.[0]?.checked
                            ? 'Valider le nom de mon site'
                            : !steps?.[1]?.checked
                              ? 'Vérifier la connexion de ma box Agrid'
                              : !steps?.[2]?.checked
                                ? 'Scanner mon réseau'
                                : !steps?.[3]?.checked
                                  ? 'Valider la configuration actuelle'
                                  : "Finaliser l'installation"}
                    </div>
                </Box>
            )}
        </div>
    )
}
