import { createSlice } from '@reduxjs/toolkit'

const GlobalSlice = createSlice({
    name: 'global',
    initialState: {
        path: null,
        mode: 'normal',
        width: 1200,
        sites: [],
        selectedSite: null,
        objects: [],
        selectedObject: null,
        selectedCompany: null,
    },
    reducers: {
        setSelectedSite: (state, { payload }) => {
            return {
                ...state,
                selectedSite: payload,
            }
        },
        setSites: (state, { payload }) => {
            return {
                ...state,
                sites: payload,
            }
        },
        setPath: (state, { payload }) => {
            return {
                ...state,
                path: payload,
            }
        },
        setMode: (state, { payload }) => {
            return {
                ...state,
                mode: payload,
            }
        },
        setWidth: (state, { payload }) => {
            return {
                ...state,
                width: payload,
            }
        },
        setObjects: (state, { payload }) => {
            return {
                ...state,
                objects: payload,
            }
        },
        setSelectedObject: (state, { payload }) => {
            return {
                ...state,
                selectedObject: payload,
            }
        },
        setSelectedCompany: (state, { payload }) => {
            return {
                ...state,
                selectedCompany: payload,
            }
        },
    },
})

export default GlobalSlice
