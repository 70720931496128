import { FC } from 'react'
import { Alert, Typography } from '@mui/material'
import moment from 'moment'

interface OverrunAlertProps {
    meters: { provider: string; pointId: string; overrunMinutes: number }[]
}

const OverrunAlert: FC<OverrunAlertProps> = ({ meters }) => {
    if (!meters.length) {
        return null
    }
    return (
        <Alert severity="warning">
            {meters.map((meter) => (
                <Typography key={meter.pointId} variant="body2">
                    {meter.provider} #{meter.pointId}&thinsp;: puissance souscrite dépassée pendant{' '}
                    {moment.duration(meter.overrunMinutes, 'minutes').humanize()}
                </Typography>
            ))}
        </Alert>
    )
}

export default OverrunAlert
