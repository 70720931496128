import { useState } from 'react'
import {
    SiteMetaData,
    CategoryFilterKey,
    Perimeter,
    SiteCategoryFilters,
    PerimeterFiltersMethods,
    CATEGORY_FILTERS,
    SiteData,
} from '../types'
import { filterSiteDataByYear } from '../utils/filterSeriesData'
import { perimeterPalette } from '../utils/palette'

const getColor = (index: number): string => perimeterPalette[index % perimeterPalette.length]

const filterSitesByCategory = <T extends SiteMetaData = SiteMetaData>(sites: T[], filters: SiteCategoryFilters): T[] =>
    sites.filter((site) =>
        filters.every((filter) => filter.values.length === 0 || filter.values.includes(site[filter.key]))
    )

const defaultYear = new Date().getFullYear() - 1
const defaultCategoryFilters: SiteCategoryFilters = CATEGORY_FILTERS.map((key) => ({ key, values: [] }))

export const usePerimeterFilters = (): PerimeterFiltersMethods => {
    const [perimeters, setPerimeters] = useState<Perimeter[]>([
        {
            categoryFilters: defaultCategoryFilters,
            year: defaultYear,
            color: perimeterPalette[0],
        },
    ])

    const addPerimeter = () => {
        setPerimeters([
            ...perimeters,
            {
                categoryFilters: defaultCategoryFilters,
                year: defaultYear,
                color: getColor(perimeters.length),
            },
        ])
    }

    const removePerimeter = (index: number) => {
        setPerimeters(perimeters.filter((_, i) => i !== index))
    }

    const removeFilter = (index: number, key: CategoryFilterKey) => {
        const newPerimeters = [...perimeters]
        newPerimeters[index].categoryFilters = newPerimeters[index].categoryFilters.map(({ key: pkey, values }) =>
            pkey === key ? { key, values: [] } : { key, values }
        )
        setPerimeters(newPerimeters)
    }

    const updateFilterValues = (index: number, key: CategoryFilterKey, values: SiteMetaData[CategoryFilterKey][]) => {
        const newPerimeters = [...perimeters]
        newPerimeters[index].categoryFilters = newPerimeters[index].categoryFilters.map((filter) => {
            if (filter.key === key) {
                return { ...filter, values }
            }
            return filter
        })
        setPerimeters(newPerimeters)
    }

    const updatePerimeterYear = (index: number, year: number) => {
        const newPerimeters = [...perimeters]
        newPerimeters[index].year = year
        setPerimeters(newPerimeters)
    }

    return {
        perimeters: perimeters.map((perimeter, index) => ({
            perimeter,
            methods: {
                clearFilter: (key: CategoryFilterKey) => removeFilter(index, key),
                updateFilterValues: (key: CategoryFilterKey, values: SiteMetaData[CategoryFilterKey][]) =>
                    updateFilterValues(index, key, values),
                updatePerimeterYear: (year: number) => updatePerimeterYear(index, year),
                filterSites: (sites: SiteData[]) => filterSitesByCategory(sites, perimeter.categoryFilters),
                filterSeriesYear: (sites: SiteData[]) =>
                    sites.map((site) => filterSiteDataByYear(site, perimeter.year)),
            },
        })),
        addPerimeter,
        removePerimeter,
    }
}
