import React from 'react'

import { Typography } from '@mui/material'
import { electricOrange } from '../../../components/Basic/colors'

export const WarningText = ({ text }) => {
    return (
        <Typography
            sx={{
                fontSize: 10,
                fontStyle: 'italic',
                color: electricOrange,
            }}
            color="text.secondary"
        >
            {text}
        </Typography>
    )
}
