import { FC } from 'react'
import { usePowerSeries, PowerSeries } from '../../dataProviders/useEnergyConsumptions'
import { Skeleton, Card } from '@mui/material'
import { CardTitle } from '../card-title.component'
import moment from 'moment'
import OverrunAlert from './OverrunAlert'
import { chartFormatOptions, getPlotlineOptions, formatSeries } from './powerCurvesChart.utils'
import HighchartsChart from '@/components/Charts/HighchartsChart'
import useHighcharts from '@/components/Charts/useHighcharts'

interface PowerCurvesChartProps {
    powerSeries: PowerSeries
}

const PowerCurvesChart: FC<PowerCurvesChartProps> = ({ powerSeries }) => {
    const { getColor } = useHighcharts()
    const { categories, series } = powerSeries
    const timestamps = categories.map((category) => moment.utc(category).valueOf())

    return (
        <HighchartsChart
            options={{
                ...chartFormatOptions,
                series: series.map((s, i) => formatSeries(s, timestamps, { color: getColor(i), max: s.pmaxKw })).flat(),

                yAxis: {
                    ...chartFormatOptions.yAxis,
                    plotLines: series
                        .filter((s) => s.pmaxKw !== undefined)
                        .map((s, i) => getPlotlineOptions(s.pmaxKw as number, s.name, getColor(i) as string)),
                },
            }}
        />
    )
}

const PowerCurvesChartWrapper = ({
    siteId,
    startDate,
    endDate,
}: {
    siteId: number
    startDate: string
    endDate: string
}) => {
    const { powerSeries, isLoading, isError, overruns } = usePowerSeries({ siteId, startDate, endDate })

    if (isLoading) {
        return <Skeleton variant="rectangular" width={'100%'} height={300} />
    }

    if (isError || !powerSeries?.series.length) {
        return null
    }

    return (
        <Card sx={{ p: 2 }}>
            <CardTitle title="Puissance instantanée" style={{}} />
            <PowerCurvesChart powerSeries={powerSeries} />
            <OverrunAlert meters={overruns} />
        </Card>
    )
}

export default PowerCurvesChartWrapper
