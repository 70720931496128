import { Backdrop, Button, Card, CardContent, Chip, CircularProgress, LinearProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import ReactBSAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { PageTitleComponent } from '../../../components/Basic/library'
import { getEquipments } from '../../../services/realtime.service'
import GlobalSlice from '../../../store/slices/global.slice'
import useLocale from '../../../utils/locale/locale.hook'
import EquipmentCard from './equipment.component'
import SiteTelecommand from './site.telecommand'

const ZonePage = ({ setModal, addModification, clearModifications }) => {
    const selectedSite = useSelector((state) => state.globalStore.selectedSite)
    const selectedObject = useSelector((state) => state.globalStore.selectedObject)
    const [showAlert, setShowAlert] = useState(null)
    const [equipments, setEquipments] = useState([])
    const [familyTypes, setFamilyTypes] = useState({})
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()
    let { search } = useLocation()
    const [temperature, setTemperature] = useState(null)
    const dispatch = useDispatch()
    const locale = useLocale()
    useEffect(() => {
        clearModifications()
        fetchData()
    }, [selectedObject, selectedSite])

    const fetchData = async () => {
        let objectId = selectedObject?.ObjectId
        if (!objectId) {
            const query = new URLSearchParams(search)
            objectId = query.get('objectId')
        }
        setLoading(true)
        if (selectedSite?.Hash && objectId) {
            let res = await getEquipments({
                hash: selectedSite.Hash,
                objectId: objectId,
            })
            if (res && res.equipments) {
                let formattedEquipments = await formatEquipments(res.equipments)
                let familyTypes = await extractFamilies(res.equipments)
                setFamilyTypes(familyTypes)
                setEquipments(formattedEquipments)
            }
        }
        setLoading(false)
    }

    const extractFamilies = async (data) => {
        let output = {}
        for (let i = 0; i < data.length; i++) {
            let equipment = data[i]
            let sensors = equipment.Sensors
            let actuators = equipment.Actuators
            ;[sensors, actuators].map((element, index) => {
                for (let j = 0; j < element.length; j++) {
                    let item = element[j]
                    let keyName = index === 0 ? 'Sensors' : 'Actuators'
                    if (!output.hasOwnProperty(item['DataTypeFamilyName'])) {
                        output[item['DataTypeFamilyName']] = {
                            Sensors: [],
                            Actuators: [],
                            DataTypeFamilyId: item.DataTypeFamilyId,
                        }
                        output[item['DataTypeFamilyName']][keyName].push({
                            ...item,
                            sensors: [item.SensorId],
                            cmdIds: [item.ActuatorId],
                            //cmdIds: [item.IdLocal_Hub],
                        })
                    } else {
                        let exist = output[item['DataTypeFamilyName']][keyName].find(
                            (el) => el.DataTypeId === item.DataTypeId
                        )
                        if (exist) {
                            exist.sensors.push(item.SensorId)
                            if (item.ActuatorId) {
                                exist.cmdIds.push(item.ActuatorId)
                                //exist.cmdIds.push(item.IdLocal_Hub);
                            }
                        } else {
                            output[item['DataTypeFamilyName']][keyName].push({
                                ...item,
                                sensors: [item.SensorId],
                                cmdIds: [item.ActuatorId],
                                //cmdIds: [item.IdLocal_Hub],
                            })
                        }
                    }
                }
            })
        }
        return output
    }

    const formatEquipments = async (data) => {
        let output = {}
        for (let i = 0; i < data.length; i++) {
            let element = data[i]
            output[element['EquipmentId']] = {
                Name: element.Name,
                EquipmentId: element.EquipmentId,
                DataTypeFamilyId: element.DataTypeFamilyId,
                Sensors: element.Sensors.map((el) => {
                    return { ...el, sensors: [el.SensorId] }
                }),
                Actuators: element.Actuators.map((el) => {
                    return {
                        ...el,
                        sensors: [el.SensorId],
                        cmdIds: [el.IdLocal_Hub],
                    }
                }),
            }
        }

        return output
    }

    return (
        <>
            {/* {typeof showAlert == "boolean" && (
        <ReactBSAlert
          style={{ display: "block" }}
          title={showAlert ? "Bravo" : "Oups"}
          onConfirm={() => setShowAlert(null)}
          success={showAlert}
          warning={!showAlert}
          confirmBtnText="Fermer"
        >
          {showAlert
            ? locale?.["realTime"]?.["success"]
            : locale?.["realTime"]?.["error"]}
        </ReactBSAlert>
      )} */}
            {loading ? (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <>
                    <Button
                        onClick={() => {
                            clearModifications()
                            navigate(`/realtime?site=${selectedSite?.Hash}`)
                            dispatch(GlobalSlice.actions.setSelectedObject(null))
                            setModal(null)
                        }}
                    >
                        {locale?.['back']}
                    </Button>

                    <Card style={{ marginTop: 10 }}>
                        <CardContent>
                            <div
                                style={{
                                    fontSize: 24,
                                    color: '#050C22',
                                    textShadow: '0px 13px 26px rgba(0, 0, 0, 0.02)',
                                    letterSpacing: '-0.03em',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div style={{ display: 'flex' }}>
                                    <PageTitleComponent title={selectedObject?.Name?.toUpperCase()} />
                                    {temperature &&
                                        temperature != null &&
                                        temperature != undefined &&
                                        selectedObject?.ObjectTypeId !== 1 && (
                                            <Chip
                                                label={`${temperature}`}
                                                color="primary"
                                                style={{ fontSize: 12, marginLeft: 10 }}
                                            />
                                        )}
                                </div>
                            </div>

                            {!(selectedObject?.ObjectTypeId === 1) &&
                                Object.keys(familyTypes).map((keyName, _) => {
                                    return (
                                        <EquipmentCard
                                            equipment={{
                                                ...familyTypes[keyName],
                                                Name: keyName,
                                            }}
                                            global={true}
                                            setShowAlert={setShowAlert}
                                            addModification={addModification}
                                            setTemperature={setTemperature}
                                            objectId={selectedObject?.ObjectId}
                                        />
                                    )
                                })}

                            {!(selectedObject?.ObjectTypeId === 1) &&
                                Object.keys(familyTypes).length === 0 &&
                                Object.keys(equipments).length === 0 &&
                                loading && <LinearProgress />}
                            {!(selectedObject?.ObjectTypeId === 1) &&
                                Object.keys(familyTypes).length === 0 &&
                                Object.keys(equipments).length === 0 &&
                                !loading &&
                                !(selectedObject?.ObjectTypeId === 1) && (
                                    <span>{locale?.['realTime']?.['noData']}</span>
                                )}
                            {selectedObject?.ObjectTypeId === 1 && (
                                <SiteTelecommand addModification={addModification} style={{ paddingTop: 15 }} />
                            )}
                        </CardContent>
                    </Card>
                </>
            )}
        </>
    )
}

export default ZonePage
