import DashboardIcon from '@mui/icons-material/Dashboard'

type Icon = typeof DashboardIcon

export enum RoutePaths {
    Dashboard = '/dashboard',
    Realtime = '/realtime',
    Constraints = '/constraints',
    Maintenance = '/maintenance',
    Documents = '/documents',
    Help = '/help',
    // Support = '/support',
    Settings = '/settings/security',
    Groups = '/hidden',
}

export type RouteData = {
    title: string
    navbarTitle: string
    minScope?: 'root' | 'site' | 'object'
    titleLink?: boolean
    path: RoutePaths
    admin?: boolean
    dev?: boolean
    enabled: string[]
    access: string[]
    accessKey?: string[]
    whitelist?: string[]
    Icon: Icon
}
