export interface SiteMetaData {
    id: number
    name: string
    city: string
    country: string
    countryCode: string
    latitude: number
    longitude: number
    brand: string
    subBrand: string | null
    fund: string
    financialPartners: string[]
    hotelGroups: string[]
    acquisitionDate: string
    assetStatus: string
    stars: number
    surface: number
    rooms: number
}

export enum SeriesTypes {
    ENERGY = 'energy',
    ENERGY_TOTAL = 'energy_total',
    WATER = 'water',
    OCCUPANCY = 'occupancy',
}

export enum OccupancySubtypes {
    OCCUPANCY_RATE = 'OCCUPANCY_RATE',
    GUEST_NIGHTS = 'GUEST_NIGHTS',
}

export enum EnergySubtypes {
    ELECTRICITY = 'ELECTRICITY',
    GAS = 'GAS',
    HEAT = 'HEAT',
    OTHER = 'OTHER',
}

interface SeriesDataPoint {
    date: string // eg 2024-01
    value: number
}

type SeriesData = SeriesDataPoint[]

export interface Series {
    type: SeriesTypes
    subType: EnergySubtypes | OccupancySubtypes | null
    unit: string | null
    data: SeriesData
    fullName: string
}

interface MetricDataPoint {
    year: number
    value: number
}

export enum MetricKeys {
    BIO = 'bio',
    PMR = 'pmr',
    GENDER = 'gender',
}
export interface Metric {
    key: MetricKeys
    data: MetricDataPoint[]
}

type DpeType = 'consumption' | 'emission'
type DpeLabel = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G'
export interface DpeFullLabel {
    label: DpeLabel
    value: number
    unit: string
}

export interface SiteData extends SiteMetaData {
    series: Series[]
    metrics: Metric[]
    dpe: Record<DpeType, DpeFullLabel>
    completionRate: number
}

export const CATEGORY_FILTERS = ['brand', 'subBrand', 'fund', 'stars', 'country'] as const

export type CategoryFilterKey = (typeof CATEGORY_FILTERS)[number]

export const categoryFilterKeyTranslations: Record<string, string> = {
    brand: 'Marque',
    subBrand: 'Enseigne',
    fund: 'Fonds',
    stars: 'Étoiles',
    city: 'Ville',
    country: 'Pays',
    financialPartners: 'Partenaires financiers',
    hotelGroups: "Groupes hôteliers présents dans l'affaire",
}

// Perimeters and filters
interface CategoryFilter<T extends CategoryFilterKey> {
    key: T
    values: readonly SiteMetaData[T][]
}

export type SiteCategoryFilters = CategoryFilter<CategoryFilterKey>[]

export interface Perimeter {
    categoryFilters: SiteCategoryFilters
    year: number
    color: string
}

export interface PerimeterFilter {
    perimeter: Perimeter
    methods: {
        clearFilter: (key: CategoryFilterKey) => void
        updateFilterValues: (key: CategoryFilterKey, values: SiteMetaData[CategoryFilterKey][]) => void
        updatePerimeterYear: (year: number) => void
        filterSites: (sites: SiteData[]) => SiteData[]
        filterSeriesYear: (sites: SiteData[]) => SiteData[]
    }
}
export interface PerimeterFiltersMethods {
    perimeters: PerimeterFilter[]
    addPerimeter: () => void
    removePerimeter: (index: number) => void
}

export interface PerimeterExtendedSiteData extends SiteData {
    perimeter: Perimeter
    perimeterIndex: number
}

export enum IntensityUnit {
    M2_YEAR = 'm2.year',
    GUEST_NIGHT = 'guest.night',
}

export interface ReferenceStudyConsumptionMetric {
    type: SeriesTypes.ENERGY_TOTAL | SeriesTypes.WATER
    subType: 'final' | 'co2' | null
    consumption_unit: string
    intensityUnit: IntensityUnit
    value: number
}

export interface ReferenceStudyData {
    id: string
    name: string
    fullName: string
    year: number
    scope: string
    buildints: number
    surface: number
    rooms: number
    link: string
    parsedConsumptionMetrics: ReferenceStudyConsumptionMetric[]
    parsedSocialMetrics: Record<string, number>
}
