import { Container, Grid } from '@mui/material'
import React, { useRef, useMemo } from 'react'
import { Kpis } from './components/kpis.component'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import './components/GroupsPage.css'
import SortableDataGrid from './components/table'
import { hotels } from './components/variables'
import { ScatterComponent } from './components/scatter.component'
import { useDemoSites } from './hooks/useDemoSites'
import Loader from '@/components/fallbacks/loader.component'
import { MapChart } from './components/map.component'
import { computeKpis } from './utils'
import { useGroupSites } from './hooks/useGroupSites'
import Header from './components/header.component'
import Toolbar from './components/toolbar.component'
import BarChartComponent from './components/bar.component'
import { IconPercentage } from './components/infographic.component'
import PersonIcon from '@mui/icons-material/Person'
import LocalHotelIcon from '@mui/icons-material/LocalHotel'
import RecyclingIcon from '@mui/icons-material/Recycling'
import { Title } from './components/title.component'
import HeaderOffset from './components/headerOffset.component'
import { useReferenceStudies } from './hooks/useReferenceStudies'
import Footer from './components/footer.component'

export const GroupsPage = () => {
    const { data = [], isLoading, filterOptions, perimeters, addPerimeter, removePerimeter, ...rest } = useDemoSites()
    const headerRef = useRef(null)
    const sitesPerPerimeter = perimeters.map(({ perimeter, methods }, index) =>
        methods.filterSites(data).map((site) => ({ ...site, perimeter, perimeterIndex: index }))
    )
    const csvExportMethod = useRef(undefined)
    const sitesPerPerimeterFiltered = sitesPerPerimeter.map(
        (sites, index) => perimeters[index].methods.filterSeriesYear(sites) // has data filtered by perimeter year
    )
    const { aggregate, ...groupingUtils } = useGroupSites()
    const aggregatedSitesPerPerimeter = sitesPerPerimeterFiltered.map((sites) => aggregate(sites)).flat() // one aggregate site per perimeter and per value of the groupby
    const totalSitesPerPerimeter = sitesPerPerimeterFiltered
        .map((sites) => groupingUtils.aggregateAll(sites))
        .flat() // one aggregate site per perimeter
        .map((site) => ({ ...site, name: `Périmètre ${site.perimeterIndex + 1}` }))
        .sort((a, b) => a.perimeterIndex - b.perimeterIndex)

    const { referenceStudies, selectedStudy, ...referenceUtils } = useReferenceStudies()

    const kpis = useMemo(() => {
        let kpis = []
        for (let i = 0; i < sitesPerPerimeter?.length; i++) {
            kpis.push(computeKpis({ sites: sitesPerPerimeter[i], year: perimeters?.[i]?.perimeter?.year }))
        }
        return kpis
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sitesPerPerimeter])

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            <Header ref={headerRef} truc={JSON.stringify(perimeters)}>
                <Toolbar
                    filterOptions={filterOptions}
                    perimeters={perimeters}
                    addPerimeter={addPerimeter}
                    removePerimeter={removePerimeter}
                    counts={sitesPerPerimeter.map((arr) => arr.length)}
                    exportAsCsv={csvExportMethod?.current}
                    referencePickerUtils={{
                        ...referenceUtils,
                        options: referenceStudies.map(({ fullName }) => fullName),
                    }}
                    groupingUtils={{
                        value: groupingUtils.groupBy,
                        setValue: groupingUtils.setGroupBy,
                        options: groupingUtils.groupByOptions,
                    }}
                    {...rest}
                />
            </Header>
            <HeaderOffset headerRef={headerRef} />
            <Container>
                <Grid container spacing={3}>
                    <TransitionGroup component={null}>
                        <CSSTransition key={'kpis'} timeout={500} classNames="fade">
                            <>
                                <Grid item md={12}>
                                    <br />
                                    <Title title="🎯 Indicateurs clés" />
                                </Grid>
                                <Grid item md={12}>
                                    <Kpis kpis={kpis} perimeters={perimeters} referenceStudies={referenceStudies} />
                                </Grid>
                                <Grid item md={5}>
                                    <Grid item container spacing={2}>
                                        <Grid item md={12}>
                                            <IconPercentage
                                                icon={<PersonIcon style={{ fontSize: 30 }} />}
                                                title={'🙎‍♀️ Mixité'}
                                                sitesPerPerimeterFiltered={sitesPerPerimeterFiltered}
                                                perimeters={perimeters}
                                                metric={'gender'}
                                                referenceStudies={referenceStudies}
                                            />
                                        </Grid>
                                        <Grid item md={12}>
                                            <IconPercentage
                                                icon={<LocalHotelIcon style={{ fontSize: 30 }} />}
                                                title={'🛌 Chambres PMR'}
                                                sitesPerPerimeterFiltered={sitesPerPerimeterFiltered}
                                                perimeters={perimeters}
                                                metric="pmr"
                                                referenceStudies={referenceStudies}
                                            />
                                        </Grid>
                                        <Grid item md={12}>
                                            <IconPercentage
                                                icon={<RecyclingIcon style={{ fontSize: 30 }} />}
                                                title={'🌱 Produits bio'}
                                                sitesPerPerimeterFiltered={sitesPerPerimeterFiltered}
                                                perimeters={perimeters}
                                                metric="bio"
                                                referenceStudies={referenceStudies}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={7}>
                                    <MapChart
                                        aggregatedSitesPerPerimeter={aggregatedSitesPerPerimeter}
                                        perimeters={perimeters}
                                    />
                                </Grid>
                            </>
                        </CSSTransition>
                        <Grid item md={12}>
                            <br />
                            <Title title="📊 Vision approfondie" style={{ marginBottom: 0 }} />
                        </Grid>

                        <Grid item xs={12}>
                            <BarChartComponent
                                sites={groupingUtils.groupBy ? aggregatedSitesPerPerimeter : totalSitesPerPerimeter}
                                groupBy={groupingUtils.groupBy}
                            />
                        </Grid>

                        <Grid item md={12}>
                            <ScatterComponent
                                hotels={aggregatedSitesPerPerimeter}
                                groupingUtils={groupingUtils}
                                reference={selectedStudy}
                                perimeters={perimeters}
                            />
                        </Grid>

                        <Grid item md={12}>
                            <br />
                            <Title title="🔎 Vision détaillée" style={{ marginTop: 0 }} />
                        </Grid>

                        <CSSTransition key={`sortable-grid-${hotels?.length}`} timeout={500} classNames="fade">
                            <Grid item md={12}>
                                <SortableDataGrid
                                    hotels={aggregatedSitesPerPerimeter}
                                    showPerimeterMarkers={perimeters.length > 1}
                                    setExportCsvMethod={(method) => {
                                        csvExportMethod.current = method
                                    }}
                                />
                            </Grid>
                        </CSSTransition>
                    </TransitionGroup>
                </Grid>
            </Container>
            <Footer />
        </>
    )
}
