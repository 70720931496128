import React, { useEffect, useState, useRef } from 'react'

export const Sidebar = ({ isOpen, onClose, children }) => {
    const sidebarRef = useRef()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                onClose()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [onClose])

    const sidebarStyle = {
        position: 'fixed',
        top: '0px',
        right: isOpen ? 0 : '-300px', 
        width: '400px',
        height: 'calc(100vh - 0px)',
        background: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        transition: 'right 0.3s ease-in-out',
        zIndex: 10000,
        padding: '20px',
    }

    return (
        <div style={sidebarStyle} ref={sidebarRef}>
            <div>{children}</div>
        </div>
    )
}
