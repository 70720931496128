export const extractFathers = (site, object, objects) => {
    let fatherIds = [object?.FatherId]
    let count = 0

    while (fatherIds?.indexOf(site?.ObjectId) === -1 && count < 10) {
        count += 1
        let find = objects?.find((el) => el?.ObjectId === fatherIds?.[fatherIds?.length - 1])

        if (find?.FatherId) fatherIds.push(find?.FatherId)
        else return fatherIds
    }
    return fatherIds
}
