import { FC, ReactNode } from 'react'
import { Container, Box, Typography, Button, ButtonProps } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import HomeIcon from '@mui/icons-material/Home'

interface FallbackProps {
    title: ReactNode
    icon?: ReactNode
    description: ReactNode
    actions?: ButtonProps[]
}

const hasPrevious = window.history.length > 1

const defaultActions = [
    {
        component: 'button',
        onClick: hasPrevious ? () => window.history.back() : undefined,
        variant: 'outlined',
        children: 'Précédente',
        startIcon: <ArrowBackIcon />,
        disabled: !hasPrevious,
        color: 'secondary',
    },
    {
        variant: 'outlined',
        color: 'primary',
        children: 'Accueil',
        to: '/',
        startIcon: <HomeIcon />,
        component: Link,
    },
] as ButtonProps[]

const Fallback: FC<FallbackProps> = ({ title, description, icon, actions = defaultActions }) => {
    return (
        <Container>
            <Box sx={{ textAlign: 'center', mt: 8 }}>
                <Typography
                    variant="h1"
                    gutterBottom
                    sx={{ display: ' flex', gap: 1, justifyContent: 'center', alignItems: 'center' }}
                >
                    {icon}
                    {title}
                </Typography>
                <Typography variant="subtitle1">{description}</Typography>
                {actions && (
                    <Box sx={{ mt: 4, display: 'flex', gap: 1, justifyContent: 'center' }}>
                        {actions.map((action, index) => (
                            <Button {...action} key={index} />
                        ))}
                    </Box>
                )}
            </Box>
        </Container>
    )
}

export default Fallback
