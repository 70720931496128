import { Card, CardContent, Grid } from '@mui/material'
import React, { useRef, useState } from 'react'
import GroupPic from '../../../assets/img/realtime/group.webp'

import useLocale from '../../../utils/locale/locale.hook'

import { GroupTelecommand } from './group-telecommand.component'

const GroupItem = ({ item, key, show, sensors }) => {
    const [showTelecommand, setShowTelecommand] = useState(false)
    const ref = useRef()
    const locale = useLocale()

    return (
        <Grid item xs={12} sm={4} md={3} key={`item_${item.name}`}>
            <Card
                key={`card_${item.name}`}
                ref={ref}
                style={{
                    cursor: 'pointer',
                    height: 100,
                    position: 'relative',
                }}
                className="card-hover"
                onClick={(e) => {
                    setShowTelecommand(true)
                }}
            >
                <img
                    src={GroupPic}
                    style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                    }}
                />

                <CardContent
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        zIndex: 10,
                        position: 'relative',
                        fontWeight: 'bold',
                        height: '100%',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            left: 10,
                            bottom: 0,
                            color: 'white',
                        }}
                    >
                        {item?.name?.toUpperCase()}
                    </div>
                </CardContent>
            </Card>
            {sensors?.length > 0 && showTelecommand && (
                <GroupTelecommand
                    showTelecommand={showTelecommand}
                    setShowTelecommand={setShowTelecommand}
                    item={item}
                    sensors={sensors?.filter((el) => item.objects?.indexOf(el.ObjectId) > -1)?.map((el) => el.SensorId)}
                />
            )}
        </Grid>
    )
}

export default GroupItem
