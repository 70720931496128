import React from 'react'

import { Chip } from '@mui/material'

const Dot = ({ pilot }) => {
    return (
        <div
            style={{
                width: '7px',
                height: '7px',
                borderRadius: '50%',
                marginLeft: 10,
            }}
            className={pilot ? 'pilot-on' : 'pilot-off'}
        />
    )
}

const PilotChip = ({ pilot }) => {
    if (pilot)
        return (
            <Chip
                avatar={<Dot pilot={pilot} />}
                style={{ background: '#CCFAE7', color: '#005C35', fontSize: 10 }}
                label={'Agrid'}
                size="small"
            >
                Agrid
            </Chip>
        )
    else
        return (
            <Chip
                size="small"
                label="Inactif"
                avatar={<Dot pilot={pilot} />}
                style={{ color: '#6E7177', fontSize: 10, background: '#F1F2F4' }}
            />
        )
}

export default PilotChip
