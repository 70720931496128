import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import moment from 'moment'
import * as React from 'react'
import Calendar from 'react-calendar'
import PublicHolidayImg from '../../../assets/img/other/public_holiday.jpg'

export const StaticDatePickerLandscape = ({ holidays, setHolidays }) => {
    const toggle = async (date) => {
        let new_holidays = [...holidays]
        let hours = Math.abs(date.getTimezoneOffset() / 60)
        date.setHours(hours)
        // date = moment(date).format("DD-MM-YYYY");
        if (
            new_holidays?.map((el) => moment(el.date).format('DD-MM-YYYY')).indexOf(moment(date).format('DD-MM-YYYY')) >
            -1
        ) {
            new_holidays = new_holidays?.filter(
                (el) => moment(el.date).format('DD-MM-YYYY') != moment(date).format('DD-MM-YYYY')
            )
        } else {
            new_holidays.push({ date, type: 'private_holiday' })
        }
        setHolidays(new_holidays)
    }

    return (
        <Grid container spacing={3}>
            <Grid
                item
                md={3}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
                sm={12}
                xs={12}
            >
                <div />
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    {/* <div style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "0.75rem" }}></div>
        <div style={{ fontSize: "2rem" }}>
          {moment().format("ddd, DD MMMM YYYY")}
        </div>
        <div></div> */}
                    <img src={PublicHolidayImg} style={{ borderRadius: '50%', height: 150, width: 150 }} />{' '}
                </Box>
                <div />
            </Grid>

            <Grid item md={9} sm={12} xs={12} style={{ minHeight: 350, display: 'flex', justifyContent: 'center' }}>
                <Calendar
                    onChange={toggle}
                    showDoubleView={true}
                    className="site-calendar"
                    next2Label={null}
                    prev2Label={null}
                    tileClassName={({ activeStartDate, date, view }) => {
                        return view === 'month' &&
                            holidays
                                ?.map((el) => moment(el.date).format('DD-MM-YYYY'))
                                ?.indexOf(moment(date).format('DD-MM-YYYY')) > -1
                            ? 'public-holiday'
                            : null
                    }}
                    formatShortWeekday={(locale, value) => ['D', 'L', 'M', 'M', 'J', 'V', 'S'][value.getDay()]}
                    formatMonth={(locale, date) => moment(date).format('MMM')}
                    formatMonthYear={(locale, date) => moment(date).format('MMM YYYY')}
                />
            </Grid>
            {/* <Grid item md={12}>
        <Alert severity="info">
          Ajoutez ou enlevez un jour non travaillé en cliquant sur la date, puis
          cliquez sur le bouton d'enregistrement.
        </Alert>
      </Grid> */}
        </Grid>
    )
}
