export const sumByMonth = (days: string[], data: number[]) => {
    if (days.length !== data.length) {
        throw new Error('days and data should have the same length')
    }
    const months: string[] = []
    const monthlyTotals: number[] = []
    days.forEach((day, index) => {
        const month = day.slice(0, 7)
        if (months[months.length - 1] !== month) {
            months.push(month)
            monthlyTotals.push(data[index])
        } else {
            monthlyTotals[monthlyTotals.length - 1] += data[index]
        }
    })
    return { months, monthlyTotals }
}
