import { Alert, Box, CircularProgress, Slider } from '@mui/material'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { deepGrey, electricGreen } from '../../../components/Basic/colors'
import { PageTitleComponent } from '../../../components/Basic/library'
import { getDataPoint, sendAction } from '../../../services/sensors.service'
import { insertAction } from '../../../utils/realtime.utils'

const OnOff = ({ drive, setDrive }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                borderRadius: 4,
                overflow: 'hidden',
                height: 40,
                width: 100,
                position: 'relative',
                cursor: 'pointer',
                // marginTop: 20,
            }}
        >
            <div
                style={{
                    background: drive === true ? electricGreen : '#eeeeee',
                    width: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: drive === true ? 'white' : deepGrey,
                    borderRight: '1px solid #bdbdbd',
                }}
                onClick={() => setDrive(true)}
            >
                ON
            </div>
            <div
                style={{
                    background: drive === false ? electricGreen : '#eeeeee',
                    width: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: drive === false ? 'white' : deepGrey,
                }}
                onClick={() => setDrive(false)}
            >
                OFF
            </div>
        </div>
    )
}

const style = {
    position: 'absolute',

    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
}
const marks = [
    {
        value: 20,
        label: '20°C',
    },
    {
        value: 25,
        label: '25°C',
    },
]

function valuetext(value) {
    return `${value}°C`
}

export const GroupTelecommand = ({ showTelecommand, setShowTelecommand, item, sensors }) => {
    const selectedSite = useSelector((state) => state.globalStore.selectedSite)
    const [min, setMin] = React.useState(null)
    const [max, setMax] = React.useState(null)
    const [setTemp, setSetTemp] = React.useState(null)
    const [drive, setDrive] = React.useState(null)
    const [driveActuator, setDriveActuator] = React.useState(null)
    const [setTempActuator, setSetTempActuator] = React.useState(null)
    const [measurement, setMeasurement] = React.useState(null)
    const [loading, setloading] = useState(false)
    const { width } = useSelector((state) => state.globalStore)

    React.useEffect(() => {
        init()
    }, [])
    const init = async () => {
        // if (sensors?.length > 0 && !measurement) {
        let res = await getDataPoint({
            hash: selectedSite?.Hash,
            sensors,
            objectId: item?.objects?.[0],
        })

        if (res?.measurement) {
            setMeasurement(res?.measurement)
            let find = res?.measurement?.find((el) => el.actuator?.DataTypeId === 228)?.actuator

            if (find) setDriveActuator(find)
            find = null
            find = res?.measurement?.find((el) => el.actuator?.DataTypeId === 226)?.actuator
            if (find) {
                setSetTempActuator(find)
                if (find?.Max) setMax(find?.Max)
                if (find?.Min) setMin(find?.Min)
            }
        }
        // }
    }
    const submit = async () => {
        setloading(true)
        let modifications = []
        if (driveActuator && drive !== null) {
            modifications.push({
                id: driveActuator.ActuatorId,
                value: drive ? 1 : 0,
                title: '',
                actuatorId: driveActuator.ActuatorId,
            })
        }
        if (setTempActuator && setTemp !== null) {
            modifications.push({
                id: setTempActuator.ActuatorId,
                value: setTemp,
                title: '',
                actuatorId: setTempActuator.ActuatorId,
            })
        }

        if (modifications?.length !== 0) {
            await sendAction({
                groupHashes: [item.hash],
                modifications,
                hash: selectedSite?.Hash,
                fromDashboard: true,
            })
            insertAction(modifications)
        }
        setloading(false)
        setShowTelecommand(false)
    }

    if (showTelecommand)
        return (
            <Modal
                open={showTelecommand}
                onClose={() => setShowTelecommand(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        ...style,
                        width: { xs: width - 20, sm: width - 20, md: 600 },
                        top: { xs: '10%', sm: '10%', md: '50%' },
                        left: { xs: 10, sm: 10, md: '50%' },
                        transform: { sm: '', md: 'translate(-50%, -50%)' },
                    }}
                    key={item?.hash}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: 10,
                        }}
                    >
                        <PageTitleComponent title={item?.name?.toUpperCase()} />
                        <div style={{ display: 'flex' }}>
                            {/* <AgridToggle
                disabled={true}
                checked={false}
                styles={{
                  label: {
                    color: "white",
                    fontSize: 12,
                    color: false ? "#00B86B" : "#B7BCC7",
                    marginRight: 10,
                  },
                }}
                position="left"
                label={false ? "Inactif" : "Actif"}
                onChange={async () => {}}
              /> */}
                            <OnOff drive={drive} setDrive={setDrive} />
                        </div>
                    </div>
                    <Alert severity="warning">
                        {`Les commandes seront transmises à l'ensemble des machines associeés à votre groupe.`}
                    </Alert>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Température désirée
                    </Typography>

                    <Slider
                        aria-label="Custom marks"
                        defaultValue={setTemp}
                        onChange={(e) => setSetTemp(e.target.value)}
                        getAriaValueText={valuetext}
                        step={1}
                        valueLabelDisplay="auto"
                        marks={
                            min && max
                                ? [{ value: min, label: `${min}°C` }, ...marks, { value: max, label: `${max}°C` }]
                                : marks
                        }
                        min={min || 16}
                        max={max || 32}
                    />

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {!loading ? (
                            <Button variant="contained" disabled={drive === null && setTemp === null} onClick={submit}>
                                Enregistrer
                            </Button>
                        ) : (
                            <CircularProgress />
                        )}
                    </div>
                </Box>
            </Modal>
        )
    else return null
}
