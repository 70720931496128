import React from 'react'
import { textBlue } from './colors'

export const TitleComponent = ({ title, style }) => {
    return (
        <h5 className="h3 mb-0" style={{ margin: 0, color: textBlue, ...style }}>
            {title}
        </h5>
    )
}

export const PageTitleComponent = ({ title, style }) => {
    return <h2 style={{ fontSize: 20, ...style }}>{title}</h2>
}

export const DescriptionComponent = ({ content, style }) => {
    return <p style={{ marginTop: 2, marginBottom: 2, color: textBlue, ...style }}>{content}</p>
}
