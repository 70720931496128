import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import ModalComponent from '../../../components/Global/modal.component'

export const UserPopUp = ({ setShowUserPopup }) => {
    return (
        <ModalComponent handleModalOutsideClick={setShowUserPopup}>
            <FormGroup>
                <FormControlLabel control={<Switch />} label="Dashboard" />
                <FormControlLabel control={<Switch />} label="Pilotage" />
                <FormControlLabel control={<Switch />} label="Documents" />
            </FormGroup>
        </ModalComponent>
    )
}
