import { Box, Card, CardContent } from '@mui/material'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deepBlue, linkBlue } from '../../../components/Basic/colors'
import DpeLabel from './dpeLabel.component'
import { formatNumber } from '../../../utils/global.utils'

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    )
}

const columns = [
    {
        field: 'name',
        headerName: 'Nom',
        width: 200,
        cellClassName: 'selectable',
    },
    {
        field: 'average',
        headerName: 'Consommation annuelle',
        flex: 1,
        renderCell: (params) => {
            if (!params?.value) return `-`
            return (
                <span>
                    {parseInt(params?.value)} kWhEP/m<sup>2</sup>
                </span>
            )
        },
    },
    {
        field: 'dpe',
        headerName: 'DPE',
        flex: 1,
        renderCell: (params) => {
            if (!params?.value) return `-`
            return <DpeLabel dpeClass={params.value} type="consumption" />
            // return `${parseInt(params?.value)} kWh/m2/an`;
        },
    },
    // {
    //   headerName: "Pourcentage d'économie par rapport à l'année de référence",
    //   field: "savings",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return `${parseInt(params?.value)} %`;
    //   },
    // },
    {
        headerName: 'Valeur de référence',
        field: 'refValue',
        flex: 1,
        renderCell: (params) => {
            if (!params?.value) return <div style={{ color: linkBlue, cursor: 'pointer', fontSize: 12 }}>editer</div>
            return `${formatNumber(parseInt(params?.value))} kWh/m2/an`
        },
    },
    {
        headerName: 'Année de référence',
        field: 'refYear',
        flex: 1,
        renderCell: (params) => {
            if (!params?.value) return <div style={{ color: linkBlue, cursor: 'pointer', fontSize: 12 }}>editer</div>
            return `${parseInt(params?.value)}`
        },
    },
]

export const GroupTable = ({ sites, onSelect }) => {
    const navigate = useNavigate()
    const currentPath = useSelector((state) => state.globalStore.path)

    const onCellClick = (params, event) => {
        if (params?.field == 'name') {
            navigate(`${currentPath}?site=${params.id}`)
            onSelect(params.id)
        } else if (params?.field == 'refYear' && !params?.row?.refYear) {
            navigate(`constraints?site=${params.id}`)
        } else if (params?.field == 'refValue' && !params?.row?.refValue) {
            navigate(`constraints?site=${params.id}`)
        }
    }

    return (
        <Card id="dashboard-group-table">
            <CardContent>
                <Box
                    sx={{
                        '& .selectable': {
                            cursor: 'pointer',
                            color: deepBlue,
                        },
                    }}
                >
                    <div style={{ height: 'auto', width: '100%' }}>
                        <DataGrid
                            rows={sites}
                            columns={columns}
                            components={{ Toolbar: CustomToolbar }}
                            localeText={{
                                toolbarExport: 'Exporter',
                                toolbarExportCSV: 'Au format CSV',
                                toolbarExportPrint: 'Imprimer',
                                labelRowsPerPage: 'Nombres de lignes par page',
                                columnMenuSortAsc: 'ASC',
                                columnMenuSortDesc: 'DESC',
                                columnMenuFilter: 'Filtre',
                                columnMenuShowColumns: 'Montrer colonne',
                                columnMenuHideColumn: 'Cacher',
                                columnMenuUnsort: 'Détrier',
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Nombres de lignes par page',
                                },
                            }}
                            sx={{
                                border: 0,
                            }}
                            autoHeight={true}
                            onCellClick={onCellClick}
                        />
                    </div>
                </Box>
            </CardContent>
        </Card>
    )
}
