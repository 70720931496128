/* eslint-disable @typescript-eslint/no-explicit-any */
import { CategoryFilterKey, SiteMetaData, PerimeterExtendedSiteData, Series, Metric, OccupancySubtypes } from '../types'
import sum from 'lodash/sum'
import mean from 'lodash/mean'
import groupBy from 'lodash/groupBy'
import uniq from 'lodash/uniq'
import sumBy from 'lodash/sumBy'
import countBy from 'lodash/countBy'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AggregationFunction = (data: any[]) => string | number

const aggregationFunctions: Record<string, AggregationFunction> = {
    sum: (data: number[]) => sum(data),
    mean: (data: number[]) => mean(data),
    ignore: () => '-',
    join: (data: string[]) => uniq(data).filter(Boolean).join(', '),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    first: (data: any[]) => data[0],
    count: (data: string[]) => JSON.stringify(countBy(data)),
}

const SEPARATOR = '|||'
const seriesTypesToId = (series: Pick<Series, 'type' | 'subType'>): string =>
    `${series.type}${SEPARATOR}${series.subType || ''}`

// TODO handle mean for occupancy
const aggregateSeries = (series: Series[][]): Series[] => {
    const groups = groupBy(series.flat(), (s) => seriesTypesToId(s))
    const aggregatedSeries: Series[] = []
    for (const group of Object.values(groups)) {
        const template = group[0]
        const isMean = template.subType === OccupancySubtypes.OCCUPANCY_RATE
        const valuesSum = isMean
            ? template.data.map((_, i) => mean(group.map((g) => g.data[i].value)))
            : template.data.map((_, i) => sumBy(group, (g) => g.data[i].value)) // assuming dates are the same

        const data = template.data.map((d, i) => {
            return {
                ...d,
                value: valuesSum[i],
            }
        })
        aggregatedSeries.push({
            ...template,
            data,
        })
    }

    return aggregatedSeries
}

const aggregateMetrics = (metrics: Metric[][]): Metric[] => {
    const groups = groupBy(metrics.flat(), (m) => m.key)
    const aggregatedMetrics: Metric[] = []
    for (const group of Object.values(groups)) {
        const template = group[0]
        const data = template.data.map((point, index) => ({
            year: point.year,
            value: sumBy(group, (g) => g.data[index].value),
        }))

        aggregatedMetrics.push({
            ...template,
            data,
        })
    }
    return aggregatedMetrics
}

const AGGREGATORS: Record<keyof SiteMetaData, AggregationFunction> = {
    id: aggregationFunctions.join,
    name: aggregationFunctions.ignore,
    city: aggregationFunctions.ignore,
    country: aggregationFunctions.join,
    countryCode: aggregationFunctions.ignore,
    latitude: aggregationFunctions.ignore,
    longitude: aggregationFunctions.ignore,
    brand: aggregationFunctions.join,
    subBrand: aggregationFunctions.join,
    fund: aggregationFunctions.join,
    financialPartners: aggregationFunctions.ignore,
    hotelGroups: aggregationFunctions.ignore,
    acquisitionDate: aggregationFunctions.ignore,
    assetStatus: aggregationFunctions.ignore,
    stars: aggregationFunctions.mean,
    surface: aggregationFunctions.sum,
    rooms: aggregationFunctions.sum,
}

const aggregateCategory = (sites: SiteMetaData[], key: string) => {
    try {
        return AGGREGATORS[key as keyof SiteMetaData](sites.map((site) => site[key as keyof SiteMetaData]))
    } catch (e) {
        return undefined
    }
}

const aggregateSites = (
    sites: PerimeterExtendedSiteData[]
): PerimeterExtendedSiteData & { countryCodes: Record<string, number> } => {
    if (!sites.length) {
        throw new Error('No sites to aggregate')
    }
    const metadata: SiteMetaData = Object.keys(sites[0]).reduce((acc, key) => {
        return {
            ...acc,
            [key]: aggregateCategory(sites, key),
        }
    }, {} as SiteMetaData)
    return {
        ...metadata,
        series: aggregateSeries(sites.map((site) => site.series)),
        metrics: aggregateMetrics(sites.map((site) => site.metrics)),
        dpe: sites[0].dpe,
        completionRate: mean(sites.map((site) => site.completionRate)),
        perimeter: sites[0].perimeter,
        perimeterIndex: sites[0].perimeterIndex,
        countryCodes: countBy(sites, 'countryCode') as Record<string, number>,
    }
}

export const groupAndAggregateSites = (
    sites: PerimeterExtendedSiteData[],
    by?: CategoryFilterKey
): PerimeterExtendedSiteData[] => {
    if (!sites.length) {
        return []
    }
    if (!by) {
        // aggregate all in one
        return [aggregateSites(sites)]
    }
    const groupedSites = groupBy(sites, by)
    return Object.entries(groupedSites)
        .map(([name, groupSites]) => ({
            ...aggregateSites(groupSites),
            name: name !== 'null' ? name : 'N/A',
        }))
        .sort((a, b) => b.name?.localeCompare(a.name))
}
