import React from 'react'
import { Container, CircularProgress } from '@mui/material'

const Loader = () => {
    return (
        <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 200 }}>
            <CircularProgress />
        </Container>
    )
}

export default Loader
