const parseRequiredEnvVar = (key: string): string => {
    const value = import.meta.env[key]
    if (!value || typeof value !== 'string') {
        throw new Error(`Missing required environment variable: ${key}`)
    }
    return value
}

interface Environment {
    readonly apiUrl: string
    readonly googleApiKey: string
}

export const environment: Environment = {
    apiUrl: parseRequiredEnvVar('VITE_API_URL'),
    googleApiKey: parseRequiredEnvVar('VITE_GOOGLE_API_KEY'),
}
