import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Menu from '@mui/material/Menu'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { linkBlue } from '../../../components/Basic/colors'

export default function NotificationMenu({ notifications }) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const selectedSite = useSelector((state) => state.globalStore.selectedSite)
    const open = Boolean(anchorEl)
    const navigate = useNavigate()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <AutoAwesomeMotionIcon style={{ color: 'white' }} />
            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                // style={{  }}
            >
                {notifications?.slice(0, 10)?.map((notification) => {
                    return (
                        <div
                            style={{
                                width: 250,
                                padding: 10,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    color: linkBlue,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    navigate(
                                        `/constraints?site=${selectedSite?.Hash}&objectId=${notification?.object?.ObjectId}`
                                    )
                                }}
                            >
                                {notification?.object?.Name}
                            </div>
                            <Chip
                                label={
                                    notification?.sensor?.DataTypeId === 226
                                        ? `${notification?.Value} °C`
                                        : `${notification?.Value === 0 ? 'OFF' : 'ON'}`
                                }
                                style={{ marginLeft: 5 }}
                            />
                        </div>
                    )
                })}
                {notifications?.length === 0 && <div style={{ padding: 10 }}>Pas de notifications pour le moment</div>}
            </Menu>
        </div>
    )
}
