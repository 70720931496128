import React, { useEffect, useState } from 'react'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import FunctionsIcon from '@mui/icons-material/Functions'
import HomeIcon from '@mui/icons-material/Home'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import SwipeRightIcon from '@mui/icons-material/SwipeRight'
import CloudIcon from '@mui/icons-material/Cloud'
import { Container, Grid, Skeleton } from '@mui/material'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { electricBlue, electricOrange } from '../../components/Basic/colors'
import { PageTitleComponent } from '../../components/Basic/library'
import { getDashboardV2 } from '../../services/data.service'
import { useSearchParams } from 'react-router-dom'
import {
    computeAutomatisation,
    computeAveragePilotedSetTemp,
    computeAverageSetTemp,
    computeAverageTemp,
    computeEnergySeries,
    computeLineChart,
    computeOnPercentage,
    formatTreemap,
} from '../../utils/dashboard.utils'
import { formatNumber } from '../../utils/global.utils'
import { BuildingChart } from './components/building-chart.component'
import { DetailsComponent } from './components/details.component'
import { EquipmentsTable } from './components/equipments.table'
import { Item } from './components/item.component'
import { ScatterComponent } from './components/scatter.component'
import { TreeComponent } from './components/tree.component'
import { WarningText } from './components/warning.text'
import { DashboardZone } from './dashboard.zone'
import min from 'lodash/min'
import max from 'lodash/max'
import EnergyConsumptions from './components/energyConsumptions'
import { SiteYearlyConsumptionsBarChart } from './components/energyConsumptions/YearlyConsumptionsBarChart'
import { useQuery } from '@tanstack/react-query'
import { useEnergyConsumptionsSeries } from './dataProviders/useEnergyConsumptions'
import PowerCurvesChart from './components/energyConsumptions/PowerCurvesChart'
import { useTranslation } from 'react-i18next'

const DashboardPage = () => {
    const { t } = useTranslation('connect', { keyPrefix: 'siteDashboard' })
    const calendarStore = useSelector((state) => state.calendarStore)
    const { selectedSite } = useSelector((state) => state.globalStore)
    const { objects } = useSelector((state) => state.globalStore)
    const [searchParams, setSearchParams] = useSearchParams()
    const [data, setData] = useState(null)
    const [treemap, setTreemap] = useState([])
    const [averageTmp, setAverageTmp] = useState(null)
    const [averageTmpOut, setAverageTmpOut] = useState(null)
    const [onPercentage, setOnPercentage] = useState(null)
    const [setTemp, setSetTemp] = useState(null)
    const [pilotedSetTemp, setPilotedSetTemp] = useState(null)
    const [automation, setAutomation] = useState(null)
    const [lineData, setLineData] = useState([])
    const [series, setSeries] = useState(null)
    const [piloted, setPiloted] = useState('-')
    const [meters, setMeters] = useState([])
    const [selectedMeter, setSelectedMeter] = useState(null)
    const [scatterData, setScatterData] = useState(null)
    const [initialized, setInitialized] = useState(false)
    const energyDisplay = 'consumption'
    const [scatterFilterPercentage, setScatterFilterPercentage] = useState(20)
    const [scatterFilterDelta, setScatterFilterDelta] = useState(2)

    const start = moment(calendarStore.start).format('YYYY-MM-DD')
    const end = moment(calendarStore.end).format('YYYY-MM-DD')
    const { data: queryData, isLoading } = useQuery({
        queryFn: () =>
            getDashboardV2(selectedSite?.Hash, {
                start,
                end,
            }),
        enabled: Boolean(selectedSite?.Hash),
        queryKey: ['dashboard', selectedSite?.Hash, start, end],
        staleTime: 1000 * 60 * 5,
    })
    const objectId = searchParams.get('objectId') ? parseInt(searchParams.get('objectId')) : null
    const { isLoading: energySeriesLoading, energySeries } = useEnergyConsumptionsSeries({
        siteId: selectedSite?.SiteId,
        startDate: start,
        endDate: end,
    })
    const totalConsumption = energySeries.series.reduce((sum, series) => sum + series.total, 0)

    useEffect(() => {
        if (selectedSite?.Hash && objects?.length > 0) {
            init(selectedSite?.Hash)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarStore.start, calendarStore.end, objects, isLoading, scatterFilterPercentage])

    useEffect(() => {
        updateChart()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMeter])

    const updateChart = () => {
        const selectedMeterSeries = energySeries.series.find((el) => el.pointId === selectedMeter?.Prm)

        const _formattedData =
            selectedMeterSeries?.data.map((v, index) => ({
                Date: energySeries.categories[index].split('-').reverse().join('-'),
                Value: v * 1000,
                Type: 'EA',
            })) || []

        let _series = computeEnergySeries(
            _formattedData,
            energyDisplay,
            selectedMeter,
            lineData?.filter(
                (el) =>
                    el?.label >= moment(calendarStore.start).format('YYYY-MM-DD') &&
                    el?.label <= moment(calendarStore.end).format('YYYY-MM-DD')
            )
        )
        setSeries(_series)
    }

    const init = async () => {
        reset()

        let res = queryData
        let _data = res?.data
        if (res?.meteoData) {
            const groupedByDate = {}

            // Step 2: Iterate through the initial array and group the data by date.
            res?.meteoData.forEach((item) => {
                // Extract just the date portion, ignoring the time
                const date = item.DateTime.split('T')[0]

                if (!groupedByDate[date]) {
                    groupedByDate[date] = {
                        totalTemperature: 0,
                        totalCount: 0,
                    }
                }

                groupedByDate[date].totalTemperature += item['2mTemperature'] - 273
                groupedByDate[date].totalCount++
            })

            // Step 3: Calculate the average for each grouped date.
            const averagedResults = []

            for (const date in groupedByDate) {
                averagedResults.push({
                    date: date,
                    averageTemperature: groupedByDate[date].totalTemperature / groupedByDate[date].totalCount,
                })
            }

            _data = _data?.map((el) => {
                // check if TOutMean is empty
                // if empty or if it equals 20, which is the default, then add the average temperature for that day
                if (!el?.TOutMean || el?.TOutMean == 20) {
                    const found = averagedResults.find((item) => item.date === moment(el?.Date).format('YYYY-MM-DD'))

                    if (found) {
                        el.TOutMean = found.averageTemperature
                    }
                }
                if (el?.EnergyConsumption == undefined || el?.EnergyConsumption == null) {
                    if (el?.OnPercentage && el?.SetTempMean && el?.TOutMean) {
                        if (el?.HeatDuration > 0) {
                            el.EnergyConsumption = el?.OnPercentage * (el?.SetTempMean - el?.TOutMean)
                        } else if (el?.CoolDuration > 0) {
                            el.EnergyConsumption = el?.OnPercentage * (-el?.SetTempMean + el?.TOutMean)
                        }
                    }
                }
                return el
            })
        }
        const _lineData = initialize(_data)

        if (energySeries.series?.length > 0) {
            const selectedMeterSeries = energySeries.series[0]

            const _formattedData =
                selectedMeterSeries?.data.map((v, index) => ({
                    Date: energySeries.categories[index].split('-').reverse().join('-'),
                    Value: v * 1000,
                    Type: 'EA',
                })) || []

            let _series = computeEnergySeries(_formattedData, energyDisplay, selectedMeter, _lineData)
            setSeries(_series)
            const _meters = energySeries.series
                .filter((s) => s.provider === 'ENEDIS')
                .map((s) => ({
                    Prm: s.pointId,
                    Pmax: s.pmaxKw,
                    PmaxUnit: 'kVA',
                }))
            setMeters(_meters)
            setSelectedMeter(_meters[0])
        } else {
            setSelectedMeter(null)
            let _series = computeEnergySeries(null, energyDisplay, null, _lineData)
            setSeries(_series)
        }

        setInitialized(true)
    }

    const initialize = (data) => {
        if (data?.length > 0) {
            setData(data)
            let t_m = formatTreemap(data)
            console.log('t_m', t_m)
            setTreemap(t_m)
            let _scatterData = Object.keys(t_m)
                ?.map((key) => {
                    return {
                        y: parseInt(parseFloat(t_m[key]?.TinMeanOn) * 10) / 10,
                        x: parseInt(parseFloat(t_m[key]?.SetTempMean) * 10) / 10,
                        name: t_m[key]?.ObjectName,
                        id: t_m[key]?.ObjectHash,
                        onPercentage: t_m[key]?.OnPercentage,
                    }
                })
                ?.filter((el) => {
                    return el?.onPercentage >= scatterFilterPercentage / 100
                })
            setScatterData(_scatterData.filter((el) => el?.x > 0 || el?.y > 0))
            let avg = computeAverageTemp(data, 'TinMean')
            setAverageTmp(avg)

            let avg_out = computeAverageTemp(data, 'TOutMean')
            console.log('xxxxxx', data)
            setAverageTmpOut(avg_out)

            let _onPercentage = computeOnPercentage(data)
            setOnPercentage(_onPercentage)
            let { _avg } = computeAverageSetTemp(data)
            setSetTemp(_avg)

            let _pilotedSetTemp = computeAveragePilotedSetTemp(data)
            setPilotedSetTemp(_pilotedSetTemp)
            let _automation = computeAutomatisation(data)
            setAutomation(_automation)

            let _piloted = ((data?.filter((el) => el?.IsPiloted)?.length / data?.length) * 100).toFixed(1)
            setPiloted(_piloted)
            let { lineChart } = computeLineChart(data, calendarStore.start, calendarStore.end)
            setLineData(lineChart)
            return lineChart
        } else {
            setData(data)
            return null
        }
    }

    useEffect(() => {}, [])

    const reset = () => {
        setInitialized(false)
        setData(null)
        setTreemap([])
        setAverageTmp(null)
        setOnPercentage(null)
        setSetTemp(null)
        setPilotedSetTemp(null)
        setMeters([])
        setSelectedMeter(null)
        setScatterData(null)
    }

    const showZone = (id) => {
        const match = objects?.find((el) => el?.ObjectHash === id)
        if (match) {
            setSearchParams((prev) => {
                const updatedParams = new URLSearchParams(prev.toString())
                updatedParams.set('objectId', match.ObjectId)
                return updatedParams
            })
        }
    }

    const displayedPeriodDurationDays = moment(calendarStore.end).diff(moment(calendarStore.start), 'hours') / 24

    const objectHash = objects?.find((el) => el?.ObjectId === objectId)?.ObjectHash
    const loading = isLoading || !queryData || data === null

    if (objectId && objectHash) {
        return (
            <DashboardZone
                objectData={{
                    lineData,
                    data,
                    hash: objectHash,
                    objectId,
                    objectHash,
                    siteHash: searchParams.get('site'),
                    siteAvg: {
                        averageTmp,
                        onPercentage,
                        setTemp,
                        pilotedSetTemp,
                        automation,
                        piloted,
                    },
                }}
            />
        )
    }

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item md={5} xs={12}>
                    {!selectedSite ? (
                        <Skeleton variant="rectangular" width={'100%'} height={300} />
                    ) : (
                        <DetailsComponent site={selectedSite} startDatePilot={queryData?.StartDatePilot} />
                    )}
                </Grid>

                <Grid item md={7} xs={12}>
                    {selectedSite?.SiteId ? (
                        <SiteYearlyConsumptionsBarChart siteId={selectedSite.SiteId} />
                    ) : (
                        <Skeleton variant="rectangular" width={'100%'} height={300} />
                    )}
                </Grid>

                <Grid item md={12} style={{ marginTop: 20 }}>
                    <PageTitleComponent title={t('selectedPeriod')} />
                </Grid>

                <Grid item md={2} sm={12} xs={12}>
                    {loading ? (
                        <Skeleton variant="rectangular" width={'100%'} height={140} />
                    ) : (
                        <Item
                            number={`${averageTmpOut ? averageTmpOut.toFixed(0) : '-'}`}
                            unit={t('indicators.extTemperature')}
                            chip={!averageTmpOut ? <WarningText text={t('indicators.noData')} /> : null}
                            icon={<CloudIcon style={{ color: '#cfd8dc' }} />}
                        />
                    )}
                </Grid>

                <Grid item md={2} sm={12} xs={12}>
                    {loading ? (
                        <Skeleton variant="rectangular" width={'100%'} height={140} />
                    ) : (
                        <Item
                            number={`${averageTmp ? averageTmp.toFixed(0) : '-'}`}
                            unit={t('indicators.measuredTemperature')}
                            chip={!averageTmp ? <WarningText text={t('indicators.noData')} /> : null}
                            icon={<HomeIcon style={{ color: '#448aff' }} />}
                        />
                    )}
                </Grid>

                <Grid item md={2} sm={12} xs={12}>
                    {loading ? (
                        <Skeleton variant="rectangular" width={'100%'} height={140} />
                    ) : (
                        <Item
                            number={`${setTemp ? setTemp.toFixed(0) : '-'}`}
                            unit={t('indicators.setpointTemperature')}
                            chip={!setTemp ? <WarningText text={t('indicators.noData')} /> : null}
                            icon={<SwipeRightIcon style={{ color: 'black' }} />}
                        />
                    )}
                </Grid>

                <Grid item md={2} sm={12} xs={12}>
                    {loading ? (
                        <Skeleton variant="rectangular" width={'100%'} height={140} />
                    ) : (
                        <Item
                            number={
                                onPercentage !== undefined && onPercentage !== null
                                    ? (parseFloat(onPercentage) * 100).toFixed(0)
                                    : '-'
                            }
                            unit={'% allumé'}
                            icon={<PowerSettingsNewIcon style={{ color: '#f06292' }} />}
                            chip={
                                onPercentage === undefined || onPercentage === null ? (
                                    <WarningText text={t('indicators.noData')} />
                                ) : null
                            }
                        />
                    )}
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    {loading ? (
                        <Skeleton variant="rectangular" width={'100%'} height={140} />
                    ) : (
                        <Item
                            number={
                                Object.keys(treemap)?.length > 0
                                    ? `∼${formatNumber((totalConsumption / Object.keys(treemap)?.length / displayedPeriodDurationDays)?.toFixed(1))} `
                                    : '-'
                            }
                            unit={'kWh/zone/jour'}
                            icon={<EnergySavingsLeafIcon style={{ color: electricBlue }} />}
                            chip={
                                totalConsumption === undefined || totalConsumption === 0 ? (
                                    <WarningText text={t('indicators.noData')} />
                                ) : totalConsumption ? (
                                    `Total sur la période: ${formatNumber(totalConsumption?.toFixed(0))} kWh`
                                ) : null
                            }
                        />
                    )}
                </Grid>
                {/* <Grid item md={3} sm={12} xs={12}>
                    {loading || energySeriesLoading ? (
                        <Skeleton variant="rectangular" width={'100%'} height={140} />
                    ) : (
                        <Item
                            number={
                                Object.keys(treemap)?.length > 0
                                    ? `∼${formatNumber((totalConsumption / Object.keys(treemap)?.length / displayedPeriodDurationDays)?.toFixed(1))}`
                                    : null
                            }
                            unit={'kWh/zone/jour'}
                            icon={<ZoomInIcon style={{ color: electricOrange }} />}
                            chip={
                                totalConsumption === undefined || totalConsumption === 0 ? (
                                    <WarningText text={t('indicators.noData')} />
                                ) : Object.keys(treemap)?.length == 0 ? (
                                    <WarningText text="pas de zones" />
                                ) : null
                            }
                        />
                    )}
                </Grid> */}
                {selectedSite && calendarStore && (
                    <Grid item xs={12}>
                        <EnergyConsumptions isLoading={energySeriesLoading} energySeries={energySeries} />
                    </Grid>
                )}
                <Grid item md={12} xs={12}>
                    {loading || energySeriesLoading ? (
                        <Skeleton variant="rectangular" width={'100%'} height={300} />
                    ) : (
                        <BuildingChart
                            series={series}
                            meters={meters}
                            selectedMeter={selectedMeter}
                            setSelectedMeter={setSelectedMeter}
                            energyDisplay={energyDisplay}
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <PowerCurvesChart siteId={selectedSite?.SiteId} startDate={start} endDate={end} />
                </Grid>

                <Grid item md={12} xs={12}>
                    {data?.length > 0 && treemap && Object.keys(treemap)?.length > 0 && initialized ? (
                        <TreeComponent
                            data={treemap}
                            siteHash={selectedSite?.Hash}
                            objects={objects}
                            setSelectedZone={showZone}
                            initialized={initialized}
                        />
                    ) : (
                        <Skeleton variant="rectangular" width={'100%'} height={500} />
                    )}
                </Grid>
                {scatterData !== null && initialized === true && (
                    <Grid item md={12} xs={12}>
                        <ScatterComponent
                            scatterData={scatterData}
                            series={[
                                {
                                    name: 'Espaces',
                                    color: 'rgba(119, 152, 191, .5)',
                                    data: scatterData,
                                    dataLabels: {
                                        enabled: true,
                                        formatter: function () {
                                            return this.point.name
                                        },
                                    },
                                },
                                {
                                    type: 'line',
                                    color: 'black',
                                    dashStyle: 'dot',
                                    marker: {
                                        symbol: 'circle', // Change to 'circle', 'square', or 'triangle'
                                    },
                                    width: 2,
                                    data: [
                                        {
                                            x: min(scatterData?.map((el) => Math.min(el?.x, el?.y))) - 0.5,
                                            y: min(scatterData?.map((el) => Math.min(el?.x, el?.y))) - 0.5,
                                        },
                                        {
                                            x: max(scatterData?.map((el) => Math.max(el?.x, el?.y))) + 0.5,
                                            y: max(scatterData?.map((el) => Math.max(el?.x, el?.y))) + 0.5,
                                        },
                                    ],
                                },
                                {
                                    type: 'line',
                                    color: 'lightgrey',
                                    dashStyle: 'dash',
                                    marker: {
                                        symbol: 'circle', // Change to 'circle', 'square', or 'triangle'
                                    },
                                    lineWidth: 1,
                                    data: [
                                        {
                                            x: Math.min(...scatterData?.map((el) => Math.min(el.x, el.y))) - 0.5,
                                            y:
                                                Math.min(...scatterData?.map((el) => Math.min(el.x, el.y))) -
                                                0.5 +
                                                scatterFilterDelta,
                                        },
                                        {
                                            x: Math.max(...scatterData?.map((el) => Math.max(el.x, el.y))) + 0.5,
                                            y:
                                                Math.max(...scatterData?.map((el) => Math.max(el.x, el.y))) +
                                                0.5 +
                                                scatterFilterDelta,
                                        },
                                    ],
                                },
                                {
                                    type: 'line',
                                    color: 'lightgrey',
                                    dashStyle: 'dash',
                                    marker: {
                                        symbol: 'circle', // Change to 'circle', 'square', or 'triangle'
                                    },
                                    lineWidth: 1,
                                    data: [
                                        {
                                            x: Math.min(...scatterData?.map((el) => Math.min(el.x, el.y))) - 0.5,
                                            y:
                                                Math.min(...scatterData?.map((el) => Math.min(el.x, el.y))) -
                                                0.5 -
                                                scatterFilterDelta,
                                        },
                                        {
                                            x: Math.max(...scatterData?.map((el) => Math.max(el.x, el.y))) + 0.5,
                                            y:
                                                Math.max(...scatterData?.map((el) => Math.max(el.x, el.y))) +
                                                0.5 -
                                                scatterFilterDelta,
                                        },
                                    ],
                                },
                            ]}
                            setSelectedZone={showZone}
                            scatterFilterPercentage={scatterFilterPercentage}
                            setScatterFilterPercentage={setScatterFilterPercentage}
                            scatterFilterDelta={scatterFilterDelta}
                            setScatterFilterDelta={setScatterFilterDelta}
                        />
                    </Grid>
                )}

                {data?.length > 0 && (
                    <Grid item md={12} sm={12} xs={12}>
                        <EquipmentsTable
                            rows={Object.keys(treemap)
                                ?.filter((key) => treemap[key]?.ObjectHash)
                                ?.map((key) => {
                                    return {
                                        id: treemap[key]?.ObjectHash,
                                        name: treemap[key]?.ObjectName,
                                        on: treemap[key]?.onCount ? parseInt(treemap[key]?.OnPercentage * 100) : 0,
                                        settemp: parseFloat(treemap[key]?.SetTempMean)?.toFixed(1),
                                        temp: parseFloat(treemap[key]?.TinMean)?.toFixed(1),
                                        mode:
                                            treemap[key]?.HeatDuration > treemap[key]?.CoolDuration
                                                ? 'HEAT'
                                                : treemap[key]?.HeatDuration < treemap[key]?.CoolDuration
                                                  ? 'COOL'
                                                  : 'AUTO',
                                    }
                                })}
                            setSelectedZone={showZone}
                        />
                    </Grid>
                )}
            </Grid>
        </Container>
    )
}

export default DashboardPage
