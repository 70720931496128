import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const createInitialState = () => {
    let storedCalendar = localStorage.getItem('calendar')
    let now = new Date()

    if (
        storedCalendar &&
        JSON.parse(storedCalendar)?.expiry &&
        parseInt(JSON.parse(storedCalendar)?.expiry) >= now.getTime()
    )
        return {
            start: moment().add(-1, 'weeks').startOf('week').toDate().toString(),
            end: moment().toDate().toString(),
            mode: 'week',
            ...JSON.parse(storedCalendar),
        }
    else
        return {
            start: moment().add(-1, 'weeks').startOf('week').add(1, 'days').toDate().toString(),
            end: moment().add(-1, 'weeks').endOf('week').add(1, 'days').toDate().toString(),
            mode: 'week',
        }
}

const CalendarSlice = createSlice({
    name: 'calendar',
    initialState: createInitialState(),
    reducers: {
        setCalendar: (state, { payload }) => {
            let now = new Date()

            localStorage.setItem('calendar', JSON.stringify({ ...payload, expiry: now.getTime() + 86400000 }))
            return payload
        },
        updateCalendar: (state, { payload }) => {
            let now = new Date()
            let out = {
                ...state,
                ...payload,
                expiry: now.getTime() + 86400000,
            }
            localStorage.setItem('calendar', JSON.stringify(out))

            return {
                ...out,
            }
        },
    },
})

export default CalendarSlice
