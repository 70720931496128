import { VFC } from 'react'
import { MenuItem, FormControl, InputLabel } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

interface YearPickerProps {
    years: number[]
    year: number
    setYear: (year: number) => void
    label: string
}

const YearPicker: VFC<YearPickerProps> = ({ years, year, setYear, label }) => {
    const handleChange = (event: SelectChangeEvent) => {
        setYear(parseInt(event.target.value))
    }
    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select value={`${year}`} onChange={handleChange} label={label} fullWidth>
                {years.map((y) => (
                    <MenuItem key={y} value={`${y}`}>
                        {y}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default YearPicker
