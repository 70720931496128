import { VFC } from 'react'
import { PerimeterFiltersMethods } from '../../types'
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@mui/material'
import PerimeterFilters from './perimeterFilters.component'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

interface EditFilterDialogProps extends PerimeterFiltersMethods {
    open: boolean
    onClose: () => void
    filterOptions: Record<string, string[]>
    yearOptions: number[]
}

const EditFilterDialog: VFC<EditFilterDialogProps> = ({
    open,
    onClose,
    perimeters,
    filterOptions,
    addPerimeter,
    removePerimeter,
    yearOptions,
}) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle variant="h6">Filtrer votre tableau de bord</DialogTitle>
            <DialogContent>
                {perimeters.map((perimeter, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
                            {index > 0 && (
                                <>
                                    <Typography variant="h6">Périmètre {index + 1}</Typography>
                                    <Button
                                        startIcon={<DeleteOutlineOutlinedIcon />}
                                        onClick={() => removePerimeter(index)}
                                    >
                                        Supprimer
                                    </Button>
                                </>
                            )}
                        </Box>
                        <PerimeterFilters {...perimeter} filterOptions={filterOptions} yearOptions={yearOptions} />
                    </Box>
                ))}
                <Button variant="outlined" sx={{ mt: 2 }} onClick={addPerimeter}>
                    Ajouter un périmètre
                </Button>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditFilterDialog
