import { VFC, ReactNode, Fragment, useState } from 'react'
import { PerimeterFilter } from '../../types'
import { Box, Typography, Chip, Menu, MenuItem, IconButton } from '@mui/material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined'
import ClearIcon from '@mui/icons-material/Clear'

interface PerimeterFiltersProps extends PerimeterFilter {
    title: ReactNode
    count: number
    yearOptions: number[]
    onDelete?: () => void
}

const YearEditableChip: VFC<{
    year: number
    yearOptions: number[]
    onChangeYear: (year: number) => void
    color?: string
}> = ({ year, yearOptions, onChangeYear, color }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const handleClose = () => setAnchorEl(null)
    return (
        <>
            <Chip
                label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {year}
                        <ExpandMoreOutlined fontSize="small" />
                    </Box>
                }
                size="small"
                sx={{ backgroundColor: color }}
                icon={<DateRangeIcon />}
                onClick={(e) => setAnchorEl(e.currentTarget)}
            />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {yearOptions.map((y) => (
                    <MenuItem
                        key={y}
                        onClick={() => {
                            onChangeYear(y)
                            handleClose()
                        }}
                    >
                        {y}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

const PerimeterFilters: VFC<PerimeterFiltersProps> = ({ title, count, perimeter, methods, yearOptions, onDelete }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.dark' }}>
                {title}
            </Typography>
            <Chip label={`${count} hôtels`} size="small" variant="outlined" />
            <YearEditableChip
                color={perimeter.color}
                year={perimeter.year}
                yearOptions={yearOptions}
                onChangeYear={(y: number) => methods.updatePerimeterYear(y)}
            />
            {perimeter.categoryFilters.map(({ key, values }) => (
                <Fragment key={key}>
                    {values.map((value) => (
                        <Chip
                            key={`${key}-${value}`}
                            label={value}
                            size="small"
                            sx={{ backgroundColor: perimeter.color }}
                            onDelete={() =>
                                methods.updateFilterValues(
                                    key,
                                    values.filter((v) => v !== value)
                                )
                            }
                        />
                    ))}
                </Fragment>
            ))}
            {onDelete && (
                <IconButton onClick={onDelete} size="small" title="Supprimer ce périmètre">
                    <ClearIcon fontSize="small" />
                </IconButton>
            )}
        </Box>
    )
}

export default PerimeterFilters
