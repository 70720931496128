import { post, get } from './utils.service'

export const getPmsGlobal = async (hash) => {
    const res = await get(`/pms/global`)
    return await res?.json()
}

export const registerPms = async (hash, body) => {
    const res = await post(`/pms/register/${hash}`, { ...body }, { SiteHash: hash })
    return await res?.json()
}
