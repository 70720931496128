import React from 'react'

import { Switch, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
    ({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    })
)

const AgridToggle = ({ checked, label, onChange, styles, position, disabled }) => {
    return (
        <div
            onClick={() => {
                if (!disabled) {
                    onChange(checked ? 0 : 1)
                }
            }}
            style={{
                //cursor: !disabled ? "pointer" : "",
                cursor: 'pointer',
                display: 'flex',
                flexDirection: position === 'left' ? 'row' : 'column',
                alignItems: position === 'left' ? 'center' : 'flex-start',
                zIndex: 10,
            }}
        >
            {label === '' ? null : (
                <Box
                    sx={{
                        display: 'flex',
                        margin: position === 'left' ? '0px' : ' 0px 0px  8px 0px',
                        //display: { xs: "none", sm: "none", md: "flex" },
                        //width: { xs: 0, sm: 0, md: 150 }
                    }}
                >
                    {
                        <div
                            style={{
                                color: checked ? '#00B86B' : '#B7BCC7',
                                fontSize: 8,
                                marginLeft: position === 'left' ? 0 : 3,
                                marginRight: position === 'left' ? 3 : 0,
                                ...styles?.label,
                            }}
                        >
                            {label ? label : label === '' ? '' : checked ? 'ON' : 'OFF'}
                        </div>
                    }
                </Box>
            )}

            <IOSSwitch sx={{ m: 1 }} checked={checked} disabled={disabled} />
        </div>
    )
}

export default AgridToggle
