import moment from 'moment'

export const getRollingYear = ({ nMonths = 12, offset = 0 }: { nMonths?: number; offset?: number } = {}) => {
    const endDate = moment()
        .add(offset - 1, 'months')
        .endOf('month')
    const startDate = endDate
        .clone()
        .subtract(nMonths - 1, 'months')
        .startOf('month')

    return {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
    }
}
