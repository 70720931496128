import React, { useState } from 'react'
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import { postConstraints } from '../../services/constraints.service'
import { isDemo } from '../../services/utils.service'
import { useSelector } from 'react-redux'

export const ForceResetComponent = ({ selectedObject, constraints, selectedSite }) => {
    const [open, setOpen] = useState(false)
    const me = useSelector((state) => state.meStore?.me)
    const [loading, setLoading] = useState(false)
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleConfirm = async () => {
        // Put your submit logic here
        setLoading(true)
        let object_id = selectedObject?.ObjectId
        let newConstraints = {}
        newConstraints[object_id] = { ...constraints[object_id] }
        console.log('newConstraints #1', newConstraints)
        newConstraints[object_id] = {
            ...newConstraints[object_id],
            constraintMax: 30,
            constraintMin: 18,
            constraintMinHeat: 16,
            constraintMaxHeat: 28,
            ephemerous: true,
            old_constraint: constraints[object_id],
            days: 1,
            pilot: 0,
        }

        if (selectedObject.Pilot !== newConstraints[object_id]?.pilot) {
            newConstraints[object_id]['old_constraint'] = {
                ...newConstraints[object_id]['old_constraint'],
                old_pilot: selectedObject.Pilot,
            }
        }

        console.log('newConstraints#2', newConstraints)

        await postConstraints(
            {
                constraints: newConstraints,
            },
            selectedSite.Hash
        )
        setLoading(false)
        setOpen(false)
    }

    if (isDemo(me)) return null

    return (
        <Box sx={{ marginRight: '10px' }}>
            <Button style={{ background: 'red', color: 'white' }} onClick={handleClickOpen}>
                Urgence
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"En cas d'urgence"}</DialogTitle>
                <DialogContent>
                    <Alert severity="warning">
                        Les bornes seront élargies. Le pilote sera désactivé. Ces modifications seront appliquées
                        pendant <b>24h</b> sur la zone.
                    </Alert>
                    <DialogContentText id="alert-dialog-description"></DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>J'annule</Button>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Button onClick={handleConfirm} autoFocus>
                            Je confirme
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    )
}
