import { Series, SiteData } from '../types'

const filterSeriesDataByYear = (series: Series, year: number): Series => ({
    ...series,
    data: series.data.filter((d) => d.date.startsWith(`${year}`)),
})

export const filterSiteDataByYear = (site: SiteData, year: number): SiteData => ({
    ...site,
    series: site.series.map((s) => filterSeriesDataByYear(s, year)),
})
