import SettingsIcon from '@mui/icons-material/Settings'
import { CircularProgress } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import * as React from 'react'

export default function BasicTable({ documents, setSelectedDocument, editable, documentLoader }) {
    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Ajout</TableCell>
                        <TableCell>Dernière édition</TableCell>
                        <TableCell>Document</TableCell>
                        {editable && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {documents?.length === 0 && documentLoader ? (
                        <CircularProgress />
                    ) : (
                        documents.map((row, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {moment(row.CreatedAt).format('DD-MM-YYYY')}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {moment(row.UpdatedAt).format('DD-MM-YYYY')}
                                </TableCell>
                                <TableCell>
                                    <a href={row.Path} style={{ color: '#03a9f4' }} target="_blank" rel="noreferrer">
                                        {row.Name}
                                    </a>
                                </TableCell>
                                {editable && (
                                    <TableCell align="right">
                                        <SettingsIcon
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setSelectedDocument(row)}
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
