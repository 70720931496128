import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { getEnergyConsumptions, getPowerCurves, getOverrunData, SiteConsumptionData } from '@/services/data.service'
import { formatSeries, FormattedSeriesData } from './utils/formatSeries'
export type { FormattedSeriesData }

interface UseEnergySeriesParams {
    startDate: string
    endDate: string
    siteId: number
}

type EnergySeriesMeta = SiteConsumptionData<'kWh'>['meters'][0]
export type EnergySeries = FormattedSeriesData<EnergySeriesMeta>

const formatMeterName = (meter: SiteConsumptionData<'kWh' | 'kW'>['meters'][0]) =>
    `${meter.provider} #${meter.pointId}` + (meter.pmaxKw ? ` (${meter.pmaxKw} kVA)` : '')

export const useEnergyConsumptionsSeries = ({ startDate, endDate, siteId }: UseEnergySeriesParams) => {
    const { data, ...query } = useQuery({
        queryKey: ['energyConsumptions', siteId, startDate, endDate],
        queryFn: () => getEnergyConsumptions(siteId, startDate, endDate),
        staleTime: Infinity,
        placeholderData: keepPreviousData,
        enabled: Boolean(siteId),
    })

    const energySeries: EnergySeries = formatSeries(
        data?.meters?.map((m) => ({ ...m, name: formatMeterName(m), points: m.points }))
    )

    return { ...query, energySeries }
}

type PowerSeriesMeta = SiteConsumptionData<'kW'>['meters'][0]
export type PowerSeries = FormattedSeriesData<PowerSeriesMeta>

export const usePowerSeries = ({ startDate, endDate, siteId }: UseEnergySeriesParams) => {
    const { isError, isLoading, data } = useQuery({
        queryKey: ['powerCurves', siteId, startDate, endDate],
        queryFn: () => getPowerCurves(siteId, startDate, endDate),
        staleTime: Infinity,
        enabled: Boolean(siteId),
    })
    const powerSeries: PowerSeries = formatSeries(
        data?.meters?.map((m) => ({ ...m, name: formatMeterName(m), points: m.points }))
    )
    const { data: overrunData } = useQuery({
        queryKey: ['overrunData', siteId, startDate, endDate],
        queryFn: () => getOverrunData(siteId, startDate, endDate),
        staleTime: Infinity,
        enabled: Boolean(siteId),
    })
    const overruns = overrunData?.meters?.filter((m) => m.overrunMinutes > 0) || []

    return { isLoading, isError, powerSeries, overruns }
}
