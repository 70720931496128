import { Alert, Box, Button, Card, CardContent, Grid } from '@mui/material'

import React, { useState } from 'react'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { linkBlue } from '../../components/Basic/colors'
import { ContactForm } from './components/contact.form'
import Tuto1 from '../../assets/img/help/tuto_1.png'
import Tuto3 from '../../assets/img/help/tuto_3.png'
import Tuto4 from '../../assets/img/help/tuto_4.png'
import Tuto5 from '../../assets/img/help/tuto_5.png'
import Tuto6 from '../../assets/img/help/tuto_6.png'
import Tuto7 from '../../assets/img/help/tuto_7.png'
import Tuto8 from '../../assets/img/help/tuto_8.png'
import Tuto9 from '../../assets/img/help/tuto_9.png'
import Tuto21 from '../../assets/img/help/tuto_2.1.png'
import Tuto22 from '../../assets/img/help/tuto_2.2.png'
import Tuto10 from '../../assets/img/help/tuto_10.png'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import { Container } from '@mui/system'
const questions = [
    {
        label: 'Mon client/locataire a froid',
        featured: true,
        description: `Sur certains modèles de télécommandes murales, les informations de température sont visibles alors que le système est éteint (climatisation / chauffage à l’arrêt).<br/>
      Il arrive donc que le client modifie les températures sur la télécommande murale et que ces changements ne soient pas pris en compte, simplement car le chauffage ou la climatisation sont éteints. <br/>
      Le client a-t-il pensé à allumer le chauffage et la climatisation (voyant ‘ON’ lumineux activé) ?`,
        id: 1,
    },
    {
        label: 'Changer de mode',
        featured: true,
        description:
            "Chez Agrid, nous ne changeons actuellement pas le mode de votre climatiseur. En effet, changer le mode n'est pas un geste anodin, car tous les climatiseurs liés au même moteur doivent avoir le même mode. <br/>Pour éviter d'endommager les moteurs, nous ne changeons pas le mode. Si votre mode a changé, Agrid n'est pas à l'initiative de cette modification et il est important de contacter votre mainteneur.",
        id: 2,
    },
    {
        label: "Faire plus d'économies",
        featured: true,
        description:
            "Il est toujours possible de faire encore plus d'économies. L'algorithme d'Agrid respecte les bornes de température que vous avez configurées. En modifiant celles-ci, vous permettez à notre intelligence artificielle de gagner en marge de manoeuvre et donc de faire encore plus d'économies. <br/>Pour cela, rendez-vous dans la section 'Configuration' de votre compte Agrid. Néanmoins il faut noter que la modification de ces bornes peut également avoir un impact sur le confort de vos clients/locataires.",
        id: 3,
    },
    {
        featured: true,
        label: 'Je ne sais pas avec quelle adresse mail me connecter ',
        description:
            'En tant qu’administrateur(trice), l’adresse e-mail a utilisé est communiquée par l’équipe AGRID. En tant qu’utilisateur, l’administrateur(trice) pourra ajouter votre adresse email depuis l’onglet « Configuration » sur la plateforme d’AGRID. Plus de détails en <span style="text-decoration:underline; cursor: pointer">cliquant ici.</span>',
        id: 4,
        click: (setSelectedTutorial) => {
            let find = tutos?.find((el) => el?.id == 't1')
            setSelectedTutorial(find)
        },
    },
    {
        label: 'Connexion - Je ne connais pas mon mot de passe ? ',
        description:
            'Vous pouvez, à tout moment, utiliser le bouton « mot de passe oublié ». Un nouveau mot de passe vous sera envoyé par e-mail. (Pour rappel, une double authentification a été mise en place de sorte qu’un code à 4 chiffres vous est envoyé à chaque fois qu’un nouvel appareil essaie de se connecter à votre compte).',
        id: 5,
    },
    {
        label: 'Configuration – Comment ajouter un utilisateur ? ',
        description:
            'En tant qu’administrateur(trice), vous pouvez ajouter (et supprimer) des utilisateurs directement depuis l’interface dans l’onglet « Configuration » puis « Configuration du site ». Choisissez le statut « Admin » pour autoriser une personne à ajouter ou supprimer des adresses emails, sinon choisissez « utilisateur » <br/>Plus de détails en <span style="text-decoration:underline; cursor: pointer">cliquant ici.</span>.',
        id: 6,
        click: (setSelectedTutorial) => {
            let find = tutos?.find((el) => el?.id == 't1')
            setSelectedTutorial(find)
        },
    },
    {
        label: 'Configuration – Je ne sais plus quelle sont les adresses emails autorisées à se connecter à l’interface ? ',
        description:
            'Il est possible de consulter à tout moment la liste des adresses emails paramétrées dans l’interface AGRID dans l’onglet « Configuration ». ',
        id: 7,
    },
    {
        label: 'Je ne parviens pas à changer une température depuis l’interface ? ',
        description:
            'Assurez-vous de procéder comme expliqué ici, vérifiez que vous avez bien cliqué sur le bouton « Enregistrer ». L’information peut mettre quelques secondes à apparaitre dans l’interface (la consigne est envoyée à votre système CVC et est confirmée avant d’être visible sur l’interface AGRID.). ',
        id: 8,
    },
    {
        label: 'Certaines températures ne « fonctionnent » pas ? ',
        description:
            'Seules les températures dans les bornes autorisées sont permises. Pour modifier les bornes de température pour une seule chambre, momentanément pour un client, ou pour toutes les chambres ; la procédure est expliquée ici. Si les bornes en mode chaud sont de 19°C – 23°C par exemple, une consigne à 26°C sera automatiquement corrigée à 23°C (dans les 5 minutes suivant la consigne). Pour avoir une consigne de température supérieure à 23°C, il est nécessaire de modifier la borne haute. ',
        id: 9,
    },
    {
        label: 'Je ne comprends pas une consigne passée automatiquement ? ',
        description:
            'Le pilote automatique d’AGRID donne des consignes visant à réduire la consommation énergétique en prenant en compte le confort de vos clients. Les consignes automatiques se situent entre les bornes de température paramétrées (procédure expliquée ici). Une consigne inférieure à la borne basse en mode chaud (par exemple 17°C pour une borne minimum à 19°C) ne sera pas corrigée par AGRID. Une correction dans ce cas-là engendrerait une hausse de la consommation par rapport à une demande venant d’un client ou d’un personnel de l’hôtel. (Cela est vrai également pour une consigne supérieure à la borne maximum en mode froid). ',
        id: 10,
    },
    {
        label: 'La température demandée n’est pas atteinte ? ',
        description:
            'Lorsque les températures à l’extérieur sont très basses en hiver ou très hautes en été, il est possible que vos systèmes CVC ne soient pas assez puissants pour atteindre la température souhaitée. Autrement dit, le système CVC peut-être sous dimensionné pour les conditions extrêmes. ',
        id: 11,
    },
    {
        label: 'La température ressentie n’est pas celle mesurée dans la pièce ? ',
        description:
            'Les températures mesurées dans les pièces proviennent des sondes de température dans les unités au plafond, de celles dans les télécommandes murales, ou bien des moyennes des deux. Il est possible que la température mesurée soit celle au niveau du plafond (ou dans le faux plafond le cas échéant). Dans ce cas, il est recommandé d’utiliser la sonde présente dans la télécommande murale. Il est également possible que les sondes de température ne soient pas correctement calibrées ou bien qu’elles soient défectueuses. Vous pouvez faire appel à votre mainteneur pour passer en revue le bon fonctionnement des sondes de température. ',
        id: 12,
    },
    {
        label: 'Un client se plaint de la température dans sa chambre ? ',
        description:
            'Plusieurs causes peuvent être à l’origine de cette plainte ; un sous-dimensionnement des systèmes ne permettant pas d’atteindre la température demandée, le client n’a pas démarré le chauffage ou la climatisation dans sa chambre (il doit s’assurer que le voyant sur la télécommande murale est bien allumé), enfin il ne souhaite pas que le pilote automatique AGRID modifie la température dans sa chambre. Dans ce dernier cas, nous conseillons de désactiver le pilote automatique pour sa chambre uniquement pendant la durée de son séjour. Le pilote automatique AGRID continuera à réduire la consommation énergétique dans le reste de l’établissement. <br/><span style="text-decoration: underline; cursor: pointer;">Cliquer ici</span> pour comprendre comment activer ou désactiver le pilote pour une zone ',
        id: 13,
        click: (setSelectedTutorial) => {
            let find = tutos?.find((el) => el?.id == 't9')
            setSelectedTutorial(find)
        },
    },
    {
        label: 'Un client me demande en quoi consiste AGRID ? ',
        description:
            'AGRID est le fruit de plusieurs années de R&D avec pour objectif de réduire l’impact carbone des bâtiments tertiaires. La solution a été développée et entrainée spécifiquement pour le secteur de l’hôtellerie dans le but d’éviter le gaspillage énergétique lié au chauffage et à la climatisation (poste de consommation énergétique le plus important). Pour illustrer ces propos, nous vous conseillons de prendre l’exemple du robinet d’eau. Lorsque l’on a terminé de se laver les mains, on ferme le robinet d’eau avant de quitter la salle de bain. Avec le chauffage et la climatisation, le plus souvent nous n’y pensons pas. Nous corrigeons ce problème en prenant également en compte la thermique des bâtiments, des modèles predictifs, etc. de sorte à optimiser l’usage des systèmes. ',
        id: 14,
    },
    {
        label: 'Mon système CVC ne semble plus fonctionner ? ',
        description:
            'Les systèmes de chauffage et de climatisation peuvent connaitre des défaillances indépendamment de la solution AGRID. Vous pouvez à tout moment (en cas de doute) débrancher le boitier AGRID pour vous assurer que le problème constaté n’a pas de lien avec le pilotage d’AGRID. Lorsque vous rebrancherez le boitier AGRID, le pilotage reprendra automatiquement et vous retrouverez l’accès à l’interface AGRID. ',
        id: 15,
    },
    {
        label: 'Quels sont les risques pour mes systèmes ? ',
        description:
            'La solution d’AGRID est qualifiée de « non intrusive », l’intelligence artificielle passe des consignes de températures et coupe le chauffage ou la climatisation comme les usagers peuvent le faire depuis les télécommandes murales. Les systèmes de CVC ne peuvent pas être endommagés par la solution AGRID. ',
        id: 16,
    },
    {
        label: 'L’interface AGRID ne semble plus fonctionner ? ',
        description:
            'Dans le cas où les services d’AGRID serait momentanément inaccessible, votre système CVC continue à fonctionner. Durant cette période et jusqu’à la remise en route du service, nous ne générons plus d’économies. Autrement dit, vous revenez à la situation antérieure à l’installation d’AGRID. Nous travaillons aussi vite que possible pour résoudre d’éventuelles complications qui pourraient rendre notre système inaccessible. N’hésitez pas à nous contacter pour avoir des informations si tel est le cas. ',
        id: 17,
    },
    {
        label: 'J’ai besoin d’aide, qui contacter ? ',
        description:
            'Pour les sujets liés à votre système CVC, votre mainteneur reste l’interlocuteur à contacter. Pour les sujets liés au pilotage ou aux outils AGRID, vous pouvez contacter l’équipe support@a-grid.com. ',
        id: 18,
    },
]

const tutos = [
    {
        id: 't1',
        title: 'Configuration de votre site',
        left: [
            {
                type: 'p',
                value: 'Renseigner la surface, les horaires de Check-Out et de Check-In.',
            },
            {
                type: 'p',
                value: 'Ajouter l’année et la consommation de référence par rapport à laquelle vous souhaitez mesurer les économies. (Nous recommandons celle utilisée dans le cadre du décret tertiaire lorsque le bâtiment est éligible).',
            },
            {
                type: 'p',
                value: 'Ajouter, dans la partie droite, les adresses emails des utilisateurs de l’outil AGRID.',
            },
        ],
        right: [{ type: 'img', value: Tuto1 }],
    },
    {
        id: 't2',
        title: 'Configuration des zones',
        left: [
            {
                type: 'p',
                value: 'Indiquer pour chacune des zones le type de pièce (« Espaces communs », « Chambres », « Espaces de restauration », etc.).',
            },
            {
                type: 'p',
                value: 'Pour cela, cliquer déposer dans la catégorie souhaitée. Ou bien, en cliquant comme suit :',
            },
            {
                type: 'img',
                value: Tuto21,
            },
        ],
        right: [{ type: 'img', value: Tuto22 }],
    },
    {
        id: 't3',
        title: "Configuration d'une zone spécifique",
        left: [
            {
                type: 'p',
                value: 'En cliquant sur une zone, il sera possible de: ',
            },
            {
                type: 'p',
                value: '- Modifier le nom de la zone si nécessaire (en cliquant sur le nom de la zone).',
            },
            {
                type: 'p',
                value: '- Définir les bornes de températures de confort (en cliquant sur les bornes de températures). Cool : température de confort en été. Heat : température de confort en hiver.',
            },
            {
                type: 'p',
                value: "- Indiquer les horaires d’ouverture. Vous pouvez étendre la plage de disponibilité en cliquant sur les flèches, ou bien copier une configuration existante en cliquant sur l'icône à côté du jour. ",
            },
        ],
        right: [{ type: 'img', value: Tuto3 }],
    },
    {
        id: 't4',
        title: 'Enregistrer pour une zone spécifique',
        left: [
            {
                type: 'p',
                value: "Plusieurs façon d'enregistrer vos modifications : ",
            },
            {
                type: 'p',
                value: '- Pour la zone uniquement :Utiliser cette option pour définir des réglages propres à une pièce. Cela peut être utilisé pour prendre en compte des attentes spécifiques de clients pendant son déjour par exemple.',
            },
            {
                type: 'p',
                value: '- Pour toutes les zones similaires  :Utiliser cette option pour répéter ces réglages automatiquement sur les autres zones. Ainsi, toutes les chambres pourront être paramétrées en un seul clic.',
            },
        ],
        right: [{ type: 'img', value: Tuto4 }],
    },
    {
        id: 't5',
        title: 'Création de groupes',
        left: [
            {
                type: 'p',
                value: 'Un groupe permet de regrouper plusieurs zones et de les piloter ensemble. ',
            },
            {
                type: 'p',
                value: 'La configuration pourra alors être enregistrée pour toutes les zones du groupe. Il sera également possible de passer des commandes au niveau du groupe (depuis l’onglet pilotage).',
            },
        ],
        right: [{ type: 'img', value: Tuto5 }],
    },
    {
        id: 't6',
        title: 'Activation du pilote automatique',
        left: [
            {
                type: 'p',
                value: 'Après une période d’observation d’environ un mois nécessaire pour que notre modèle thermique se calibre, il est possible d’activer le pilote automatique dans l’onglet « configuration du site ».',
            },
            {
                type: 'p',
                value: 'Cliquer sur « Démarrer le pilote ». Le pilote pourra, par la suite, être activé ou désactivé à tout moment en un seul clic. Le pilote est ensuite activé/désactivé zone par zone.',
            },
        ],
        right: [{ type: 'img', value: Tuto6 }],
    },
    {
        id: 't7',
        title: 'Activer/désactiver le pilote pour une zone',
        left: [
            {
                type: 'p',
                value: 'De la même manière que pour les autres réglages, il est possible d’enregistrer pour toutes les zones similaires, pour les zones du groupe, ou pour une zone uniquement.',
            },
            {
                type: 'p',
                value: 'Il est ainsi possible d’activer/désactiver très rapidement le pilote pour toutes les chambres par exemple. Ou bien, de le désactiver pour une seule chambre pendant le séjour d’un client en particulier.',
            },
        ],
        right: [{ type: 'img', value: Tuto7 }],
    },
    {
        id: 't8',
        title: 'Suivi en temps réel',
        left: [
            {
                type: 'p',
                value: 'L’onglet Pilotage permet et de voir en temps réel l’usage qui est fait des systèmes CVC (chauffage, ventilation et climatisation):',
            },
            {
                type: 'p',
                value: '- Pourcentage de machines en marche',
            },
            {
                type: 'p',
                value: '- Température moyenne mesurée',
            },
            {
                type: 'p',
                value: '- Température moyenne mesurée',
            },
            {
                type: 'p',
                value: 'Le bouton « Pilotage du site » permet de passer une consigne pour l’ensemble des zones. Sur chaque zone, il est possible de connaitre sa température de consigne, son mode de fonctionnement (chauffage, ventilation, climatisation), son état (allumé ou éteint) et si le pilote automatique est activé ou non.',
            },
            {
                type: 'p',
                value: "- L'état de la machine (allumé ou éteint) est indiqué par la couleur de la zone. La zone est verte si la machine est allumée, rouge si elle est éteinte.",
            },
            {
                type: 'p',
                value: '- Le pilotage automatique est indiqué par le nom Agrid en vert. Si le pilotage automatique est désactivé, le nom Agrid est en gris.',
            },
            {
                type: 'p',
                value: '- Le mode Heat est associé au pictogramme rouge et le mode Cool au pictogramme bleu.',
            },
        ],
        right: [{ type: 'img', value: Tuto8 }],
    },
    {
        id: 't9',
        title: 'Passer une consigne depuis l’interface',
        left: [
            {
                type: 'p',
                value: 'Il est possible d’avoir accès aux températures passées ainsi qu’à la température mesurée en temps réel dans la zone. ',
            },
            {
                type: 'p',
                value: "Sur l'interface vous avez accès à:",
            },
            {
                type: 'p',
                value: '- Température mesurée, ici 21.5°C',
            },
            {
                type: 'p',
                value: '- Température de consigne, ici 22°C',
            },
            {
                type: 'p',
                value: '- La vitesse de ventiation',
            },
            {
                type: 'p',
                value: '- Le statut de la climatisation (on/off)',
            },
            {
                type: 'p',
                value: "Vous pouvez modifier les valeurs précédentes directement sur l'interface et enregistrer avec le bouton Enregistrer. En revanche il n'est pas possible de changer le mode de fonctionnement de la climatisation (chaud, froid, etc).",
            },
        ],
        right: [{ type: 'img', value: Tuto9 }],
    },
    {
        id: 't9',
        title: "Procédure d'urgence",
        left: [
            {
                type: 'p',
                value: "Il est possible de changer rapidement les paramètres de votre site pour une durée limitée. Cette fonctionnalité est utile en cas d'urgence, par exemple si vous avez un besoin spécifique pour un client.",
            },
            {
                type: 'p',
                value: "Pendant 24h, nous élargissons les bornes lorsque cela est possible et nous désactivons le pilote automatique. Ainsi, vous pouvez passer des consignes plus élevées ou plus basses que d'habitude.",
            },
        ],
        right: [{ type: 'img', value: Tuto10 }],
    },
]

export const HelpPage = () => {
    const [value, setValue] = useState(null)
    const [isForm, setIsForm] = useState(false)
    const [selectedTutorial, setSelectedTutorial] = useState(null)
    return (
        <Container>
            {isForm ? (
                <ContactForm goBack={() => setIsForm(false)} />
            ) : selectedTutorial ? (
                <>
                    <div
                        style={{ color: linkBlue, marginLeft: 10, cursor: 'pointer' }}
                        onClick={() => setSelectedTutorial(null)}
                    >
                        Retour
                    </div>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: 20,
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <h2>{selectedTutorial.title}</h2>
                        <Box
                            style={{ display: 'flex', alignItems: 'center' }}
                            sx={{
                                width: {
                                    md: '50%',
                                    xs: '100%',
                                },
                            }}
                        ></Box>
                    </Box>
                    <Grid container spacing={2} style={{ marginTop: 20 }}>
                        <Grid item xs={12} md={4}>
                            {selectedTutorial?.left?.map((item, index) => {
                                if (item.type === 'h5') {
                                    return <h5>{item.value}</h5>
                                } else if (item.type === 'p') {
                                    return <p>{item.value}</p>
                                } else if (item.type === 'img') {
                                    return <img src={item.value} style={{ objectFit: 'contain', maxWidth: '80%' }} />
                                }
                            })}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {selectedTutorial?.right?.map((item, index) => {
                                if (item.type === 'h5') {
                                    return <h5>{item.value}</h5>
                                } else if (item.type === 'p') {
                                    return <p>{item.value}</p>
                                } else if (item.type === 'img') {
                                    return (
                                        <div
                                            style={{
                                                boxShadow: '2px 2px 5px rgba(0,0,0,0.3)',
                                                maxWidth: '90%',
                                                position: 'relative',
                                            }}
                                        >
                                            <img src={item.value} style={{ objectFit: 'cover', width: '100%' }} />
                                        </div>
                                    )
                                }
                            })}
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    {' '}
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: 20,
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <h4>Recherche par mot clé</h4>
                        <Box
                            style={{ display: 'flex', alignItems: 'center' }}
                            sx={{
                                width: {
                                    md: '50%',
                                    xs: '100%',
                                },
                            }}
                        >
                            <Autocomplete
                                value={value}
                                disablePortal
                                id="combo-box"
                                options={questions}
                                sx={{
                                    width: '100%',
                                    background: 'white',
                                }}
                                renderInput={(params) => <TextField {...params} label="Par ex: mode" />}
                                onChange={(event, newValue) => {
                                    setValue(newValue)
                                }}
                            />
                            {value && (
                                <div
                                    style={{
                                        color: linkBlue,
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setValue(null)}
                                >
                                    effacer
                                </div>
                            )}
                        </Box>
                    </Box>
                    <br />
                    <br />
                    {value ? (
                        <Card>
                            <CardContent>
                                <h3>{value.label}</h3>
                                <p
                                    dangerouslySetInnerHTML={{ __html: value.description }}
                                    onClick={() => {
                                        if ('click' in value) {
                                            value.click(setSelectedTutorial)
                                        }
                                    }}
                                />
                            </CardContent>
                        </Card>
                    ) : (
                        <>
                            <h2>Questions fréquentes</h2>
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <Alert
                                        severity="info"
                                        action={
                                            <Button color="inherit" size="small" onClick={() => setIsForm(true)}>
                                                Contactez-nous
                                            </Button>
                                        }
                                    >
                                        Vous ne trouvez pas la réponse à votre question ?
                                    </Alert>
                                </Grid>
                                {questions
                                    ?.filter((el) => el.featured)
                                    ?.map((el) => {
                                        return (
                                            <Grid item md={6} key={el.id}>
                                                <Card>
                                                    <CardContent
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setValue(el)
                                                        }}
                                                    >
                                                        <h3>{el.label}</h3>
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: el.description?.substring(0, 150) + '...',
                                                            }}
                                                        />
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        )
                                    })}
                            </Grid>

                            <h2 style={{ marginTop: 30 }}>Tutoriels</h2>
                            <Grid container spacing={2}>
                                {tutos?.map((el) => {
                                    return (
                                        <Grid item md={6} xs={12} key={el.id}>
                                            <Card
                                                onClick={() => {
                                                    setSelectedTutorial(el)
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <CardContent style={{ display: 'flex', alignItems: 'center' }}>
                                                    <LibraryBooksIcon />
                                                    <h3 style={{ marginLeft: 10 }}>{el.title}</h3>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </>
                    )}
                </>
            )}
        </Container>
    )
}
