import { VFC, useState, useEffect, RefObject } from 'react'
import { Box, useTheme } from '@mui/material'

const HeaderOffset: VFC<{ headerRef: RefObject<HTMLDivElement> | null; truc: string }> = ({ headerRef, truc }) => {
    const theme = useTheme()
    const [offset, setOffset] = useState(theme.mixins.toolbar.minHeight)
    useEffect(() => {
        if (headerRef?.current?.clientHeight) {
            setOffset(headerRef?.current?.clientHeight)
        }
    }, [headerRef, headerRef?.current?.clientHeight, truc])

    return <Box sx={{ height: offset }} />
}

export default HeaderOffset
