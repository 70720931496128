import React, { useEffect, useState } from 'react'
// nodejs library that concatenates classes
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import SaveIcon from '@mui/icons-material/Save'
import {
    Backdrop,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Container,
    Step,
    StepButton,
    StepLabel,
    Stepper,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { uploadFile } from '../../services/s3.service'
import { updateSite } from '../../services/settings.service'
import { getSiteDetails } from '../../services/site.service'
import { sleep } from '../../utils/global.utils'
import { checkSite, checkWorkers } from '../../utils/settings.utils'
import DocumentsWrapper from './components/documents.wrapper'
import ManagerFormComponent from './components/manager.form'
import SiteFormComponent from './components/site.form.component'

const OnboardingPage = () => {
    const [sitePicture, setSitePicture] = useState(null)
    const width = useSelector((state) => state.globalStore.width)
    const steps = ['Site', 'Prestataires', 'Documents']
    const [site, setSite] = useState({
        Site_Type_Id: 1,
        LegalEntity: 'SAS',
    })
    const [activeStep, setActiveStep] = useState(0)
    const [completed, setCompleted] = useState({})
    const [workers, setWorkers] = useState([
        { WorkerType: 'installer' },
        { WorkerType: 'maintainer' },
        { WorkerType: 'dsi' },
    ])
    const [submitting, setSubmitting] = useState(null)
    const [errors, setErrors] = useState([])
    const [documents, setDocuments] = useState([])
    const [types, setTypes] = useState([])

    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    let query = useQuery()

    const handleNext = async () => {
        setActiveStep((prevActiveStep) => {
            setErrors([])
            let output = prevActiveStep
            if (prevActiveStep === 0) {
                let error = checkSite(site)
                if (error?.length > 0) {
                    setErrors(error)
                } else {
                    save()
                    output = prevActiveStep + 1
                }
            } else if (prevActiveStep === 1) {
                let error = checkWorkers(workers)
                if (error?.length > 0) {
                    setErrors(error)
                } else {
                    save()
                    output = prevActiveStep + 1
                }
            } else {
                save()
                output = prevActiveStep + 1
            }
            return output
        })
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleStep = (step) => () => {
        setActiveStep(step)
    }

    const changeSite = (key, value) => {
        let newSite = { ...site }
        newSite[key] = value

        setSite({ ...newSite })
    }

    const changeAddress = (val) => {
        let newSite = { ...site }
        newSite['Address'] = val?.Address || ''
        newSite['Latitude'] = val?.Latitude || ''
        newSite['Longitude'] = val?.Longitude || ''
        newSite['ZipCode'] = val?.ZipCode || ''
        newSite['City'] = val?.City || ''
        setSite({ ...newSite })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        let hash = query.get('site')
        let key = query.get('key')
        if (hash) {
            const res = await getSiteDetails(hash, key)
            if (res?.statusCode === 403 || !res?.site) window.location.href = '/'
            setWorkers([
                { WorkerType: 'installer', ...res?.installer },
                { WorkerType: 'maintainer', ...res?.maintainer },
                { WorkerType: 'dsi', ...res?.computerSystemMaintainer },
            ])
            setSite(res?.site)
            setDocuments(res?.documents)
            setTypes(res?.siteTypes)
        }
    }

    const save = async () => {
        let hash = query.get('site')
        let newSite = { ...site }
        if (activeStep === 0 && sitePicture !== null) {
            let path = await uploadFile(sitePicture)
            newSite.PictureUrl = path
        }
        // if (!hash) {
        //   let response = await createSite({
        //     Site_Type_Id: 1,
        //     ...newSite,
        //     workers,
        //     documents,
        //   });
        //   hash = response.hash;
        // }
        const res = await updateSite({ site: newSite, workers, documents }, hash)
        setSite({ ...newSite })
        return res
    }

    const submit = async () => {
        setSubmitting(true)

        for (let i = 0; i < documents.length; i++) {
            let doc = documents[i]
            //doc.Name = doc.name;
            if (!doc.DocumentId) {
                let path = await uploadFile(doc)
                doc.Path = path
            }
        }
        //setSubmitting("Mise à jour des informations");
        await save()
        await sleep(1000)
        await sleep(1000)
        setSubmitting(null)
        window.location.reload()
        //props.setShowAlert(res.success);
    }

    useEffect(() => {
        checkCompletion()
    }, [workers, site])

    const checkCompletion = () => {
        let completion = {}
        if (site?.Name && site?.Address) {
            completion[0] = true
        }
        setCompleted(completion)
    }
    return (
        <Container
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: 100,
                paddingBottom: 100,
                flexDirection: 'column',
            }}
        >
            {submitting && (
                <Backdrop open={true} style={{ color: 'white', zIndex: 10 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            {width > 700 && (
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    nonLinear
                    style={{ maxWidth: 700, width: 700, marginBottom: 40 }}
                >
                    {steps.map((label, index) => (
                        <Step key={label}>
                            {completed[index] ? (
                                <StepButton onClick={handleStep(index)} completed={completed[index]}>
                                    {label}
                                </StepButton>
                            ) : (
                                <StepLabel>{label}</StepLabel>
                            )}
                            {/* <StepLabel>{label}</StepLabel> */}
                        </Step>
                    ))}
                </Stepper>
            )}
            <Card>
                <CardContent>
                    <CardActions>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                color: 'white',
                                width: '100%',
                            }}
                        >
                            <div>
                                {activeStep !== 0 && (
                                    <>
                                        {width < 700 ? (
                                            <NavigateBeforeIcon
                                                onClick={handleBack}
                                                style={{ padding: 4, fontSize: 40 }}
                                            />
                                        ) : (
                                            <Button onClick={handleBack}>Précédent</Button>
                                        )}
                                    </>
                                )}
                            </div>
                            <div>
                                {activeStep !== steps.length - 1 && (
                                    <>
                                        {width < 700 ? (
                                            <NavigateNextIcon
                                                onClick={handleNext}
                                                style={{ padding: 4, fontSize: 40 }}
                                            />
                                        ) : (
                                            <Button onClick={handleNext}>Suivant</Button>
                                        )}
                                    </>
                                )}
                                {activeStep === steps.length - 1 ? (
                                    <>
                                        {' '}
                                        {width < 700 ? (
                                            <SaveIcon onClick={submit} />
                                        ) : (
                                            <Button style={{ color: 'white' }} onClick={submit} disabled={submitting}>
                                                Enregistrer
                                            </Button>
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </CardActions>
                    <Container style={{ paddingTop: 15 }}>
                        {activeStep === 0 && (
                            <SiteFormComponent
                                site={site}
                                changeSite={changeSite}
                                changeAddress={changeAddress}
                                errors={errors}
                                setSitePicture={setSitePicture}
                                types={types}
                            />
                        )}
                        {activeStep === 1 && (
                            <ManagerFormComponent
                                site={site}
                                workers={workers}
                                setWorkers={setWorkers}
                                errors={errors}
                            />
                        )}

                        {activeStep === 2 && <DocumentsWrapper setDocuments={setDocuments} documents={documents} />}
                        {/* {activeStep === 4 && (
            <ElecFormComponent site={site} changeSite={changeSite} />
          )} */}
                    </Container>

                    {/* <div style={{ height: 10, color: "red" }}>{errors}</div> */}
                </CardContent>
            </Card>
        </Container>
    )
}

export default OnboardingPage
