import { VFC } from 'react'
import { Stack } from '@mui/material'
import { CATEGORY_FILTERS, PerimeterFilter } from '../../types'
import CategoryFilter from './categoryFilter.component'
import YearPicker from './yearPicker.component'

export interface PerimeterFiltersProps extends PerimeterFilter {
    filterOptions: Record<string, string[]>
    yearOptions: number[]
}

const PerimeterFilters: VFC<PerimeterFiltersProps> = ({ filterOptions, perimeter, methods, yearOptions }) => {
    const { categoryFilters, year } = perimeter

    return (
        <Stack sx={{ mt: 1 }} spacing={2}>
            {CATEGORY_FILTERS.map((key) => (
                <CategoryFilter
                    title={key}
                    key={key}
                    values={(categoryFilters.find((filter) => filter.key === key)?.values as string[]) || []}
                    setValues={(newValues: string[]) => methods.updateFilterValues(key, newValues)}
                    options={filterOptions[key] || []}
                />
            ))}
            <YearPicker
                years={yearOptions}
                year={year}
                setYear={(newYear) => methods.updatePerimeterYear(newYear)}
                label="Année"
            />
        </Stack>
    )
}

export default PerimeterFilters
