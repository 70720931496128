import { createTheme, ThemeOptions } from '@mui/material'

const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: '#075985',
            light: '#0284C7',
            dark: '#044060',
        },
        secondary: {
            main: '#E33E7F',
            light: '#E86498',
            dark: '#9E2B58',
        },
        grey: {
            50: '#f8fafc',
            100: '#f1f5f9',
            200: '#e2e8f0',
            300: '#cbd5e1',
            400: '#94a3b8',
            500: '#64748b',
            600: '#475569',
            700: '#334155',
            800: '#1e293b',
            900: '#0f172a',
        },
    },
    typography: {
        fontFamily: 'Plus Jakarta Sans, sans-serif',
        body1: {
            // Figma : text-base
            fontSize: '16px',
            lineHeight: '24px',
        },
        body2: {
            // Figma : text-sm
            fontSize: '14px',
            lineHeight: '20px',
        },
        caption: {
            // Figma : text-xs
            fontSize: '12px',
            lineHeight: '16px',
        },
        subtitle1: {
            // Figma : text-lg
            fontSize: '18px',
            lineHeight: '28px',
        },
        h6: {
            // Figma : text-xl
            fontSize: '20px',
            lineHeight: '28px',
            fontWeight: 'bold',
        },
        h5: {
            // Figma : text-2xl
            fontSize: '24px',
            lineHeight: '32px',
            fontWeight: 'bold',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
}

export const theme = createTheme(themeOptions)
