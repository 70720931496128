import { useSelector } from 'react-redux'

const useType = () => {
    const me = useSelector((state) => state.meStore?.me)
    const { selectedSite } = useSelector((state) => state.globalStore)
    let type = me?.sites?.find((el) => el.Hash === selectedSite?.Hash)?.UserRole

    if (type === 'agrid') return 'admin'
    return type
}

export default useType
