import React from 'react'
import { Box, TextField, MenuItem } from '@mui/material'
import { Autocomplete } from '@mui/material'
import NavSelect from './navSelect'

const SiteSelector = (props) => {
    const { siteHash, handleChangeSite, showObject, me } = props

    const sortedSites = me?.sites?.sort((a, b) => a.name?.localeCompare(b.name))

    const siteItems = sortedSites?.map(({ name, hash }) => ({
        label: name,
        value: hash,
    }))
    const handleSiteChange = (event, value) => {
        if (value) {
            console.log('value ->', value)
            handleChangeSite(value?.value)
        }
    }

    return (
        <Box
            sx={{
                display: {
                    xs: 'block',
                    sm: 'block',
                    md: 'block',
                },
            }}
            id="sites-select"
        >
            {siteItems?.length > 5 ? (
                <Autocomplete
                    disabled={showObject}
                    options={siteItems}
                    getOptionLabel={(option) => option.label}
                    disableClearable
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Select a site"
                            sx={{
                                width: 300,
                                maxHeight: 50,
                                backgroundColor: 'white',
                                borderRadius: 1,
                                fontSize: 'inherit',
                               
                                '& .MuiAutocomplete-popupIndicator': {
                                    color: 'black',
                                },
                            }}
                        />
                    )}
                    onChange={handleSiteChange}
                    value={siteItems.find((item) => item.value === siteHash) || null}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                />
            ) : (
                <NavSelect
                    value={siteHash}
                    onChange={handleChangeSite}
                    disabled={showObject}
                    sx={{
                        color: 'common.white',
                        fontSize: 'inherit',
                        '&: Mui-disabled': {
                            color: 'common.white',
                        },
                        border: 'none',
                        '&: focus': {
                            outline: 'none',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                        outline: 'none',
                        '& .MuiSelect-icon': {
                            color: 'inherit',
                        },
                        '& .MuiInputBase-input': {
                            px: 0,
                            py: 0.5,
                        },
                    }}
                >
                    {sortedSites?.map(({ name, hash }) => (
                        <MenuItem key={hash} value={hash}>
                            {name}
                        </MenuItem>
                    ))}
                </NavSelect>
            )}
        </Box>
    )
}

export default SiteSelector
