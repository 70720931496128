import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import useLocale from '../../../utils/locale/locale.hook'
import { useSelector } from 'react-redux'

export default function SaveMenu({ title, saveModifications, items }) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const me = useSelector((state) => state.meStore?.me)

    const open = Boolean(anchorEl)
    const locale = useLocale()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (hash) => {
        setAnchorEl(null)
        saveModifications(hash)
    }

    return (
        <div>
            <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                disableElevation
                style={{ color: 'white', border: '1px solid white' }}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {title}
            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => handleClose('zone')}>
                    <div style={{ fontSize: 14 }}>{locale?.['realTime']?.['saveZone']}</div>
                </MenuItem>

                <MenuItem onClick={() => handleClose('ephemerous')}>
                    <div style={{ fontSize: 14 }}>Enregistrer pour quelques jours</div>
                </MenuItem>

                <MenuItem onClick={() => handleClose('space_type')}>
                    <div style={{ fontSize: 14 }}>Enregistrer pour toutes les zones similaires</div>
                </MenuItem>
                {items?.map((el) => {
                    return (
                        <MenuItem onClick={() => handleClose(el?.hash)}>
                            <div style={{ fontSize: 14 }}>
                                {locale?.['realTime']?.['saveGroup']}

                                <b style={{ marginLeft: 5 }}>{el.name}</b>
                            </div>
                        </MenuItem>
                    )
                })}
            </Menu>
        </div>
    )
}
