import React, { useState } from 'react'
import Calendar from 'react-calendar'
import '../../assets/css/calendar.css'
import { useSelector, useDispatch } from 'react-redux'
import CalendarSlice from '../../store/slices/calendar.slice'
import moment from 'moment'
import { event } from '../../utils/ga.utils'

const CalendarComponent = (props) => {
    const calendarStore = useSelector((state) => state.calendarStore)
    const [start, setStart] = useState(calendarStore.start)
    const [end, setEnd] = useState(calendarStore.end)
    const dispatch = useDispatch()
    React.useEffect(() => {
        if (calendarStore.start) setStart(calendarStore.start)
        if (calendarStore.end) setEnd(calendarStore.end)
    }, [calendarStore])

    return (
        <Calendar
            className="agrid-calendar"
            onChange={(data) => {
                event('Calendar', `Changed dates: ${data[0].toString()} / ${data[1].toString()}`)
                let momentStart = moment(data[0]).toDate().toString()
                let momentEnd = moment(data[1]).toDate().toString()
                dispatch(
                    CalendarSlice.actions.updateCalendar({
                        start: momentStart,
                        end: momentEnd,
                    })
                )
                props.onChange({ start: momentStart, end: momentEnd })
            }}
            onClickMonth={(value) => {
                let momentStart = moment(value).startOf('month').toDate().toString()
                let momentEnd = moment(value).endOf('month').toDate().toString()
                dispatch(
                    CalendarSlice.actions.updateCalendar({
                        start: momentStart,
                        end: momentEnd,
                    })
                )
                props.onChange({ start: momentStart, end: momentEnd })
            }}
            key={`${new Date(start)}-${new Date(end)}`}
            returnValue={'range'}
            value={[new Date(start), new Date(end)]}
            selectRange={true}
            defaultView="month"
            next2Label={null}
            prev2Label={null}
            maxDate={new Date()}
            formatShortWeekday={(locale, value) => ['D', 'L', 'M', 'M', 'J', 'V', 'S'][value.getDay()]}
        />
    )
}

export default CalendarComponent
