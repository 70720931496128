import Covivio from '../../../assets/img/brand/covivio.png'
import Agrid from '../../../assets/img/brand/logo.png';
import Oid from '../../../assets/img/brand/oid.png';
const generateHotels = () => {
    const hotels = []
    const fonds = ['A', 'B', 'C', 'D']
    const stars = ['1', '2', '3', '4', '5']
    for (let i = 0; i < 50; i++) {
        let randomFond = fonds[Math.floor(Math.random() * fonds.length)]
        let randomStars = stars[Math.floor(Math.random() * stars.length)]
        let surface = parseInt(Math.random() * 10000)
        hotels.push({
            name: `Hôtel ${i}`,
            tags: [
                {
                    type: 'other',
                    name: `Fond ${randomFond}`,
                },
                {
                    type: 'stars',
                    name: randomStars,
                },
            ],

            id: i,
            adresse: '123 Main St',
            surface: surface,
            occupation: 50,
            travaux: Math.random() * 10000,
            chambres: Math.random() * 100,
            consoBrute: surface * Math.random() * 100,
            consoSource: Math.random() * 10000,
            consoTempCorr: Math.random() * 10000,
            consoM2: Math.random() * 5,
            consoChambre: Math.random() * 200,
            dpe: 'C',
            ges: '50 kg',
            latitude: 48.8566 + Math.random() * 0.1,
            longitude: 2.3522 + Math.random() * 0.1,
            SiteId: i,
        })
    }
    return hotels
}

export const hotels = generateHotels()

export const averages = {
    oid: {
        energy: 200,
        water: 0.4,
        co2: 50,
        img: Oid,
    },
    covivio: {
        energy: 198,
        water: 0.45,
        co2: 54,
        img: Covivio,
    },
    agrid: {
        energy: 210,
        water: 0.5,
        co2: 60,
        img: Agrid,
    },
}

export const colors = {
    neutral: 'rgb(103, 115, 137)',
    darken: 'rgb(26,63,93)',
}
