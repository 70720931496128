import { forwardRef } from 'react'
import { AppBar, Box, Container, Toolbar as MuiToolbar } from '@mui/material'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'

const Header = forwardRef<HTMLDivElement>(({ children }, ref) => {
    return (
        <AppBar
            position="fixed"
            ref={ref}
            sx={(theme) => ({
                py: 2,
                background: theme.palette.background.default,
                borderBottom: `1px solid ${theme.palette.grey[200]}`,
                color: 'text.secondary',
            })}
            elevation={0}
        >
            <MuiToolbar>
                <Container>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <img
                            src="https://extendam.com/wp-content/uploads/2019/08/logo_extendam.png"
                            alt="logo Extendam"
                            style={{ width: '140px' }}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <SettingsOutlinedIcon />
                            <AccountCircleOutlinedIcon />
                        </Box>
                    </Box>
                    {children}
                </Container>
            </MuiToolbar>
        </AppBar>
    )
})

Header.displayName = 'Header'

export default Header
