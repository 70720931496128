import useHighcharts from './useHighcharts'
import { useRef } from 'react'
import HighchartsReact, { HighchartsReactProps } from 'highcharts-react-official'
import { ErrorBoundary } from 'react-error-boundary'
import { Typography } from '@mui/material'

const FallBack = () => (
    <Typography variant="subtitle1" fontWeight="bold" color="text.disabled">
        Impossible d&apos;afficher ce graphique
    </Typography>
)

const HighchartsChart = (props: Omit<HighchartsReactProps, 'ref' | 'highcharts'>) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
    const { highchartsInstance } = useHighcharts()

    const isHighchartsInitialized = highchartsInstance !== undefined

    return isHighchartsInitialized ? (
        <ErrorBoundary fallbackRender={FallBack}>
            <HighchartsReact highcharts={highchartsInstance} oneToOne={true} ref={chartComponentRef} {...props} />
        </ErrorBoundary>
    ) : null
}

export default HighchartsChart
