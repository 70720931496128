import { post } from './utils.service'

export const getDataPoint = async (body) => {
    const res = await post(`/sensors/getDataPoint`, { ...body })
    return await res?.json()
}

export const sendAction = async (body) => {
    const res = await post(`/sensors/sendAction`, { ...body }, { SiteHash: body.hash })
    return await res?.json()
}

export const getTimeSeries = async (body) => {
    const res = await post(`/sensors/getTimeSeries`, { ...body })
    return await res?.json()
}

export const getActuatorOptions = async (body) => {
    const res = await post(`/sensors/actuatorOptions`, { ...body })
    return await res?.json()
}
