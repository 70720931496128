/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useState, useEffect } from 'react'
import { Box, CssBaseline, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import { Outlet, useLocation } from 'react-router-dom'
import Header from './header.component'
import NavDrawer from './navDrawer.component'
import { PERMANENT_DRAWER_BREAKPOINT, DRAWER_WIDTH } from './constants'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '../fallbacks/error.component'
import { useSelector } from 'react-redux'
import { store } from '@/store/configure.store'
import { getObjectsForSite } from '@/services/realtime.service'
import GlobalSlice from '@/store/slices/global.slice'

// Inspired from https://mui.com/material-ui/react-drawer/#responsive-drawer

const MainLayout: FC = () => {
    const [mobileOpen, setMobileOpen] = useState(false)
    const theme = useTheme()
    const isPermanent = useMediaQuery(theme.breakpoints.up(PERMANENT_DRAWER_BREAKPOINT))

    const location = useLocation()
    //@ts-expect-error
    const { selectedSite } = useSelector((state) => state.globalStore)
    //@ts-expect-error
    const me = useSelector((state) => state.meStore?.me)
    //@ts-expect-error
    const currentPath = useSelector((state) => state.globalStore.path)
    const query = new URLSearchParams(location.search)
    const fetchSites = async () => {
        const res = {
            sites: me?.sites,
        }
        if (res && res.sites && res.sites.length > 0) {
            store.dispatch(GlobalSlice.actions.setSites(res.sites))
            if (query.get('site')) {
                const find = res.sites.find((el: { Hash: string }) => el.Hash == query.get('site'))
                if (find) store.dispatch(GlobalSlice.actions.setSelectedSite(find))
                else store.dispatch(GlobalSlice.actions.setSelectedSite(res.sites[0]))
            } else {
                if (currentPath?.includes('/site') && selectedSite?.Hash != currentPath.split('/')[2]) {
                    const find = res?.sites?.find((el: { Hash: string }) => el.Hash === currentPath.split('/')[2])
                    store.dispatch(GlobalSlice.actions.setSelectedSite(find))
                } else store.dispatch(GlobalSlice.actions.setSelectedSite(res.sites[0]))
            }
        }
    }
    useEffect(() => {
        if (me) {
            fetchSites()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me])

    const [gettingObjects, setGettingObjects] = useState(false)
    useEffect(() => {
        if (selectedSite && !gettingObjects) getObjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSite])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const uniqueArray = (array: any[]) =>
        Array.from(new Set(array.map((item) => item.ObjectId))).map((objectId) => {
            return array.find((item) => item.ObjectId === objectId)
        })
    const getObjects = async () => {
        setGettingObjects(true)
        const res = await getObjectsForSite({ hash: selectedSite?.Hash })
        if (res.objects) {
            const _objects = uniqueArray(res.objects)
            store.dispatch(GlobalSlice.actions.setObjects(_objects))
            if (query.get('objectId')) {
                const find = _objects.find((el) => el.ObjectId == query.get('objectId'))
                if (find) store.dispatch(GlobalSlice.actions.setSelectedObject(find))
                else store.dispatch(GlobalSlice.actions.setSelectedObject(_objects?.[0]))
            }
        }
        setGettingObjects(false)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Header handleDrawerToggle={() => setMobileOpen(true)} />
            <NavDrawer
                variant={isPermanent ? 'permanent' : 'temporary'}
                open={isPermanent || mobileOpen}
                onClose={() => setMobileOpen(false)}
            />
            <Box
                component={'main'}
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { [PERMANENT_DRAWER_BREAKPOINT]: `calc(100% - ${DRAWER_WIDTH}px)` },
                }}
            >
                <Toolbar />
                <ErrorBoundary fallback={<ErrorFallback />}>
                    <Outlet />
                </ErrorBoundary>
            </Box>
        </Box>
    )
}

export default MainLayout
