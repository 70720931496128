import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import SettingsIcon from '@mui/icons-material/Settings'
import {
    Alert,
    Button,
    Card,
    CardContent,
    Checkbox,
    Chip,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Tooltip,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { electricGreen, redBackground } from '../../../components/Basic/colors'
import { TitleComponent } from '../../../components/Basic/library'
import { ImageInput } from '../../../components/Global/image.input.component'
import { getPmsGlobal, registerPms } from '../../../services/pms.service'
import { getObjectsForSite, togglePilot } from '../../../services/realtime.service'
import { uploadFile } from '../../../services/s3.service'
import { saveSiteNameAlt } from '../../../services/site.service'
import { createUser, getAllUsers } from '../../../services/user.service'
import { makeid, sleep, uniqueArray } from '../../../utils/global.utils'
import { validateEmail } from '../../../utils/regex.utils'
import { UserPopUp } from './user.pop-up.component'
import { store } from '@/store/configure.store'
import GlobalSlice from '@/store/slices/global.slice';

const isAdmin = (hash, permissions, selectedSite) => {
    return permissions?.find(
        (perm) =>
            perm?.hash == hash &&
            perm?.SiteId == selectedSite?.SiteId &&
            perm?.Section == 'ConstraintsView' &&
            perm?.GrantType == 'Manage'
    )
}

export const SiteComponent = ({ checkIn, checkOut, autoSave, observationEndDate }) => {
    const [userPopup, setShowUserPopup] = useState(false)
    const [users, setUsers] = useState(null)
    const [newUsers, setNewUsers] = useState([])
    const selectedSite = useSelector((state) => state.globalStore.selectedSite)
    const objects = useSelector((state) => state.globalStore.objects)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [newName, setNewName] = useState()
    const [newSurface, setNewSurface] = useState('')
    const [newRefYear, setNewRefYear] = useState('')
    const [newRefValue, setNewRefValue] = useState('')
    const [pms, setPms] = useState()
    const [pmsVendorId, setPmsVendorId] = useState()
    const [pmsList, setPmsList] = useState([])
    const [pmsStatus, setPmsStatus] = useState(null)
    const [pilot, setPilot] = useState(0)
    const [pilotLoader, setPilotLoader] = useState(false)
    const [open, setOpen] = useState(false)
    const [pictureLoading, setPictureLoading] = useState(false)
    const [showCheckinError, setShowCheckinError] = useState(false)
    const [permissions, setPermissions] = useState([])
    const [previousUsers, setPreviousUsers] = useState([])

    useEffect(() => {
        init()
    }, [selectedSite])

    useEffect(() => {
        let site = objects?.find((el) => el?.ObjectTypeId === 1)
        setPilot(null)
        if (site) {
            setPilot(site?.Pilot)
        }
    }, [objects])

    useEffect(() => {
        setPmsVendorId(selectedSite?.PmsVendorId || null)
        setPms(selectedSite?.Pms || null)
    }, [selectedSite])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (pms && pmsVendorId && selectedSite?.Hash) {
                _fetch()
            }
        }, 500)
        return () => clearTimeout(timer)
    }, [pms, pmsVendorId])
    const _fetch = async () => {
        let res = await registerPms(selectedSite?.Hash, { pms, pmsVendorId })
        // if (!res?.success) setPmsStatus("error")
        // else setPmsStatus("success")
    }
    const getObjects = async () => {
        const res = await getObjectsForSite({ hash: selectedSite?.Hash })
        if (res.objects) {
            let _objects = uniqueArray(res.objects)
            store.dispatch(GlobalSlice.actions.setObjects(_objects))
            
        }
    }

    const init = async () => {
        let res = await getAllUsers(selectedSite?.SiteId)
        let _users = []
        if (res?.users) {
            _users = res?.users
                ?.filter((user) => {
                    return user?.SiteId == selectedSite?.SiteId
                })
                ?.map((el) => {
                    return { ...el, hash: makeid() }
                })
            setUsers(_users)
            setPreviousUsers(_users)
        }
        if (res?.permissions) {
            // for each permission add hash corresponding to user
            let _permissions = res?.permissions?.map((perm) => {
                let user = _users?.find((user) => user?.email == perm?.email)
                if (user) {
                    return {
                        ...perm,
                        hash: user?.hash,
                    }
                } else return perm
            })
            setPermissions(_permissions)
        }
        res = await getPmsGlobal()
        if (res?.pms_list) {
            setPmsList(res?.pms_list)
        }
    }
    const changeUser = (email, role, hash) => {
        let _newUsers = users.map((el) => {
            if (el.hash != hash) return el
            else {
                let newRoles = { ...el.roles }
                newRoles[selectedSite?.SiteId] = role
                return {
                    ...el,
                    email,
                    roles: newRoles,
                }
            }
        })
        setUsers(_newUsers)
    }

    const changeUserPermissions = (hash, section, grant) => {
        console.log('hash: ', hash, section, grant)
        let _newPermissions = permissions
        let find = _newPermissions?.find(
            (perm) => perm?.hash == hash && perm?.SiteId == selectedSite?.SiteId && perm?.Section == section
        )
        console.log("find: ", find)
        if (find) {
            _newPermissions = _newPermissions?.map((perm) => {
                if (perm?.hash == hash && perm?.SiteId == selectedSite?.SiteId && perm?.Section == section) {
                    return {
                        ...perm,
                        GrantType: grant,
                    }
                } else return perm
            })
        } else {
            _newPermissions = [
                ..._newPermissions,
                {
                    SiteId: selectedSite?.SiteId,
                    Section: section,
                    GrantType: grant,
                    hash: hash,
                },
            ]
        }

        find = _newPermissions?.find(
            (perm) => perm?.hash == hash && perm?.SiteId == selectedSite?.SiteId && perm?.Section == section
        )
        console.log('find: ', find)
      

        setPermissions(_newPermissions)
    }

    const deleteUser = (email) => {
        let _newUsers = users.map((user) => {
            if (user?.email == email) {
                return {
                    ...user,
                    delete: true,
                }
            } else return user
        })
        setUsers(_newUsers)
    }

    const submit = async () => {
        let _selectedUsers = users?.filter((el) => {
            let siteId = isNaN(selectedSite?.SiteId) ? selectedSite?.SiteId : selectedSite?.SiteId?.toString()
            // let is_admin = isAdmin(el?.hash, permissions, selectedSite)
            return el?.SiteId == siteId
        })
        // remove users that are identical to previous users
        setError('')
        for (let i = 0; i < _selectedUsers?.length; i++) {
            let email = _selectedUsers[i]?.email
            let isValid = validateEmail(email)
            if (!isValid || email == 'to_change@gmail.com') {
                setError("Merci d'entrer un email valide")
                return
            }
        }
        setLoading(true)
        setOpen(false)
        for (let i = 0; i < _selectedUsers?.length; i++) {
            console.log('email: ', _selectedUsers[i])

            const res = await createUser(
                {
                    email: _selectedUsers[i]?.email,
                    userRoles: _selectedUsers[i]?.roles,
                    siteId: selectedSite?.SiteId,
                    delete: _selectedUsers[i]?.delete,
                    permissions: permissions?.filter((el) => el?.hash == _selectedUsers[i]?.hash),
                },
                selectedSite?.Hash
            )
            if (res?.errorMsg) {
                setError(res?.errorMsg)
            } else {
                setOpen(true)
            }
        }

        setLoading(false)
    }

    const addUser = () => {
        let roles = {}
        roles[selectedSite?.SiteId] = 'user'
        let hash = makeid()
        let newUser = {
            Type: 'user',
            email: 'to_change@gmail.com',
            roles,
            hash: hash,
            SiteId: selectedSite?.SiteId,
        }
        let _newUsers = [newUser, ...users]
        console.log('_newUsers: ', _newUsers)

        setUsers(_newUsers)
        let _newPermissions = permissions
        _newPermissions = [
            ..._newPermissions,
            {
                SiteId: selectedSite?.SiteId,
                Section: 'DashboardSiteView',
                GrantType: 'Edit',
                hash: hash,
            },
            {
                SiteId: selectedSite?.SiteId,
                Section: 'DocumentsView',
                GrantType: 'Edit',
                hash: hash,
            },
            {
                SiteId: selectedSite?.SiteId,
                Section: 'RealtimeView',
                GrantType: 'Edit',
                hash: hash,
            },
            {
                SiteId: selectedSite?.SiteId,
                Section: 'ConstraintsView',
                GrantType: '',
                hash: hash,
            },
        ]
        setPermissions(_newPermissions)
    }

    const changePicture = async (file) => {
        setPictureLoading(true)
        let path = await uploadFile(file)
        if (path) {
            await saveSiteNameAlt(selectedSite?.Hash, {
                siteName: selectedSite?.Name,
                PictureUrl: path,
            })
            setOpen(true)
        }
        setPictureLoading(false)
    }

    const changeName = async (name) => {
        await saveSiteNameAlt(selectedSite?.Hash, {
            siteName: name,
            PictureUrl: selectedSite?.PictureUrl,
        })
        setNewName(name)
    }

    const changeSurface = async (surface) => {
        setNewSurface(surface)
        await saveSiteNameAlt(selectedSite?.Hash, {
            siteName: selectedSite?.Name,
            Surface: surface,
        })
    }

    const changeRefYear = async (_value) => {
        setNewRefYear(_value)
        await saveSiteNameAlt(selectedSite?.Hash, {
            siteName: selectedSite?.Name,
            RefYear: _value,
        })
    }

    const changeRefValue = async (_value) => {
        setNewRefValue(_value)
        await saveSiteNameAlt(selectedSite?.Hash, {
            siteName: selectedSite?.Name,
            RefValue: _value,
        })
    }

    return (
        <Card style={{ marginTop: 30 }}>
            {userPopup ? (
                <UserPopUp
                    setShowUserPopup={() => {
                        setShowUserPopup(false)
                    }}
                />
            ) : null}

            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12} style={{ position: 'relative' }}>
                                {pictureLoading && (
                                    <div style={{ position: 'absolute', top: 10, left: 10 }}>
                                        <CircularProgress />
                                    </div>
                                )}
                                <ImageInput
                                    url={selectedSite?.PictureUrl}
                                    onChange={(e) => {
                                        changePicture(e)
                                    }}
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nom du site"
                                    value={newName || selectedSite?.Name || ''}
                                    type="text"
                                    onChange={(e) => changeName(e.target.value)}
                                    // error={props.errors.indexOf(`Name`) > -1}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    fullWidth
                                    label={`Surface (m2)`}
                                    value={newSurface || selectedSite?.Surface || ''}
                                    type="text"
                                    onChange={(e) => changeSurface(e.target.value)}
                                    endAdornment={<InputAdornment position="end">m2</InputAdornment>}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Année de référence"
                                    value={newRefYear || selectedSite?.RefYear || ''}
                                    type="number"
                                    onChange={(e) => changeRefYear(e.target.value)}
                                    // error={props.errors.indexOf(`Name`) > -1}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <FormControl>
                                    <InputLabel id="pms-select-label">Conso. référence</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        label="Conso. référence"
                                        value={newRefValue || selectedSite?.RefValue || ''}
                                        type="number"
                                        onChange={(e) => changeRefValue(e.target.value)}
                                        endAdornment={
                                            newRefValue || selectedSite?.RefValue ? (
                                                <InputAdornment position="end">kWh/m2/an</InputAdornment>
                                            ) : null
                                        }
                                    />
                                </FormControl>
                                {/* <TextField
                  fullWidth
                  label="Conso. référence"
                  value={newRefValue || selectedSite?.RefValue || ""}
                  type="text"
                  onChange={(e) => changeRefValue(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">kWh</InputAdornment>
                  }
                  // error={props.errors.indexOf(`Name`) > -1}
                /> */}
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={2}>
                                    {selectedSite?.SiteType === 'Hotel' && (
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel id="checkout-label" style={{ fontSize: 12 }}>
                                                Check-Out
                                            </InputLabel>
                                            <Select
                                                style={{ width: '100%' }}
                                                value={checkOut}
                                                id="select-checkout"
                                                labelId="checkout-label"
                                                onChange={(e) => {
                                                    setShowCheckinError(false)
                                                    // check that checkin is after checkout
                                                    let checkin = checkIn
                                                    let checkout = e.target.value
                                                    let checkinHour = parseInt(checkin.split(':')[0])
                                                    let checkinMinute = parseInt(checkin.split(':')[1])
                                                    let checkoutHour = parseInt(checkout.split(':')[0])
                                                    let checkoutMinute = parseInt(checkout.split(':')[1])
                                                    if (checkinHour > checkoutHour) {
                                                        autoSave({ _checkout: e.target.value })
                                                    } else if (checkinHour == checkoutHour) {
                                                        if (checkinMinute > checkoutMinute) {
                                                            autoSave({ _checkout: e.target.value })
                                                        } else {
                                                            setShowCheckinError(true)
                                                        }
                                                    } else {
                                                        setShowCheckinError(true)
                                                    }
                                                }}
                                            >
                                                {[
                                                    '08:00',
                                                    '08:30',
                                                    '09:00',
                                                    '09:30',
                                                    '10:00',
                                                    '10:30',
                                                    '11:00',
                                                    '11:30',
                                                    '12:00',
                                                    '12:30',
                                                    '13:00',
                                                ].map((el) => {
                                                    return <MenuItem value={el + ':00'}>{el}</MenuItem>
                                                })}
                                            </Select>
                                            {/* <TextField
                        type="time"
                        label="Check-Out"
                        style={{ width: "100%" }}
                        value={checkOut || "11:00"}
                        onChange={(e) => {
                          autoSave({ _checkout: e.target.value });
                        }}
                      /> */}
                                        </Grid>
                                    )}
                                    {selectedSite?.SiteType === 'Hotel' && (
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel id="checkin-label" style={{ fontSize: 12 }}>
                                                Check-In{' '}
                                            </InputLabel>
                                            <Select
                                                style={{ width: '100%' }}
                                                value={checkIn}
                                                id="select-checkin"
                                                labelId="checkin-label"
                                                onChange={(e) => {
                                                    setShowCheckinError(false)
                                                    // check that checkin is after checkout
                                                    let checkin = e.target.value
                                                    let checkout = checkOut
                                                    let checkinHour = parseInt(checkin.split(':')[0])
                                                    let checkinMinute = parseInt(checkin.split(':')[1])
                                                    let checkoutHour = parseInt(checkout.split(':')[0])
                                                    let checkoutMinute = parseInt(checkout.split(':')[1])
                                                    if (checkinHour > checkoutHour) {
                                                        autoSave({ _checkin: e.target.value })
                                                    } else if (checkinHour == checkoutHour) {
                                                        if (checkinMinute > checkoutMinute) {
                                                            autoSave({ _checkin: e.target.value })
                                                        } else {
                                                            setShowCheckinError(true)
                                                        }
                                                    } else {
                                                        setShowCheckinError(true)
                                                    }
                                                }}
                                            >
                                                {[
                                                    '11:00',
                                                    '11:30',
                                                    '12:00',
                                                    '12:30',
                                                    '13:00',
                                                    '13:30',
                                                    '14:00',
                                                    '14:30',
                                                    '15:00',
                                                    '15:30',
                                                    '16:00',
                                                    '16:30',
                                                    '17:00',
                                                    '17:30',
                                                    '18:00',
                                                    '18:30',
                                                    '19:00',
                                                    '19:30',
                                                    '20:00',
                                                    '20:30',
                                                ].map((el) => {
                                                    return <MenuItem value={el + ':00'}>{el}</MenuItem>
                                                })}
                                            </Select>
                                        </Grid>
                                    )}
                                    {selectedSite?.SiteType == 'Hotel' && (
                                        <Grid item md={12} xs={12}>
                                            {showCheckinError ? (
                                                <Alert severity="warning" sx={{ width: '100%' }} on>
                                                    Le check-in doit être après check-out.
                                                </Alert>
                                            ) : (
                                                <br />
                                            )}
                                        </Grid>
                                    )}

                                    <Grid item md={6} xs={12}>
                                        <Button
                                            //   variant={pilot ? "outlined" : "contained"}
                                            endIcon={
                                                pilotLoader ? (
                                                    <CircularProgress
                                                        style={{ color: pilot ? '' : 'white' }}
                                                        size={12}
                                                    />
                                                ) : pilot && observationEndDate ? (
                                                    <DoDisturbOnIcon />
                                                ) : (
                                                    <RocketLaunchIcon />
                                                )
                                            }
                                            style={{
                                                background: pilot ? redBackground : electricGreen,
                                                color: 'white',
                                            }}
                                            fullWidth
                                            disabled={!observationEndDate || pilotLoader}
                                            onClick={async () => {
                                                let site = objects?.find((el) => el?.ObjectTypeId === 1)
                                                if (site?.ObjectId) {
                                                    setPilotLoader(true)
                                                    await sleep(1000)
                                                    let res = await togglePilot(selectedSite?.Hash, {
                                                        objectIds: [site.ObjectId],
                                                        newValue: 1 - pilot,
                                                        isSite: true,
                                                    })
                                                    await getObjects()
                                                    setPilotLoader(false)
                                                    // setPilot(1 - pilot)
                                                }
                                            }}
                                        >
                                            {pilot && observationEndDate ? 'Arrêter le pilote' : 'Démarrer le pilote'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* {selectedSite?.SiteType === "Hotel" && (
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6}>
                    <TextField
                      type="time"
                      placeholder="12:00"
                      label="Check-In"
                      style={{ width: "100%" }}
                      value={checkIn || "15:00"}
                      onChange={(e) => {
                        autoSave({ _checkin: e.target.value });
                      }}
                    />
                  </Grid>
                </Grid>
              )} */}
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div style={{ color: 'red' }}>{error}</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <TitleComponent title="Utilisateurs" />
                            <div>
                                <Button
                                    onClick={() => {
                                        addUser()
                                    }}
                                >
                                    Ajouter
                                </Button>
                                {loading ? (
                                    <CircularProgress size={16} />
                                ) : (
                                    <Button
                                        onClick={() => {
                                            submit()
                                        }}
                                    >
                                        Enregistrer
                                    </Button>
                                )}
                            </div>
                        </div>
                        <TableContainer sx={{ maxHeight: 320, maxWidth: '100%', overflow: 'scroll' }}>
                            <Table stickyHeader aria-label="sticky table" style={{ maxWidth: '100%' }}>
                                <TableBody style={{ maxWidth: '100%' }}>
                                    {/* <Alert severity="info">Contactez Agrid pour ajouter ou modifier des administrateurs</Alert> */}

                                    {users?.length > 0 ? (
                                        users
                                            ?.filter((el) => {
                                                let siteId = isNaN(selectedSite?.SiteId)
                                                    ? selectedSite?.SiteId
                                                    : selectedSite?.SiteId?.toString()
                                                console.log('xxxxx', el?.email, el?.SiteId, siteId)
                                                return (
                                                    el?.SiteId == siteId &&
                                                    !el?.email?.includes('a-grid.com') &&
                                                    !el?.delete
                                                )
                                            })
                                            ?.map((row) => {
                                                // return

                                                return (
                                                    <UserRow
                                                        row={row}
                                                        changeUser={changeUser}
                                                        deleteUser={deleteUser}
                                                        permissions={permissions}
                                                        selectedSite={selectedSite}
                                                        changeUserPermissions={changeUserPermissions}
                                                    />
                                                )
                                            })
                                    ) : (
                                        <TableRow>
                                            <TableCell>
                                                {users?.length === 0 ? (
                                                    <Alert severity="info">Aucun utilisateur n'a été ajouté…</Alert>
                                                ) : (
                                                    <CircularProgress />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setOpen(false)}
            >
                <Alert severity="success" sx={{ width: '100%' }} on>
                    Modifications enregistrées
                </Alert>
            </Snackbar>
        </Card>
    )
}

const UserRow = ({ row, changeUser, deleteUser, permissions, selectedSite, changeUserPermissions }) => {
    let is_admin = isAdmin(row?.hash, permissions, selectedSite)
    let pilot_permission = permissions?.find(
        (perm) =>
            perm?.hash == row?.hash &&
            perm?.SiteId == selectedSite?.SiteId &&
            perm?.Section == 'RealtimeView' &&
            perm?.GrantType !== ''
    )
    let dashboard_permission = permissions?.find(
        (perm) =>
            perm?.hash == row?.hash &&
            perm?.SiteId == selectedSite?.SiteId &&
            perm?.Section == 'DashboardSiteView' &&
            perm?.GrantType !== ''
    )
    let documents_permission = permissions?.find(
        (perm) =>
            perm?.hash == row?.hash &&
            perm?.SiteId == selectedSite?.SiteId &&
            perm?.Section == 'DocumentsView' &&
            perm?.GrantType !== ''
    )
    let constraints_permission = permissions?.find(
        (perm) =>
            perm?.hash == row?.hash &&
            perm?.SiteId == selectedSite?.SiteId &&
            perm?.Section == 'ConstraintsView' &&
            perm?.GrantType !== ''
    )
    console.log('pilot_permission: ', permissions, pilot_permission)
    console.log('user', row, pilot_permission, dashboard_permission, documents_permission)
    return (
        <TableRow hover tabIndex={-1}>
            <TableCell
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    <TextField
                        style={{ width: 300 }}
                        value={row.email}
                        type="text"
                        onChange={(e) => changeUser(e.target.value, row?.roles[selectedSite?.SiteId], row.hash)}
                        // error={props.errors.indexOf(`Name`) > -1}
                    />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip
                            title={'Un administrateur pourra ajouter des utilisateurs et modifier les données du site.'}
                        >
                            <FormControlLabel
                                style={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        checked={is_admin ? true : false}
                                        onChange={(e) => {
                                            console.log('e: ', e.target.checked)
                                            changeUserPermissions(
                                                row?.hash,
                                                'ConstraintsView',
                                                e.target.checked ? 'Manage' : 'Edit'
                                            )
                                        }}
                                    />
                                }
                                label="Admin"
                            />
                        </Tooltip>
                        <i
                            className="fa fa-trash"
                            style={{
                                padding: 2,
                                color: 'red',
                                cursor: 'pointer',
                                marginLeft: 10,
                            }}
                            onClick={() => deleteUser(row.email)}
                        />
                    </div>
                </div>

                <FormGroup style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={dashboard_permission ? true : false}
                                onChange={(e) => {
                                    changeUserPermissions(
                                        row?.hash,
                                        'DashboardSiteView',
                                        e.target.checked ? 'Edit' : ''
                                    )
                                }}
                                size="small"
                            />
                        }
                        label="Dashboard"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={pilot_permission !== undefined && pilot_permission !== '' ? true : false}
                                onChange={(e) => {
                                    changeUserPermissions(row?.hash, 'RealtimeView', e.target.checked ? 'Edit' : '')
                                }}
                                size="small"
                            />
                        }
                        label="Pilotage"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={constraints_permission ? true : false}
                                onChange={(e) => {
                                    changeUserPermissions(row?.hash, 'ConstraintsView', e.target.checked ? 'Edit' : '')
                                }}
                                size="small"
                            />
                        }
                        label="Configuration"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={documents_permission ? true : false}
                                onChange={(e) => {
                                    changeUserPermissions(row?.hash, 'DocumentsView', e.target.checked ? 'Edit' : '')
                                }}
                                size="small"
                            />
                        }
                        label="Mes documents"
                    />
                </FormGroup>
                {/* {
          is_admin ? <Chip
            label="admin"
            style={{
              background: "#ce93d8",
              color: "white",
            }}
          /> : <Chip
            label="utilisateur"
            style={{ background: "#e6ee9c" }}
          />
        } */}
            </TableCell>
        </TableRow>
    )
}
