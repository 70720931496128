import React, { useState } from 'react'

import { Popover, Card, CardContent, Button } from '@mui/material'
import { updateOnboarding } from '../../services/user.service'
import useLocale from '../../utils/locale/locale.hook'

const Badge = ({ step, style, title, close, description }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const locale = useLocale()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        close()
    }

    const open = Boolean(anchorEl)
    return (
        <div style={{ zIndex: 200001, position: 'absolute', ...style }}>
            <div
                onClick={handleClick}
                aria-describedby={step}
                style={{
                    width: 30,
                    height: 30,
                    borderRadius: '50%',
                    background: '#ff1744',
                    color: 'white',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 0px 10px 2px #FF1744',
                }}
                className="onboarding-badge"
            >
                {step + 1}
            </div>
            <Popover
                id={step}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{ zIndex: 100000000 }}
            >
                <Card>
                    <CardContent
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: 350,
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={{ fontWeight: 'bold', color: '#424242' }}>{title}</div>
                        <div style={{ color: '#757575', paddingTop: 10, paddingBottom: 5 }}>{description}</div>
                        <Button onClick={handleClose} style={{ marginTop: 20 }} variant="outlined">
                            {locale?.['onboarding']?.['understood']}
                        </Button>
                    </CardContent>
                </Card>
            </Popover>
        </div>
    )
}

const OnboardingComponent = ({ onboardingStep, setOnboardingStep }) => {
    const [step, setStep] = useState(0)
    const locale = useLocale()
    return (
        <div>
            {onboardingStep === 0 && (
                <Badge
                    description={locale?.['onboarding']?.['1']}
                    step={0}
                    title={locale?.['onboarding']?.['nav']}
                    close={() => setOnboardingStep(onboardingStep + 1)}
                    style={{
                        top: document.getElementById('sites-select')?.current?.getBoundingClientRect().top,
                        left: document.getElementById('sites-select')?.getBoundingClientRect().left,
                    }}
                />
            )}
            {onboardingStep === 1 && (
                <Badge
                    step={1}
                    title={locale?.['onboarding']?.['pilot']}
                    description={locale?.['onboarding']?.['2']}
                    close={() => setOnboardingStep(onboardingStep + 1)}
                    style={{
                        top: document.getElementById('/realtime')?.getBoundingClientRect().top || 110,
                        left: 10 + document.getElementById('/realtime')?.getBoundingClientRect().left,
                    }}
                />
            )}
            {onboardingStep === 2 && (
                <Badge
                    step={2}
                    title={locale?.['onboarding']?.['config']}
                    description={locale?.['onboarding']?.['3']}
                    close={async () => {
                        setOnboardingStep(onboardingStep + 1)
                    }}
                    style={{
                        top: document.getElementById('/constraints')?.getBoundingClientRect().top || 150,
                        left: 10 + document.getElementById('/constraints')?.getBoundingClientRect().left,
                    }}
                />
            )}
            {onboardingStep === 3 && (
                <Badge
                    step={3}
                    title={locale?.['onboarding']?.['calendar']}
                    description={locale?.['onboarding']?.['4']}
                    close={async () => {
                        await updateOnboarding({ onboarding: 1 })
                        window.location.reload()
                    }}
                    style={{
                        top: document.getElementById('agrid-calendar')?.getBoundingClientRect().top || 350,
                        left: 10 + document.getElementById('agrid-calendar')?.getBoundingClientRect().left,
                    }}
                />
            )}
        </div>
    )
}

export default OnboardingComponent
