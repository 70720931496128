import { Backdrop, Box, CircularProgress, Grid, MenuItem, Select, Skeleton } from '@mui/material'
import { Container } from '@mui/system'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    electricBlue,
    electricGreen,
    electricOrange,
    electricPink,
    electricPurple,
} from '../../components/Basic/colors'
import { getCompanyDashboardData } from '../../services/data.service'
import { BuildingChart } from './components/building-chart.component'
import { GlobalGroupComponent } from './components/global-group.component'
import { GroupSummaryComponent } from './components/group-summary.component'
import { GroupMapComponent } from './components/maps-group.component'
import { GroupTable } from './components/table-group.component'
import { useQuery } from '@tanstack/react-query'
import YearlyConsumptionsBarChart from './components/energyConsumptions/YearlyConsumptionsBarChart'
import { formatSeries } from './dataProviders/utils/formatSeries'
import sumBy from 'lodash/sumBy'
import uniqBy from 'lodash/uniqBy'

const colors = [electricBlue, electricGreen, electricOrange, electricPurple, electricPink]

export const DashboardGroup = ({ onSelect, show }) => {
    const me = useSelector((state) => state.meStore?.me)
    const { sites, selectedCompany } = useSelector((state) => state.globalStore)
    const [_sites, _setSites] = useState([])
    const { data, isLoading } = useQuery({
        queryKey: ['companyDashboardData', '*'],
        queryFn: () => getCompanyDashboardData('*'),
        staleTime: Infinity,
    })
    const [loading, setLoading] = useState(isLoading)
    const [raw, setRaw] = useState(null)
    const [comparisonSeries, setComparisonSeries] = useState(null)
    const [comparisonDate, setComparisonDate] = useState(null)
    const [comparisonSites, setComparisonSites] = useState([])

    const sitesData = data?.data || []
    const seriesRaw = sitesData?.map(({ site, dailyConsumptions }) => {
        const { data, ...rest } = dailyConsumptions
        return {
            ...rest,
            points: data,
            name: site.Name,
        }
    })
    const yearlySeries = formatSeries(seriesRaw)
    const availableMonths = uniqBy(yearlySeries.categories.map((date) => moment(date).format('MM-YYYY')))

    /**
     * @type {{surface: number, yearlyConsumptionKWh: number,yearlyConsumptionKWhPerSquareMeter : number, name: string, hash: string }[]}
     */
    const averageConsumptionsPerSquareMeter = sitesData.reduce((arr, { site, dailyConsumptions, dpe }) => {
        if (dailyConsumptions.yearlyTotal && site.Surface) {
            arr.push({
                hash: site.Hash,
                surface: site.Surface,
                yearlyConsumptionKWh: dailyConsumptions.yearlyTotal,
                name: site.Name,
                yearlyConsumptionKWhPerSquareMeter: dpe.primaryKwhPerSquareMeter,
                dpeClass: dpe.dpe['consumption'].class, //dailyConsumptions.yearlyTotal / site.Surface,
            })
        }
        return arr
    }, [])
    const totalConsumption = sumBy(averageConsumptionsPerSquareMeter, 'yearlyConsumptionKWh')
    const totalSurface = sumBy(averageConsumptionsPerSquareMeter, 'surface')
    const groupAverageConsumptionPerSquareMeter = totalSurface > 0 ? totalConsumption / totalSurface : undefined

    const reset = () => {
        _setSites([])
        setRaw(null)
        setComparisonSeries([])
    }

    useEffect(() => {
        reset()
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    useEffect(() => {
        updateComparison(comparisonDate, comparisonSites)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comparisonDate, comparisonSites, raw, _sites])

    const updateComparison = async (_date, _comparisonSites) => {
        if (!_date || !_comparisonSites) {
            _date = moment().add(-1, 'month').format('MM-YYYY')

            _comparisonSites = _sites
                ?.map((el) => {
                    return {
                        hash: el?.Hash,
                        name: el?.Name,
                        enabled: true,
                        color: colors?.[0],
                    }
                })
                ?.slice(0, 1)
        }
        if (_comparisonSites?.length < 1 || !raw) return
        if (_comparisonSites?.length > 3 && !(_date?.split('-')?.length < 2)) {
            _comparisonSites = _comparisonSites?.slice(0, 3)
        }
        setComparisonDate(_date)
        setComparisonSites(_comparisonSites)
        let _series = []
        for (let i = 0; i < _comparisonSites?.length; i++) {
            const siteData = sitesData?.find((el) => el?.site?.Hash == _comparisonSites?.[i]?.hash)
            const dataPoints = siteData?.dailyConsumptions?.data.map(({ value, timestamp }) => ({
                Value: value * 1000,
                Date: moment(timestamp).format('DD-MM-YYYY'),
                Type: 'EA',
            }))
            _series.push(generateSerie(dataPoints, colors?.[i], _date, true))
        }
        setComparisonSeries(_series)
    }

    const init = () => {
        setLoading(true)

        let res = data
        if (res?.data?.length > 0) {
            setRaw(res?.data)
            let _newSites = res?.data?.map((item) => {
                const s = averageConsumptionsPerSquareMeter.find(({ hash }) => hash === item.site.Hash)
                const site_avg = s?.yearlyConsumptionKWhPerSquareMeter
                const dpe = s?.dpeClass
                const find = me?.sites?.find((el) => el?.Hash === item?.site?.Hash)

                return {
                    ...item?.site,
                    name: item?.site?.Name,
                    id: item?.site?.Hash,
                    savings: Math.random() * 25,
                    refValue: find?.RefValue,
                    refYear: find?.RefYear,
                    dpe: dpe,
                    average: site_avg ? site_avg : null,
                }
            })

            _setSites(_newSites)
        }

        setLoading(false)
    }

    const generateSerie = (data, color, date, modify) => {
        let isFullYear = false
        if (date?.split('-')?.length < 2) isFullYear = true
        let dataset = data
            ?.filter((el) => {
                let _dateArr = el?.Date?.split('-')
                if (_dateArr?.length < 3 && !isFullYear) return false
                else
                    return (
                        el?.Type === 'EA' &&
                        ((isFullYear && _dateArr[1] == date) ||
                            (`${_dateArr[1]}-${_dateArr[2]}` === `${date}` && !isFullYear))
                    )
            })
            ?.map((el) => {
                //moment().month(parseInt(el?.Date?.split("-")[0]) - 1).year
                return {
                    y: parseInt(el?.Value / 1000),
                    x: !modify ? parseInt(el?.Date?.split('-')[0]) : isFullYear ? `01-${el?.Date}` : el?.Date, //parseInt(el?.Date?.split("-")[0]),
                }
            })
        if (modify) {
            dataset = dataset.map((point) => ({
                y: point.y,
                x: moment(point.x, 'DD-MM-YYYY').valueOf(),
            }))
        }

        return {
            name: 'Consommation',
            data: dataset,
            hash: 6,
            visible: true,
            marker: {
                enabled: false,
            },
            dataLabels: {
                enabled: true,
            },
            type: 'bar',
            spacingLeft: 10,
            yAxis: 0,
            color: color,
        }
    }

    return (
        <Box sx={{ display: show ? 'block' : 'none' }}>
            <Container>
                {sites?.length === 0 ? (
                    <Backdrop open={true} style={{ zIndex: 10, color: 'white' }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                ) : (
                    <Grid container spacing={2} id="groups" alignItems="stretch">
                        <Grid item xl={4} lg={6} md={8} sm={12}>
                            <GroupSummaryComponent sites={_sites} selectedCompany={selectedCompany} />
                        </Grid>
                        <Grid item xl={4} lg={6} md={4} sm={12}>
                            <GlobalGroupComponent average={groupAverageConsumptionPerSquareMeter} />
                        </Grid>
                        <Grid item xl={4} lg={12} md={12} xs={12}>
                            {loading || isLoading ? (
                                <Skeleton variant="rectangular" width={'100%'} height={250} />
                            ) : (
                                <YearlyConsumptionsBarChart energySeries={yearlySeries} />
                            )}
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            {_sites?.length > 0 && (
                                <GroupMapComponent
                                    sites={_sites
                                        ?.filter((el) => el?.Latitude && el?.Longitude)
                                        ?.map((el) => {
                                            return {
                                                ...el,
                                                Latitude: parseFloat(el.Latitude),
                                                Longitude: parseFloat(el.Longitude),
                                            }
                                        })}
                                    onSelect={onSelect}
                                />
                            )}
                        </Grid>

                        {comparisonSeries ? (
                            <Grid item md={12} sm={12} xs={12}>
                                <BuildingChart
                                    title="Données de conso. spécifiques"
                                    series={comparisonSeries}
                                    selectedSites={comparisonSites}
                                    energyDisplay={'consumption'}
                                    meters={[]}
                                    origin="group"
                                    setSelectedSites={(_sites) => {
                                        let _newSites = _sites?.filter((el) => el?.enabled)
                                        //change color
                                        for (let i = 0; i < _newSites?.length; i++) {
                                            _newSites[i] = {
                                                ..._newSites[i],
                                                color: colors?.[i],
                                            }
                                        }
                                        setComparisonSites(_newSites)
                                    }}
                                    floatRight={
                                        comparisonDate && comparisonSeries ? (
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ position: 'relative' }}>
                                                    <Select
                                                        value={comparisonDate}
                                                        style={{ height: 60, boxSizing: 'border-box' }}
                                                        onChange={(e) => {
                                                            setComparisonDate(e.target.value)
                                                        }}
                                                    >
                                                        {availableMonths.map((date) => (
                                                            <MenuItem
                                                                value={date}
                                                                key={date}
                                                                disabled={date === comparisonDate}
                                                            >
                                                                {date}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>

                                                <Select
                                                    value={'-'}
                                                    // value={comparisonSites?.[idx]?.hash}
                                                    style={{
                                                        marginLeft: 10,
                                                        width: 200,
                                                        height: 60,
                                                        boxSizing: 'border-box',
                                                    }}
                                                    onChange={(e) => {
                                                        let _comparisonSites = [...comparisonSites]
                                                        let max =
                                                            comparisonDate?.toString()?.split('-')?.length > 1 ? 3 : 5
                                                        if (_comparisonSites?.length == max) {
                                                            _comparisonSites[max - 1] = {
                                                                hash: e.target.value,
                                                                name: _sites?.find((el) => el?.Hash == e.target.value)
                                                                    ?.Name,
                                                                enabled: true,
                                                            }
                                                        } else {
                                                            _comparisonSites.push({
                                                                hash: e.target.value,
                                                                name: _sites?.find((el) => el?.Hash == e.target.value)
                                                                    ?.Name,
                                                                enabled: true,
                                                            })
                                                        }
                                                        for (let i = 0; i < _comparisonSites?.length; i++) {
                                                            _comparisonSites[i] = {
                                                                ..._comparisonSites[i],
                                                                color: colors?.[i],
                                                            }
                                                        }

                                                        setComparisonSites(_comparisonSites)
                                                    }}
                                                >
                                                    <MenuItem value="-">Ajouter un site</MenuItem>
                                                    {_sites?.map((el) => {
                                                        return (
                                                            <MenuItem
                                                                key={el.Hash}
                                                                value={el?.Hash}
                                                                disabled={
                                                                    comparisonSites
                                                                        ?.map((el) => el?.hash)
                                                                        ?.indexOf(el?.Hash) > -1
                                                                }
                                                            >
                                                                {el?.Name}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        ) : null
                                    }
                                    xAxis={{
                                        type: 'datetime',
                                        labels: {
                                            format:
                                                comparisonDate?.split('-')?.length > 1
                                                    ? '{value:%d-%m-%Y}'
                                                    : '{value:%m-%Y}',
                                            align: 'center',
                                        },
                                    }}
                                    // hideChips={true}
                                    hideOpposite={true}
                                />
                            </Grid>
                        ) : null}

                        <Grid item md={12} sm={12} xs={12}>
                            <GroupTable sites={_sites} onSelect={onSelect} />
                        </Grid>

                        <Grid item md={12} sm={12}></Grid>
                        {loading && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    background: 'rgba(255,255,255,0.5)',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'center',
                                    padding: 50,
                                }}
                            >
                                <CircularProgress />
                            </div>
                        )}
                    </Grid>
                )}
            </Container>
        </Box>
    )
}
