import { Typography } from '@mui/material'
import React from 'react'

export const CardTitle = ({ title, style }) => {
    return (
        <Typography
            component="h6"
            fontWeight="bold"
            sx={{ pb: 1 }}
            gutterBottom
            style={{
                ...style,
            }}
        >
            {title}
        </Typography>
    )
}
