import { useDemoData } from './useDemoData'

import { usePerimeterFilters } from './usePerimeterFilters'
import uniq from 'lodash/uniq'
import get from 'lodash/get'
import { SiteData } from '../types'
import { CategoryFilterKey, CATEGORY_FILTERS } from '../types'

type FilterOptions = Record<CategoryFilterKey, string[]>

const getFilterOptions = (sites: unknown[] | undefined = []): FilterOptions => {
    return CATEGORY_FILTERS.reduce((acc: FilterOptions, key: string) => {
        const values = uniq(sites.map((site) => get(site, key)).filter(Boolean)).sort()
        return { ...acc, [key]: values }
    }, {} as FilterOptions)
}

const yearFromDateString = (date: string): number => new Date(date).getFullYear()

const getYearOptions = (sites: SiteData[] = []): number[] => {
    if (!sites.length) return []
    const { series } = sites[0]
    const dates = series[0].data.map((d) => d.date)
    return uniq(dates.map(yearFromDateString)).sort()
}

export const useDemoSites = () => {
    const query = useDemoData()
    const filterOptions = getFilterOptions(query.data)
    const perimeters = usePerimeterFilters()
    const yearOptions = getYearOptions(query.data as SiteData[])

    return {
        ...query,
        filterOptions,
        ...perimeters,
        yearOptions,
    }
}
