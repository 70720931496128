import React, { FC } from 'react'
import {
    Box,
    Drawer,
    DrawerProps,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
} from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DRAWER_WIDTH } from './constants'
import { mainRoutesList, isUserAllowed } from '@/navigation/mainRoutesList'
import useAuth from '@/context/useAuth'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Logo from '@/assets/img/brand/logo.png'

const NavDrawer: FC<Omit<DrawerProps, 'sx'>> = (props) => {
    const { me, handleSignOut } = useAuth()
    const { pathname, search } = useLocation()
    const navigate = useNavigate()
    const navBarLinks = mainRoutesList.filter((route) => isUserAllowed(route, me))

    return (
        <Box component="nav">
            <Drawer
                {...props}
                sx={{
                    width: DRAWER_WIDTH,

                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
                }}
            >
                <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                        src={Logo}
                        alt="agrid_log"
                        style={{
                            width: 120,
                            objectFit: 'contain',
                            // position: "fixed",
                            top: 20,
                            left: 20,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            navigate('/')
                        }}
                    />
                </Toolbar>
                <Divider />
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <List disablePadding>
                            {navBarLinks.map(({ path, title, Icon }) => (
                                <ListItem key={path} disablePadding>
                                    <ListItemButton
                                        component={Link}
                                        to={`${path}${search}`}
                                        selected={pathname.startsWith(path)}
                                    >
                                        <ListItemIcon>{<Icon />}</ListItemIcon>
                                        <ListItemText primary={title} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                        <Box id="nav-drawer-portal-container" />
                    </Box>
                    <ListItem disablePadding component="div">
                        <ListItemButton onClick={handleSignOut}>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary="Déconnexion" />
                        </ListItemButton>
                    </ListItem>
                </Box>
            </Drawer>
        </Box>
    )
}

export default NavDrawer
