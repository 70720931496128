import { FC } from 'react'
import EnergyConsumptionsBarChart from './energyConsumptionsBarChart'
import { Card } from '@mui/material'
import { CardTitle } from '../card-title.component'
import { EnergySeries } from '../../dataProviders/useEnergyConsumptions'
import { Skeleton } from '@mui/material'
interface EnergyConsumptionsProps {
    energySeries: EnergySeries
    isLoading: boolean
}

const EnergyConsumptions: FC<EnergyConsumptionsProps> = ({ energySeries, isLoading }) => {
    if (isLoading) {
        return <Skeleton variant="rectangular" height={300} />
    }
    if (!energySeries.series.length) {
        return null
    }
    if (energySeries.series.length === 1) {
        return null // redundant with Données Enedis & Températures if only 1 meter
    }
    return (
        <Card sx={{ p: 2 }}>
            <CardTitle title="Consommation d'énergie par compteur" style={{}} />
            <EnergyConsumptionsBarChart energySeries={energySeries} />
        </Card>
    )
}

export default EnergyConsumptions
