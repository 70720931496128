import { Card, CardContent } from '@mui/material'
import React, { useState } from 'react'
import HighchartsChart from '@/components/Charts/HighchartsChart'
import ButtonPicker from './buttonPicker'
import { IntensityUnit } from '../types'
import { sumBy } from 'lodash'

const colors = [
    '#7cb5ec',
    '#434348',
    '#90ed7d',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
    '#e4d354',
    '#2b908f',
    '#f45b5b',
    '#91e8e1',
    '#f45b5b',
    '#1f78b4',
    '#33a02c',
    '#e31a1c',
    '#ff7f00',
    '#6a3d9a',
    '#b15928',
    '#a6cee3',
    '#b2df8a',
    '#fb9a99',
    '#fdbf6f',
]
const symbols = ['circle', 'square', 'diamond', 'triangle', 'triangle-down']

const Legend = ({ series }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '10px' }}>
            {series.map((s, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '3px 10px' }}>
                    <div
                        style={{
                            width: '8px',
                            height: '8px',
                            backgroundColor: s.color,
                            marginRight: '5px',
                            clipPath: getSymbolClipPath(s.marker.symbol),
                        }}
                    ></div>
                    <span style={{ fontSize: '8px' }}>{s.name}</span>
                </div>
            ))}
        </div>
    )
}

const getSymbolClipPath = (symbol) => {
    switch (symbol) {
        case 'circle':
            return 'circle(50%)'
        case 'square':
            return 'none'
        case 'diamond':
            return 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)'
        case 'triangle':
            return 'polygon(50% 0%, 0% 100%, 100% 100%)'
        case 'triangle-down':
            return 'polygon(50% 100%, 0% 0%, 100% 0%)'
        default:
            return 'none'
    }
}

const parseReference = (reference, type, intensityUnit) => {
    try {
        const metric = reference?.parsedConsumptionMetrics.find(
            (el) => (el.type === type || el.subType === type) && el.intensityUnit == intensityUnit
        )
        return metric?.value || null
    } catch (error) {
        return null
    }
}

export const ScatterComponent = ({ hotels, groupingUtils, reference, perimeters }) => {
    const [selectedType, setSelectedType] = useState('final')
    const intensityUnit = IntensityUnit.M2_YEAR
    const unit = hotels[0]?.series.find((el) => el.type === selectedType || el.subType === selectedType)?.unit
    const referenceValue = parseReference(reference, selectedType, intensityUnit)
    // const max_square_meters_hotels = hotels?.map((el) => el?.surface).reduce((a, b) => Math.max(a, b), 0)

    const computeSeries = () => {
        let categories
        let groupBy = 'fund'
        if (groupingUtils?.groupBy && groupingUtils?.groupBy !== 'fund') {
            groupBy = groupingUtils?.groupBy
        }

        categories = hotels
            ?.map((hotel) => hotel?.[groupBy])
            ?.filter((value, index, self) => self.indexOf(value) === index)

        let series = categories?.map((category, index) => {
            let data = hotels?.filter((hotel) => hotel?.[groupBy] === category)
            data = data.filter((hotel, index, self) => self.findIndex((t) => t.id === hotel.id) === index)
            return {
                name: category,
                data: data
                    ?.map((hotel) => {
                        const series = hotel.series.find(
                            (el) => el.type === selectedType || el.subType === selectedType
                        )
                        return {
                            x: parseInt(hotel?.surface),
                            y: series ? sumBy(series.data, 'value') : 0,
                            id: hotel?.id + Math.random(),
                            hotelName: hotel?.name,
                            perimeterIndex: perimeters?.length > 1 ? `Périmètre #${hotel?.perimeterIndex + 1}: ` : '',
                        }
                    })
                    ?.map((el) => {
                        return {
                            ...el,
                            y: el?.y ? (selectedType === 'water' ? parseFloat(el?.y.toFixed(1)) : parseInt(el?.y)) : 0,
                        }
                    }),
                color: colors[index % colors.length], // Assign color
                marker: {
                    symbol: symbols[index % symbols.length], // Assign symbol
                },
            }
        })
        const max_square_meters_hotels = hotels?.map((el) => el?.surface).reduce((a, b) => Math.max(a, b), 0)

        const referenceSeries = referenceValue
            ? [
                  {
                      type: 'line',
                      color: '#9e9e9e',
                      dashStyle: 'dash',
                      width: 2,
                      data: [
                          {
                              x: 0,
                              y: 0,
                          },
                          {
                              x: max_square_meters_hotels,
                              y: max_square_meters_hotels * referenceValue,
                          },
                      ],

                      dataLabels: {
                          enabled: true,
                          formatter: function () {
                              if (this.point.x === max_square_meters_hotels) {
                                  return reference.fullName
                              }
                              return null
                          },
                          align: 'right',
                          verticalAlign: 'bottom',
                          style: {
                              fontSize: '10px',
                          },
                      },
                  },
              ]
            : []

        return [...series, ...referenceSeries]
    }

    const series = React.useMemo(() => computeSeries(), [hotels, selectedType])
    const seriesLabels = {
        final: "Consommation d'énergie",
        water: "Consommation d'eau",
        co2: 'Émissions de CO2',
    }
    const seriesOptions = Object.keys(seriesLabels)
    return (
        <Card style={{ height: 450 }}>
            <CardContent style={{ position: 'relative' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <ButtonPicker
                        value={selectedType}
                        setValue={setSelectedType}
                        options={seriesOptions}
                        valueTranslations={seriesLabels}
                        badge={false}
                    >
                        {seriesLabels[selectedType]}
                    </ButtonPicker>
                </div>
                <br />
                <HighchartsChart
                    options={{
                        chart: {
                            type: 'scatter',
                            height: 300,
                            zoomType: 'x',
                        },
                        legend: {
                            enabled: false, // Disable the default legend
                        },
                        title: {
                            text: '',
                            enabled: false,
                        },
                        yAxis: [
                            {
                                title: {
                                    text: unit,
                                },
                            },
                        ],
                        xAxis: [
                            {
                                title: {
                                    text: 'Surface (m2)',
                                },
                            },
                        ],
                        exporting: {
                            enabled: false,
                        },
                        series: series,

                        plotOptions: {
                            scatter: {
                                marker: {
                                    radius: 5, // Customize marker size
                                    states: {
                                        hover: {
                                            enabled: true,
                                            lineColor: 'rgb(100,100,100)',
                                        },
                                    },
                                },
                                states: {
                                    hover: {
                                        marker: {
                                            enabled: false,
                                        },
                                    },
                                },
                                tooltip: {
                                    pointFormat: `{point.perimeterIndex} {point.hotelName}: {point.x} m² / {point.y} ${unit}`,
                                },
                            },
                        },
                    }}
                />
                <Legend series={series?.filter((el) => el?.type !== 'line')} />
            </CardContent>
        </Card>
    )
}

export default ScatterComponent
