/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'
import { useLocation, useSearchParams, Link as RouterLink } from 'react-router-dom'
import { Breadcrumbs, Link, MenuItem, SelectChangeEvent, Typography, useMediaQuery, Theme } from '@mui/material'
import { mainRoutesList } from '@/navigation/mainRoutesList'
import useAuth from '@/context/useAuth'
import NavSelect from './navSelect'
import { store } from '@/store/configure.store'
import GlobalSlice from '@/store/slices/global.slice'
import { useSelector } from 'react-redux'
import SiteSelector from './site.selector'

const NavBreadcrumbs: FC = () => {
    const { pathname } = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const truncateIfMobile = (s: string) => (isMobile ? `${s.slice(0, 17)}.` : s)
    const updateParam = (paramName: string, newValue: string) => {
        const updatedParams = new URLSearchParams(searchParams.toString())
        updatedParams.set(paramName, newValue)
        setSearchParams(updatedParams)
    }
    const siteHash = searchParams.get('site')
    const objectId = searchParams.get('objectId')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { objects } = useSelector((state) => state.globalStore)
    const activeRoute = mainRoutesList.find((route) => route.path === pathname)
    const { me } = useAuth()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const meFromStore = useSelector((state) => state.meStore?.me)
    if (!me) {
        return null
    }
    const handleChangeSite = (e: SelectChangeEvent<string>) => {
        const hash = e?.target?.value || e;
        const newSite = meFromStore?.sites.find((site: any) => site.Hash === hash)
        if (newSite) {
            setSearchParams({ site: hash })
            store.dispatch(GlobalSlice.actions.setSelectedSite(newSite))
        }
    }

    const handleChangeObject = (e: SelectChangeEvent<string>) => {
        const objectId = e.target.value
        const newObject = objects.find((el: any) => el.ObjectId === objectId)
        if (newObject) {
            store.dispatch(GlobalSlice.actions.setSelectedObject(newObject))
            updateParam('objectId', objectId)
        }
    }
    const siteName = me?.sites.find((site) => site.hash === siteHash)?.name
    const { titleLink, minScope } = activeRoute || {}
    const isTitleLink = titleLink && (siteHash || objectId)
    const showSite = Boolean(siteHash) && minScope !== 'root'
    const showObject = showSite && Boolean(objectId) && minScope !== 'site'

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            sx={({ typography }) => ({
                color: 'common.white',
                fontSize: isMobile ? typography.body2.fontSize : typography.body1.fontSize,
            })}
        >
            {activeRoute &&
                (isTitleLink ? (
                    <Link underline="none" component={RouterLink} color="inherit" to={activeRoute.path}>
                        {activeRoute.navbarTitle}
                    </Link>
                ) : (
                    <Typography fontSize="inherit">{activeRoute.navbarTitle}</Typography>
                ))}
            {showSite &&
                siteHash &&
                activeRoute &&
                (!showObject ? (
                    <SiteSelector
                        siteHash={siteHash}
                        handleChangeSite={handleChangeSite}
                        showObject={showObject}
                        me={me}
                    />
                ) : (
                    // <NavSelect value={siteHash} onChange={handleChangeSite} disabled={showObject}>
                    //     {me?.sites
                    //         ?.sort((a, b) => a.name.localeCompare(b.name))
                    //         .map(({ name, hash }) => (
                    //             <MenuItem key={hash} value={hash}>
                    //                 {name}
                    //             </MenuItem>
                    //         ))}
                    // </NavSelect>
                    <Link
                        underline="hover"
                        component={RouterLink}
                        color="inherit"
                        to={`${activeRoute.path}?site=${siteHash}`}
                    >
                        {truncateIfMobile(siteName || '')}
                    </Link>
                ))}
            {showObject && objectId && (
                <NavSelect value={objectId} onChange={handleChangeObject}>
                    {objects &&
                        [...objects]
                            .sort((a, b) => a.Name?.localeCompare(b.Name))
                            .map(({ Name, ObjectId }) => (
                                <MenuItem key={ObjectId} value={ObjectId}>
                                    {Name?.toUpperCase()}
                                </MenuItem>
                            ))}
                </NavSelect>
            )}
        </Breadcrumbs>
    )
}

export default NavBreadcrumbs
