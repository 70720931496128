import { PowerSeries } from '../../dataProviders/useEnergyConsumptions'

export const chartFormatOptions: Highcharts.Options = {
    title: {
        text: '',
    },
    chart: {
        backgroundColor: 'transparent',
        zooming: {
            type: 'x',
        },
    },
    plotOptions: {},
    xAxis: {
        type: 'datetime',
        gridLineWidth: 1,
        crosshair: true,
        dateTimeLabelFormats: {
            day: '%e %b',
            month: '%B %y',
            year: '%Y',
        },
    },
    yAxis: {
        title: {
            text: 'Puissance (kW)',
        },
    },
    exporting: {
        enabled: false,
    },
    tooltip: {
        shared: true,
    },
}

export const getPlotlineOptions = (value: number, name: string, color: string): Highcharts.YAxisPlotLinesOptions => ({
    value,
    color,
    id: `plot-line-${name}`,
    width: 1,
    zIndex: 1,
    dashStyle: 'Dash',
    label: {
        text: `P. souscrite ${value} kVA`,
        align: 'right',
        x: -10,
        style: {
            color,
        },
    },
})

export const formatPlotlineEvent = (value: number): Highcharts.SeriesLineOptions['events'] => ({
    legendItemClick: function () {
        console.log('legendItemClick', this.name, this.visible)
        const chart = this.chart
        const plotLineId = `plot-line-${this.name}`
        if (this.visible) {
            chart.yAxis[0].removePlotLine(plotLineId)
        } else {
            chart.yAxis[0].addPlotLine(getPlotlineOptions(value, this.name, this.color as string))
        }
    },
})

export const areaSeriesOptions: Highcharts.SeriesAreaOptions = {
    type: 'area',
    marker: {
        enabled: false,
    },
    lineWidth: 1,
    fillOpacity: 0.5,
}

export const lineSeriesOptions: Highcharts.SeriesLineOptions = {
    type: 'line',
    marker: {
        enabled: false,
    },
    lineWidth: 2,
    tooltip: {
        valueDecimals: 2,
        pointFormat: '<span style="color:{series.color}">Moyenne</span>: <b>{point.y}</b> kW<br/>',
    },
}

export const areaRangeSeriesOptions: Highcharts.SeriesArearangeOptions = {
    type: 'arearange',
    lineWidth: 0,
    linkedTo: ':previous',
    fillOpacity: 0.3,
    marker: {
        enabled: false,
    },
    tooltip: {
        valueDecimals: 0,
        pointFormat:
            '<span style="color:{series.color}">Min - max</span>: <b>{point.low}</b> - <b>{point.high}</b> kW<br/>',
    },
}

export const formatSeries = (
    series: PowerSeries['series'][number],
    timestamps: number[],
    options: {
        color: unknown
        max?: number
    }
) => {
    const { data, range, ...s } = series
    const { color, max } = options
    const zones = max
        ? [
              {
                  value: max,
                  color,
              },
              {
                  color: 'red',
              },
          ]
        : undefined
    const plotLineEvents = max ? formatPlotlineEvent(max) : undefined
    if (range) {
        return [
            {
                ...lineSeriesOptions,
                ...s,
                color,
                data: data.map((d, i) => [timestamps[i], d]),
                events: plotLineEvents,
            },
            {
                ...areaRangeSeriesOptions,
                ...s,
                color,
                zones,
                data: range.map((r, i) => [timestamps[i], r[0], r[1]]),
            },
        ]
    }
    return [
        {
            ...areaSeriesOptions,
            ...s,
            color,
            zones,
            data: data.map((d, i) => [timestamps[i], d]),
        },
    ]
}
