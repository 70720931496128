import React from 'react'

import { Alert, CircularProgress, Box } from '@mui/material'

export const EnedisDisabled = ({ loading }) => {
    return (
        <Box
            sx={(theme) => ({
                zIndex: theme.zIndex.appBar - 1,
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0,0,0,0.1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backdropFilter: 'blur(1px)',
            })}
        >
            {!loading ? (
                <Alert severity="info" style={{ maxWidth: '80%' }}>
                    Contactez-nous pour activer l'API Enedis sur vos sites.
                </Alert>
            ) : (
                <CircularProgress />
            )}
        </Box>
    )
}
