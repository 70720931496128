/* eslint-disable @typescript-eslint/no-explicit-any */
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import camelCase from 'lodash/camelCase'

type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
    ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
    : Lowercase<S>

// from https://stackoverflow.com/questions/59623524/typescript-how-to-map-type-keys-to-camelcase
type KeysToCamelCase<T> = {
    [K in keyof T as CamelCase<string & K>]: T[K] extends object ? KeysToCamelCase<T[K]> : T[K]
}

export function camelCaseDeep<T extends object>(obj: T): KeysToCamelCase<T> {
    if (isArray(obj)) {
        return obj.map((item) => (isObject(item) ? camelCaseDeep(item) : item)) as any
    }
    const result: any = {}

    for (const key in obj) {
        const camelKey = camelCase(key)

        if (isObject(obj[key])) {
            result[camelKey] = camelCaseDeep(obj[key] as object)
        } else if (isArray(obj[key])) {
            result[camelKey] = (obj[key] as any[]).map((item: any) => {
                if (isObject(item)) {
                    return camelCaseDeep(item)
                } else {
                    return item
                }
            })
        } else {
            result[camelKey] = obj[key]
        }
    }

    return result
}
