import uniq from 'lodash/uniq'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SeriesMetadata = Record<string, any>

export interface FormattedSeriesData<TMeta extends SeriesMetadata> {
    categories: string[]
    series: (TMeta & {
        name: string
        total: number
        data: number[]
        range?: number[][]
    })[]
}

export const formatSeries = <TMeta extends SeriesMetadata>(
    rawData?: (TMeta & {
        points: { timestamp: string; value: number; min?: number; max?: number }[]
    })[]
): FormattedSeriesData<TMeta> => {
    if (!rawData) {
        return { categories: [], series: [] }
    }
    const categories = uniq(rawData.flatMap((series) => series.points.map((point) => point.timestamp))).sort()
    const series = rawData.map(({ points, name, ...meta }) => {
        const data = categories.map((c) => points.find((p) => p.timestamp === c)?.value || 0)
        const range =
            meta.dataType === 'MIN_MAX_AVG'
                ? categories.map((c) => {
                      const p = points.find((p) => p.timestamp === c)
                      return [p?.min || 0, p?.max || 0]
                  })
                : undefined

        return {
            data,
            name,
            range,
            total: data.reduce((acc, value) => acc + value, 0),
            ...(meta as unknown as TMeta),
        }
    })

    return { categories, series }
}
