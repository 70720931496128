import { configureStore } from '@reduxjs/toolkit'
import MeSlice from './slices/me.slice'
import AuthSlice from './slices/auth.slice'
import CalendarSlice from './slices/calendar.slice'
import GlobalSlice from './slices/global.slice'
import ConsumptionUnitsSlice from './slices/consumptionUnits.slice'
import DashboardSlice from './slices/dashboard.slice'
import RealtimeSlice from './slices/realtime.slice'
import SiteSlice from './slices/site.slice'
import LocaleSlice from './slices/locale.slice'

export const store = configureStore({
    reducer: {
        meStore: MeSlice.reducer,
        authStore: AuthSlice.reducer,
        calendarStore: CalendarSlice.reducer,
        globalStore: GlobalSlice.reducer,
        consumptionUnitsStore: ConsumptionUnitsSlice.reducer,
        dashboardStore: DashboardSlice.reducer,
        realtimeStore: RealtimeSlice.reducer,
        siteStore: SiteSlice.reducer,
        localeStore: LocaleSlice.reducer,
    },
})
