import { Alert, CircularProgress, FormControlLabel, Grid, Switch } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toggleChallenge } from '../../../services/constraints.service'
import GlobalSlice from '../../../store/slices/global.slice'

export const AgridLite = ({ selectedObject, selectedSite }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let toggle = async () => {
        setLoading(true)
        let res = await toggleChallenge({ objectHash: selectedObject?.ObjectHash }, selectedSite.Hash)
        if (res?.success) {
            dispatch(
                GlobalSlice.actions.setSelectedObject({
                    ...selectedObject,
                    NoChallenge: 1 - selectedObject?.NoChallenge,
                })
            )
        }
        setLoading(false)
    }
    return (
        <Grid
            container
            style={{
                fontSize: 16,

                fontWeight: 600,
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 40,
                marginTop: 40,
                alignItems: 'center',
            }}
        >
            <Grid
                item
                style={{
                    marginBottom: 5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                md={12}
            >
                <div style={{ color: 'rgb(66, 133, 244)' }}>Agrid Lite</div>
                {loading ? (
                    <CircularProgress size={20} />
                ) : (
                    <FormControlLabel
                        control={<Switch checked={selectedObject?.NoChallenge ? false : true} onChange={toggle} />}
                        label={selectedObject?.NoChallenge ? 'Non protégé' : 'Protégé'}
                        labelPlacement="start"
                    />
                )}
            </Grid>
            <Grid item>
                <Alert severity="info" style={{ fontSize: 13 }}>
                    L'application AgridLite est activée sur votre site. Par défaut, nous demandons à l'utilisateur de
                    prouver qu'il est légitime à passer des commandes. Vous pouvez désactiver cette sécurité sur la zone
                    ici.
                </Alert>
            </Grid>
        </Grid>
    )
}
