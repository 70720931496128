import { Box, Card, CardContent, Grid, Tooltip } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Sidebar } from './sidebar'
import { LineComponent } from './line.component'
import Co2Icon from '@mui/icons-material/Co2'
import WaterDropIcon from '@mui/icons-material/WaterDrop'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import { PerimeterMarker } from './table'
import { averages, colors } from './variables'
import { Title } from './title.component'
import InfoIcon from '@mui/icons-material/Info'

export const Kpis = ({ kpis, perimeters, referenceStudies }) => {
    return (
        <>
            <Grid container spacing={2}>
                {[
                    {
                        name: '⚡ Énergie',
                        type: 'energy',
                        values: kpis?.map((kpi) => {
                            return kpi?.find((kpi) => kpi?.type === 'energy')
                        }),

                        unit: 'kWhef/m2',
                        unit2: 'kWhef/nuitée',
                        icon: <ElectricBoltIcon style={{ maxWidth: 15 }} />,
                        description:
                            'Le terme “kWhef/m²” (kilowattheures d’énergie finale par mètre carré) est une unité de mesure utilisée pour quantifier la consommation énergétique d’un bâtiment par rapport à sa surface. Il indique combien de kilowattheures d’énergie finale sont consommés par mètre carré de surface sur une période donnée, généralement une année. L’énergie finale représente l’énergie réellement utilisée par le bâtiment, incluant toutes les sources d’énergie comme l’électricité, le gaz, le chauffage, etc.',
                    },
                    {
                        name: '🌍 Carbone',
                        type: 'co2',
                        values: kpis?.map((kpi) => {
                            return kpi?.find((kpi) => kpi?.subType === 'co2')
                        }),
                        unit: 'kgeqCO2/m2 ',
                        unit2: 'kgeqCO2/nuitée',
                        icon: <Co2Icon />,
                        description:
                            'Le terme “kgeqCO2/m²” (kilogrammes équivalent CO2 par mètre carré) est une unité de mesure utilisée pour quantifier l’impact environnemental en termes d’émissions de gaz à effet de serre d’un bâtiment ou d’une activité par rapport à sa surface. Il indique combien de kilogrammes de dioxyde de carbone (CO2) et d’autres gaz à effet de serre, exprimés en équivalents CO2, sont émis par mètre carré de surface sur une période donnée, généralement une année.',
                    },
                    {
                        name: '💧 Eau',
                        type: 'water',
                        values: kpis?.map((kpi) => {
                            return kpi?.find((kpi) => kpi?.type === 'water')
                        }),

                        unit: 'm3/m2',
                        unit2: 'm3/nuitée',
                        icon: <WaterDropIcon style={{ maxWidth: 15 }} />,

                        description:
                            'Le terme “m3/m²” (mètres cubes par mètre carré) est une unité de mesure utilisée pour quantifier la consommation d’eau d’un bâtiment par rapport à sa surface. Il indique combien de mètres cubes d’eau sont consommés par mètre carré de surface sur une période donnée, généralement une année. Cette unité de mesure est utilisée pour évaluer l’efficacité de la gestion de l’eau dans un bâtiment et pour identifier les sources de gaspillage et les possibilités d’économie.',
                    },
                ].map((item, index) => {
                    return (
                        <Grid item md={4} key={index}>
                            <KpiItem
                                {...item}
                                title={item?.name}
                                perimeters={perimeters}
                                referenceStudies={referenceStudies}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}

const KpiItem = ({ values, title, unit, unit2, icon, description, perimeters, referenceStudies, type }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    const handleOpenSidebar = () => {
        setIsSidebarOpen(true)
    }

    const handleCloseSidebar = () => {
        setIsSidebarOpen(false)
    }
    const bottomHeight = 40

    return (
        <>
            <Card style={{ position: 'relative' }}>
                <CardContent>
                    <div
                        style={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: colors?.neutral,
                            letterSpacing: 2,
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 10,
                        }}
                    >
                        {title}
                        <CustomTooltip referenceStudies={referenceStudies} type={type} unit={unit} />
                    </div>
                    {values?.length === 1 ? (
                        <MonoKpi
                            value={
                                values?.[0]?.value > 10 ? Math.floor(values?.[0]?.value) : values?.[0]?.value.toFixed(2)
                            }
                            unit={unit}
                            value2={
                                values?.[0]?.valuePerNight > 10
                                    ? Math.floor(values?.[0]?.valuePerNight)
                                    : values?.[0]?.valuePerNight.toFixed(2)
                            }
                            unit2={unit2}
                            // value2={averages[reference]?.[type]}
                            // unit2={unit}
                            percentage={Math.floor(values?.[0]?.percentage)}
                            icon={icon}
                            // icon={<img src={averages[reference]?.img} style={{ maxHeight: 16, maxWidth: 60 }} />}
                            handleOpenSidebar={handleOpenSidebar}
                        />
                    ) : (
                        <MultipleKpi values={values} unit={unit} icon={icon} unit2={unit2} perimeters={perimeters} />
                    )}
                </CardContent>

                {isSidebarOpen ? (
                    <Sidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar}>
                        <div>
                            <h3>{`${title} (${unit})`}</h3>
                            <br />
                            <LineComponent
                                series={[
                                    {
                                        data: values?.[0]?.series,
                                        color: '#40c4ff',
                                        name: values?.[0]?.series?.[0]?.date?.split('-')[0],
                                    },
                                    {
                                        data: values?.[0]?.last_year_series,
                                        color: '#757575',
                                        name: values?.[0]?.last_year_series?.[0]?.date?.split('-')[0],
                                    },
                                ]}
                            />

                            <p>{description}</p>
                        </div>
                    </Sidebar>
                ) : null}
            </Card>
        </>
    )
}

export const CustomTooltip = ({ referenceStudies, type, unit, metrics = 'parsedConsumptionMetrics' }) => {
    let _type = type === 'water' ? 'water' : 'energy_total'
    let subtype = type === 'co2' ? 'co2' : type == 'energy' ? 'final' : null
    let intensityUnit = 'm2.year'
    const tooltipContent = (
        <div>
            {referenceStudies.map((item, index) => {
                let value
                if (metrics == 'parsedSocialMetrics') {
                    value = item?.[metrics]?.[type] ? item?.[metrics]?.[type] * 100 : null
                } else {
                    value = item?.[metrics]?.find((metric) => {
                        return (
                            metric?.type == _type &&
                            metric?.subType == subtype &&
                            metric?.intensityUnit == intensityUnit
                        )
                    })?.value
                }

                return (
                    <div key={index} style={{ marginBottom: '4px', fontSize: 10 }}>
                        <strong>{item?.name?.toUpperCase()}</strong>: {value ? `${value?.toFixed(2)} ${unit}` : 'N/A'}
                    </div>
                )
            })}
        </div>
    )

    return (
        <Tooltip
            title={tooltipContent}
            placement="top"
            arrow
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: '#f5f5f9',
                        color: 'rgba(0, 0, 0, 0.87)',
                        maxWidth: 220,
                        fontSize: '14px',
                        border: '1px solid #dadde9',
                        padding: '10px',
                    },
                },
            }}
        >
            <InfoIcon style={{ marginLeft: 5, fontSize: 16, color: '#bdbdbd' }} />
        </Tooltip>
    )
}

const MonoKpi = ({ value, unit, value2, unit2, icon, percentage, bottomHeight = 40, handleOpenSidebar }) => {
    return (
        <>
            <div
                style={{
                    fontSize: 50,
                    fontWeight: 600,
                    color: colors?.darken,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 10,
                    alignItems: 'center',
                }}
            >
                {value}
                <div style={{ fontSize: 12, marginLeft: 5, color: colors?.neutral, opacity: 0.7 }}>{unit}</div>
            </div>
            <Tooltip title="Par rapport à l'année précédente" placement="top">
                <div
                    style={{
                        ...styles.stats,
                    }}
                    onClick={handleOpenSidebar}
                >
                    <Trend percentage={Math.floor(percentage)} />
                </div>
            </Tooltip>
            <div
                style={{
                    position: 'absolute',
                    // background: '#f5f5f5',
                    borderTop: '2px solid #f5f5f5',
                    bottom: 0,
                    width: '100%',
                    left: 0,
                    color: colors?.neutral,
                    padding: 5,
                    fontSize: 12,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: bottomHeight,
                    // cursor: 'pointer',
                }}
            >
                <div style={{ marginRight: 5 }}>{icon}</div>
                {value2} {unit2}
            </div>
            <div style={{ height: 20, width: '100%' }} />
        </>
    )
}

export const Trend = ({ percentage, reverse = false }) => {
    return (
        <div
            style={{
                background: percentage <= 0 || reverse ? '#e8f5e9' : '#fce4ec',
                color: percentage <= 0 || reverse ? '#4caf50' : '#f44336',
                cursor: 'pointer',
                borderRadius: 4,
                display: 'flex',
                fontSize: 14,
                alignItems: 'center',
                padding: 2,
            }}
        >
            {percentage > 0 ? <TrendingUpIcon fontSize="22" /> : <TrendingDownIcon fontSize="22" />}
            <div style={{ marginLeft: 4 }}> {percentage}%</div>
        </div>
    )
}

export const MultipleKpi = ({ values, unit, unit2, icon, perimeters }) => {
    // assume we have only two perimeters
    return (
        <Grid container spacing={1} style={{ marginTop: 10 }}>
            {values?.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <Grid item md={1}>
                            <PerimeterMarker color={perimeters?.[index]?.perimeter?.color} label={index + 1} />
                        </Grid>
                        <Grid
                            item
                            md={5}
                            style={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                color: colors?.darken,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                paddingLeft: 20,
                            }}
                        >
                            {item?.value > 20
                                ? Math.floor(item?.value)
                                : item?.value > 10
                                  ? item?.value?.toFixed(1)
                                  : item?.value?.toFixed(2)}{' '}
                            {unit}
                        </Grid>
                        <Grid
                            item
                            md={4}
                            style={{
                                fontSize: 9,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                color: colors?.neutral,
                            }}
                        >
                            {icon}
                            {item?.valuePerNight > 10
                                ? Math.floor(item?.valuePerNight)
                                : item?.valuePerNight?.toFixed(2)}{' '}
                            {unit2}
                        </Grid>
                        <Grid item md={2}>
                            {index === 0 && item?.value <= values?.[1]?.value ? (
                                <Trend
                                    percentage={Math.ceil(
                                        ((item?.value - values?.[1]?.value) * 100) / values?.[1]?.value
                                    ).toFixed(0)}
                                    handleOpenSidebar={() => {}}
                                />
                            ) : null}
                            {index === 1 && item?.value < values?.[0]?.value ? (
                                <Trend
                                    percentage={Math.ceil(
                                        ((item?.value - values?.[0]?.value) * 100) / values?.[0]?.value
                                    ).toFixed(0)}
                                    handleOpenSidebar={() => {}}
                                />
                            ) : null}
                        </Grid>
                    </Fragment>
                )
            })}
        </Grid>
    )
}

const styles = {
    stats: {
        position: 'absolute',
        top: 8,
        right: 8,
    },
}
