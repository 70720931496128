import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import WarningIcon from '@mui/icons-material/Warning'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import { Box, Card, CardContent, Chip, Grid, Menu, MenuItem, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDrag } from 'react-dnd'
import { useSelector } from 'react-redux'
import AutoModeSvg from '../../../assets/img/realtime/auto-mode.svg'
import Room from '../../../assets/img/realtime/bedroom.webp'
import Common_Space from '../../../assets/img/realtime/Common_Space.webp'
import CoolModeSvg from '../../../assets/img/realtime/cool-mode.svg'
import DryModeSvg from '../../../assets/img/realtime/dry-mode.svg'
import FanModeSvg from '../../../assets/img/realtime/fan-mode.svg'
import HeatModeSvg from '../../../assets/img/realtime/heat-mode.svg'
import Office from '../../../assets/img/realtime/office.webp'
import OpenSpace from '../../../assets/img/realtime/open_space.webp'
import Other from '../../../assets/img/realtime/other.webp'
import Restaurant from '../../../assets/img/realtime/restaurant.webp'
import { default as Meeting_Room, default as Technical_Zone } from '../../../assets/img/realtime/Technical_Zone.webp'

import { electricBlue, electricOrange, lightGrey, mediumGrey } from '../../../components/Basic/colors'
import { isDemo } from '../../../services/utils.service'
import { event } from '../../../utils/ga.utils'
import useLocale from '../../../utils/locale/locale.hook'
import PilotChip from '../../dashboard/components/pilot.chip.component'
import moment from 'moment'

const TempOrderChip = ({ value, on }) => {
    return (
        <Chip
            label={`${value}°C`}
            size={'small'}
            style={{
                background: on ? '#00E786' : '#F1F2F4',
                color: on ? 'white' : '#494B4F',
                fontSize: 10,
                marginRight: 4,
            }}
        />
    )
}

const SpaceMenu = ({ categories, changeSpaceType }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const locale = useLocale()
    return (
        <Box sx={{ position: 'absolute', top: 5, right: 5 }} className="space-menu">
            <WorkspacesIcon
                onClick={handleClick}
                style={{ color: 'white', cursor: 'pointer', fontSize: 30 }}
                className="space-menu"
            />

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className="space-menu"
            >
                {Object.keys(categories).map((key) => {
                    return (
                        <MenuItem
                            onClick={() => {
                                changeSpaceType(key)
                                handleClose()
                            }}
                            key={key}
                        >
                            {locale?.['spaceTypes']?.[key]}
                        </MenuItem>
                    )
                })}
            </Menu>
        </Box>
    )
}

const SpaceItem = ({
    item,
    show,
    sensors,
    dataMeasurements,
    hideInactives,
    spaceType,
    changeSpaceType,
    draggable,
    sitePilot,
    piloted,
    categories,
    translations,
    currentGroup,
    updateCurrentGroup,
    constraint,
    objectPms,
    bookings,
}) => {
    const selectedSite = useSelector((state) => state.globalStore.selectedSite)
    const [on, setOn] = useState(0)
    const [tmpOrder, setTmpOrder] = useState(null)
    const [mode, setMode] = useState(null)
    const [occupied, setOccupied] = useState(null)
    const [willBeOccupied, setWillBeOccupied] = useState(null)
    const [error, setError] = useState(null)
    const ref = useRef()
    const [{ isDragging }, drag] = useDrag({
        type: 'CARD',
        item: { name: 'Any custom name', type: 'CARD' },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (dragItem, monitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult?.name) changeSpaceType(item, dropResult.name)
        },
    })

    const locale = useLocale()

    useEffect(() => {
        let object_sensors = sensors?.filter((el) => el.ObjectId === item.ObjectId)

        /** finding the drive */
        let newItem = object_sensors?.find((el) => el?.DataTypeId === 228)
        if (newItem) {
            let dataMeasurement = dataMeasurements.find((el) => el?.SensorId === newItem?.SensorId)
            if (dataMeasurement) setOn(dataMeasurement?.Value)
        }
        /** finding the mode */
        newItem = object_sensors?.find((el) => el?.DataTypeId === 225)

        if (newItem) {
            let dataMeasurement = dataMeasurements.find((el) => el?.SensorId === newItem?.SensorId)
            let translation = translations?.find((el) => el.IdGlobal === dataMeasurement?.Value)
            let demo_mode = isDemo()
            if (demo_mode) setMode('HEAT')
            if (translation) {
                setMode(translation?.Name)
            }
        }
        /** finding temp order */
        newItem = object_sensors?.find((el) => el?.DataTypeId === 226)
        if (newItem) {
            let dataMeasurement = dataMeasurements.find((el) => el?.SensorId === newItem?.SensorId);
            let value = dataMeasurement?.Value
            // round to 0.5
            value = value ? Math.round(value * 2) / 2  : value
            setTmpOrder(value)
        }
        /** finding error */
        newItem = object_sensors?.find((el) => el?.DataTypeId === 326)
        if (newItem) {
            let dataMeasurement = dataMeasurements.find((el) => el?.SensorId === newItem?.SensorId)
            setError(dataMeasurement?.Raw)
        }
        let new_version = objectPms?.length > 0

        let pms_ids = new_version
            ? objectPms?.filter((el) => el?.ObjectId == item?.ObjectId)?.map((el) => el?.PmsId)
            : item?.PmsId
                ? [item?.PmsId]
                : []

        if (pms_ids?.length > 0) {
            /** finding occupancy */
            newItem = sensors?.filter((el) => el?.PmsId && pms_ids?.indexOf(el?.PmsId) > -1)
            if (newItem) {
                let dataMeasurement = dataMeasurements
                    ?.filter((el) => newItem?.map((el) => el?.SensorId)?.indexOf(el?.SensorId) > -1)
                    ?.sort((a, b) => b?.DateTime - a?.DateTime)?.[0]
                // if (item?.Name == "CH 2") {
                //   console.log("xxx1", object_sensors);
                //   console.log("xxx1", dataMeasurements?.filter((el) => object_sensors?.map(el => el?.SensorId)?.indexOf(el?.SensorId) > -1));
                //   console.log("xxx1", pms_ids)
                //   console.log("xxx1", newItem)
                //   console.log("xxx1", dataMeasurement)
                // }
                setOccupied(dataMeasurement?.Value)
            }
        }
    }, [dataMeasurements])

    useEffect(() => {
        if (bookings?.length > 0 && objectPms?.length > 0) {
            initBookings()
        }
    }, [bookings, objectPms])

    const initBookings = () => {
        setWillBeOccupied(null)
        let pms_ids = objectPms?.filter((el) => el?.ObjectId == item?.ObjectId)?.map((el) => el?.PmsId)

        if (pms_ids?.length > 0) {
            let today = moment()

            let associated_bookings = bookings?.filter(
                (el) =>
                    pms_ids?.indexOf(el?.PmsId) > -1 &&
                    moment(el?.StartDate).isSameOrBefore(today) &&
                    moment(el?.EndDate).isSameOrAfter(today)
            )
            if (associated_bookings?.length > 0) {
                // we disabled the willBeOccupied for now; it causes issues when the data is not updated
                // setWillBeOccupied(true)
            }
        }
    }

    if ((hideInactives && !on) || sensors?.filter((el) => el.ObjectId === item.ObjectId)?.length === 0) {
        // console.log("xxxxxxx", sensors?.filter((el) => el.ObjectId === item.ObjectId))
        return null
    }
    if ((piloted && item.Pilot * sitePilot === 0) || (!piloted && item.Pilot * sitePilot === 1) || item?.IsSlave) {
        return null
    }

    const isOn = on === 1
    const isError =
        error?.toLowerCase() !== 'ok' &&
        error !== null &&
        error !== 0 &&
        error !== undefined &&
        error?.toLowerCase() !== 'off'

    const backgroundImgSrc =
        spaceType === 'Hotel_Room'
            ? Room
            : spaceType === 'Common_Area'
                ? Common_Space
                : spaceType === 'Technical_Zone'
                    ? Technical_Zone
                    : spaceType === 'Restaurant'
                        ? Restaurant
                        : spaceType === 'Office'
                            ? Office
                            : spaceType == 'Meeting_Room'
                                ? Meeting_Room
                                : spaceType === 'Other'
                                    ? Other
                                    : spaceType === 'Open_Space'
                                        ? OpenSpace
                                        : null
    return (
        <Grid item xs={12} sm={4} md={3} key={`item_${item.Technical_Name}}`}>
            <Card
                ref={draggable ? drag : ref}
                style={{
                    cursor: 'pointer',
                    height: 100,
                    position: 'relative',
                    boxShadow:
                        isOn || currentGroup?.objects?.indexOf(item?.ObjectId) > -1
                            ? '0px 0px 4px 4px rgba(118,255,3,0.44)'
                            : '',
                    opacity: isDragging ? 0.4 : '',
                }}
                className="card-hover"
                onClick={(e) => {
                    if (
                        !e?.target?.classList?.contains('space-menu') &&
                        !e?.target?.classList?.contains('MuiBackdrop-root') &&
                        !e?.target?.classList?.contains('MuiMenuItem-root') &&
                        !e?.target?.classList?.contains('space-item-checkbox') &&
                        e?.target?.type !== 'checkbox' &&
                        e.target.nodeName !== 'path'
                    ) {
                        event('Realtime', `Click -> ${selectedSite?.Name}/${item?.Name?.toUpperCase()}`)
                        if (((!mode && !tmpOrder && item) || isError) && !draggable) {
                            alert('Vous ne pouvez pas contrôler cette télécommande car une erreur est reportée.')
                        } else if (!item?.IsSlave || draggable) {
                            show(item)
                        } else {
                            alert(
                                'Vous ne pouvez pas contrôler cette télécommande car elle est lié à une télécommande maîtresse.'
                            )
                        }
                    }
                }}
            >
                <img
                    src={backgroundImgSrc}
                    style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                    }}
                    key={`img_${spaceType}`}
                />
                {!isOn && dataMeasurements?.length > 0 ? (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            background: 'rgba(0,0,0,0.6)',
                            position: 'absolute',
                        }}
                    />
                ) : null}
                <CardContent sx={{ fontWeight: 'bold', p: 0 }}>
                    {draggable ? (
                        <SpaceMenu
                            categories={categories}
                            changeSpaceType={(newSpaceType) => changeSpaceType(item, newSpaceType)}
                        />
                    ) : null}
                    <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                        {draggable ? (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    padding: 5,
                                }}
                                className="space-item-checkbox"
                                onClick={() => updateCurrentGroup(item?.ObjectId)}
                            >
                                {currentGroup?.objects?.indexOf(item?.ObjectId) > -1 ? (
                                    <div style={{ position: 'relative', zIndex: 1 }}>
                                        <div
                                            style={{
                                                width: 15,
                                                height: 15,
                                                background: 'white',
                                                position: 'absolute',
                                                top: 4,
                                                left: 4,
                                                zIndex: 1,
                                            }}
                                        />{' '}
                                        <div style={{ zIndex: 10, position: 'absolute' }}>
                                            <CheckBoxIcon
                                                className="space-item-checkbox"
                                                style={{ color: '#76ff03' }}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <CheckBoxOutlineBlankIcon
                                        className="space-item-checkbox"
                                        style={{ color: 'white' }}
                                    />
                                )}
                            </div>
                        ) : null}
                    </Box>

                    <div
                        style={{
                            position: 'absolute',
                            left: 10,
                            bottom: 0,
                            color: 'white',
                        }}
                    >
                        {' '}
                        {item?.Name?.toUpperCase()}
                    </div>
                    {constraint ? (
                        <div
                            style={{
                                position: 'absolute',
                                top: 10,
                                left: 10,
                                color: 'white',
                                fontSize: 12,
                                paddingTop: 2,
                            }}
                        >
                            {mode === 'HEAT' &&
                                constraint.constraintMinHeat &&
                                `${constraint.constraintMinHeat}°C-${constraint.constraintMaxHeat}°C`}
                            {mode === 'COOL' &&
                                constraint.constraintMin &&
                                `${constraint.constraintMin}°C-${constraint.constraintMax}°C`}
                        </div>
                    ) : null}
                    <div
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            zIndex: 10,
                            fontSize: 10,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ marginRight: 4 }}>
                            {
                                [{
                                    value: 'HEAT',
                                    src: HeatModeSvg
                                }, {
                                    value: 'COOL',
                                    src: CoolModeSvg
                                }, {
                                    value: 'AUTO',
                                    src: AutoModeSvg
                                }, {
                                    value: 'AUTOHEAT',
                                    src: AutoModeSvg
                                }, {
                                    value: 'AUTOCOOL',
                                    src: AutoModeSvg
                                }, {
                                    value: 'FAN',
                                    src: FanModeSvg
                                }, {
                                    value: 'DRY',
                                    src: DryModeSvg
                                }]?.map((el) => {
                                    if (mode === el.value)
                                        return <div key={`img_${el.value}`}>
                                            <img src={el.src} key={`img_${el.value}`} />
                                        </div>
                                    return null
                                })
                            }
                        </div>
                        {tmpOrder && !(mode === 'FAN') && <TempOrderChip value={tmpOrder} on={on} />}
                        {((!mode && !tmpOrder && item) || isError) && !draggable ? (
                            <WarningIcon
                                sx={{
                                    color: !mode && !tmpOrder && item ? '#03a9f4' : 'yellow',
                                }}
                            />
                        ) : null}

                        {!mode && !tmpOrder && item && !draggable
                            ? null
                            : (sitePilot === 0 || sitePilot === 1) && <PilotChip pilot={item.Pilot * sitePilot} />}
                    </div>
                    {spaceType === 'Hotel_Room' && dataMeasurements?.length > 0 ? (
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                padding: 10,
                                background: occupied ? electricBlue : willBeOccupied ? electricOrange : lightGrey,
                                color: occupied || willBeOccupied ? lightGrey : mediumGrey,
                                display: 'flex',
                                borderRadius: 4,
                            }}
                            className="space-menu"
                        // onClick={() => modifyOccupation()}
                        >
                            <Tooltip
                                title={
                                    occupied === null && willBeOccupied === null
                                        ? locale?.['toolTips']?.['addPms']
                                        : occupied
                                            ? locale?.['toolTips']?.['takenRoom']
                                            : willBeOccupied
                                                ? locale?.['toolTips']?.['willBeTakenRoom']
                                                : locale?.['toolTips']?.['freeRoom']
                                }
                            >
                                <PeopleAltIcon className="space-menu" />
                            </Tooltip>
                        </div>
                    ) : null}
                    {item?.IsSlave && !draggable ? (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                background: 'rgba(0,0,0,0.2)',

                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'white',
                                fontSize: 11,
                            }}
                        ></div>
                    ) : null}
                </CardContent>
            </Card>
        </Grid>
    )
}

export default SpaceItem
