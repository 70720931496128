import React, { useEffect, useState } from 'react'
import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    MenuItem,
    Modal,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { createUser } from '../../services/user.service'
import { sleep } from '../../utils/global.utils'
import useLocale from '../../utils/locale/locale.hook'
import { validateEmail } from '../../utils/regex.utils'

const roles = ['admin', 'user', 'worker']

const UserModal = ({ open, handleClose, user, sites }) => {
    const [email, setEmail] = useState(user?.email)
    const [userRoles, setUserRoles] = useState({ ...user?.roles })
    const [error, setError] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [snackOpen, setSnackOpen] = useState(false)
    const locale = useLocale()
    useEffect(() => {
        setEmail(user?.email)
        setUserRoles({ ...user?.roles })
    }, [user])
    const submit = async () => {
        setError('')
        let isValid = validateEmail(email)
        if (!isValid) {
            setError("Merci d'entrer un email valide")
            return
        }
        setIsSubmitting(true)
        const res = await createUser({ email, userRoles })
        if (res?.success) {
            setSnackOpen(true)
            await sleep(1000)
            window.location.reload()
        } else if (res?.errorMsg) {
            setError(res?.errorMsg)
        }
        setIsSubmitting(false)
    }
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 2,
                        p: 4,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <TextField
                            id="email-basic"
                            variant="outlined"
                            placeholder="Adresse email"
                            value={email || ''}
                            disabled={user?.email ? true : false}
                            style={{ width: 300 }}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {error && <span style={{ color: 'red' }}>{error}</span>}
                        <Button onClick={submit} disabled={isSubmitting}>
                            Enregistrer
                        </Button>
                    </div>
                    {snackOpen ? (
                        <Alert severity="success" style={{ marginTop: 10 }}>
                            Vos modifications ont été prises en compte
                        </Alert>
                    ) : (
                        <Alert severity="info" style={{ marginTop: 10 }}>
                            Un utilisateur <b>admin</b> aura la possibilité d'ajouter d'autres utilisateurs.
                        </Alert>
                    )}

                    <TableContainer style={{ overflow: 'scroll', height: 400 }} stickyHeader={true}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Site</TableCell>
                                    <TableCell>Rôle</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sites.map((row) => (
                                    <TableRow
                                        key={row.email}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row.Name}</TableCell>
                                        <TableCell>
                                            {' '}
                                            <Select
                                                style={{ width: 100 }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={userRoles[row?.SiteId] || ''}
                                                onChange={(e) => {
                                                    let newRoles = { ...userRoles }
                                                    newRoles[row?.SiteId] = e?.target?.value
                                                    setUserRoles({ ...newRoles })
                                                }}
                                            >
                                                <MenuItem value="">
                                                    <em style={{ fontSize: 12 }}>Supprimer</em>
                                                </MenuItem>
                                                {roles?.map((el) => {
                                                    return <MenuItem value={el}>{locale?.['roles']?.[el]}</MenuItem>
                                                })}
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>
        </>
    )
}

const AdminPage = () => {
    const me = useSelector((state) => state.meStore?.me)
    const [selectedUser, setSelectedUser] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        setLoading(false)
    }
    return (
        <>
            {loading && (
                <Backdrop open={true} style={{ zIndex: 1000, color: 'white' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <UserModal
                open={selectedUser ? true : false}
                handleClose={() => setSelectedUser(null)}
                user={selectedUser}
                sites={me?.sites}
            />
        </>
    )
}

export default AdminPage
