import { Card, CardContent, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import DetailsImg from '../../../assets/img/realtime/details.png'
import { TitleComponent } from '../../../components/Basic/library'
import { StaticDatePickerLandscape } from './calendar.component'
import { SiteComponent } from './site.component'

export const Tab1Component = ({ autoSave, holidays, checkIn, checkOut, observationEndDate }) => {
    const selectedSite = useSelector((state) => state.globalStore.selectedSite)
    return (
        <Grid container spacing={2} style={{ marginBottom: 40 }}>
            <Grid item md={12} sm={12} xs={12}>
                <SiteComponent
                    checkIn={checkIn}
                    checkOut={checkOut}
                    autoSave={autoSave}
                    observationEndDate={observationEndDate}
                />
            </Grid>

            {selectedSite?.SiteType !== 'Hotel' && (
                <Grid item md={12} sm={12} xs={12}>
                    <Card style={{ marginBottom: 40 }}>
                        <CardContent>
                            <div
                                style={{
                                    color: '#050C22',
                                    fontSize: 14,
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 500,
                                }}
                            >
                                <img src={DetailsImg} style={{ marginRight: 10, maxHeight: 20 }} />
                                <TitleComponent title={'Jours feriés'} style={{ fontSize: 15 }} />
                            </div>
                            <StaticDatePickerLandscape
                                // setModified={setModified}
                                holidays={holidays}
                                setHolidays={(e) => {
                                    autoSave({ _holidays: e })
                                }}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </Grid>
    )
}
